import {useInfiniteQuery, useMutation, useQueryClient} from "react-query";
import * as api from "../api/api";
import Post from "../components/Post";
import {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {protectedPage} from "../utils/auth";

const ResourcePage = ({userLogin, setUserLogin}) => {
  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  return (
    <div className="resourcepage-container">
      <div className="resourcepage-banner">
        <img
          src={require("../assets/images/resource-banner.png")}
          alt="resource-banner"
        />
      </div>
      <div className="article-container">
        <h2 className="title">Support for IBD </h2>
        <p className="text1">
          <a
            target="_blank"
            href="https://crohnsandcolitis.org.uk"
            className="name"
          >
            Crohn’s & Colitis UK
          </a>{" "}
          <p>
            is a charity dedicated to supporting individuals with Crohn's
            disease and ulcerative colitis, caregivers, and healthcare
            professionals. They offer information, support groups, a helpline,
            online community, research funding, and advocacy efforts to improve
            services and raise awareness.
          </p>
        </p>
        <p className="text1">
          <p className="name">Crohn's & Colitis Helpline </p>
          <p>
            Tel: 0300 222 5700 Monday -Friday, 9am - 5pm (except English bank
            holidays)
          </p>
          <p>
            <span className="name">Email:</span>
             helpline@crohnsandcolitis.org.uk
          </p>
        </p>
        <p className="text1">
          <a target="_blank" href="https://www.cicra.org" className="name">
            Cicra
          </a>{" "}
          <p>
            Seeking in-depth information and support on Crohn's and colitis for
            children and families? The <span className="name">Cicra</span>{" "}
            provides a wealth of resources to help you navigate this challenging
            condition. Visit their website to learn more.
          </p>
        </p>
        <p className="text1">
          <a target="_blank" href="https://ibduk.org" className="name">
            IBDUK
          </a>{" "}
          <p>
            Collaborative alliance for IBD care across the UK, ensuring
            high-quality, person-centered treatment and outcomes for all
            affected.
          </p>
        </p>
        <p className="text-end">
          <a
            target="_blank"
            href="https://www.ibdpassport.com"
            className="name"
          >
            IBD Passport
          </a>
          <p>
            <span className="name">IBD Passport:</span> Award-winning website
            offering comprehensive travel information for those with IBD. The
            first resource of its kind, making trip planning easy. A UK
            registered non-profit charity (Charity number: 1171268) with a
            global mission to support IBD travelers worldwide.
          </p>
        </p>
        <h2 className="title">Support for mental health</h2>
        <p className="text1">
          If you are worried about anxiety or depression, these helplines can
          provide professional guidance.
        </p>
        <p className="text1">
          <a target="_blank" href="https://www.mind.org.uk" className="name">
            Mind
          </a>
          <p>Supporting people with mental health problems.</p>
          <p>
            <span className="name">Info line:</span> 0300 123 3393 Mon-Fri,
            9am-6pm (except English bank holidays)
          </p>
          <p>
            <span className="name">Email: </span>
            info@mind.org.uk
          </p>
        </p>
        <p className="text1">
          If you believe that discussing your emotions with someone could be
          beneficial,
        </p>
        <p className="text1">
          <p className="name purple">Samaritans</p>
          <p>
            <span className="name">Tel:</span> 116123 (24 hours a day, 7 days a
            week)
          </p>
          <p>
            <span className="name">Email: </span>
            jo@samaritans.org
          </p>
        </p>
      </div>
    </div>
  );
};

export default ResourcePage;
