import React, {useEffect, useState} from "react";
import {getCookie, deleteCookie, cookieExists} from "../utils/cookies";
import ArticleCard from "../components/ArticleCard";
import Faq from "../components/Faq";
import ContactUs from "../components/ContactUs";
import {listOfCards} from "../utils/mockData";
import {Forum} from "./ForumPage";
import AboutUs from "../components/AboutUs";
import {useQuery} from "react-query";
import {getBlogById} from "../api/api";

const HomePage = ({userLogin, setUserLogin, setModalOpen}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [pinBlog, setPinBlog] = useState([]);
  const [pinForum, setPinForum] = useState([]);
  const handleCookiesAuth = () => {
    if (cookieExists("userID") && cookieExists("username")) {
      const useridValue = getCookie("userID");
      const usernameValue = getCookie("username");
      localStorage.setItem("user_id", useridValue);
      localStorage.setItem("username", usernameValue);
      deleteCookie("userID");
      deleteCookie("username");
    } else {
      return;
    }
  };

  const listOfBlog = [
    "65e4e5112184d99db24c1bfb",
    "65e4e37f2184d99db24c1bfa",
    "65e1e22c6d9dc30b51321197",
  ];
  const listOfForum = [
    "65e4ec0c2184d99db24c1bfe",
    "65e4e91e2184d99db24c1bfd",
    "65e4e7452184d99db24c1bfc",
  ];

  useEffect(() => {
    if (pinBlog.length === 0) {
      Promise.all(listOfBlog.map((postId) => getBlogById(postId)))
        .then((newList) => {
          setPinBlog(newList);
        })
        .catch((error) => console.error("Failed to fetch blogs", error));
    }
  }, [pinBlog]);

  useEffect(() => {
    if (pinForum.length === 0) {
      Promise.all(listOfForum.map((postId) => getBlogById(postId)))
        .then((newList) => {
          setPinForum(newList);
        })
        .catch((error) => console.error("Failed to fetch forums", error));
    }
  }, [pinForum]);

  useEffect(() => {
    handleCookiesAuth();
  }, []);

  useEffect(() => {
    const today = new Date().toDateString();
    const dontShowModalToday = localStorage.getItem("dontShowModalToday");
    if (dontShowModalToday !== today) {
      setModalOpen(true);
    }
  }, []);

  return (
    <div className="homepage-container">
      <div className="title-text-section">
        <div className="top">
          Welcome to <br />
          FamilyBridge for IBD
        </div>
        <p className="bottom">
          An online resource dedicated to supporting and empowering family
          members affected by Inflammatory Bowel Disease.
        </p>
      </div>
      <div className="article-section">
        <div className="topic-section">
          <h2>Information for you</h2>
          <hr />
        </div>
        <div className="card-wrapper">
          {listOfCards.map((each, index) => {
            return (
              <ArticleCard
                key={index}
                image={each.image}
                mainText={each.mainText}
                subText={each.subText}
                url={each.url}
              />
            );
          })}
        </div>
      </div>
      <div className="feature-section">
        <div className="topic-section">
          <h2>Our Community</h2>
          <hr />
        </div>
        <div className="feature-wrapper">
          <div className="putting-home">
            <ArticleCard
              key="blog"
              image={require("../assets/images/Shoes-2.png")}
              mainText="Putting yourself in someone else's shoes"
              subText=""
              url="/blog"
            />
            <div className="list-article-fix">
              {!!pinBlog &&
                pinBlog.length !== 0 &&
                pinBlog.map((each) => {
                  return <Forum forum={each} />;
                })}
            </div>
          </div>
          <div className="forum-home">
            <ArticleCard
              key="forum"
              image={require("../assets/images/Forum-Head.png")}
              mainText="Forum"
              subText=""
              url="/forum"
            />
            <div className="list-article-fix">
              {!!pinForum &&
                pinForum.length !== 0 &&
                pinForum.map((each) => {
                  return <Forum forum={each} />;
                })}
            </div>
          </div>
        </div>
      </div>
      <AboutUs />
      <div className="our-team-section">
        <div className="topic-section">
          <h2>Our Team</h2>
          <hr />
        </div>
        <div className="member">
          <ArticleCard
            key="team01"
            image={require("../assets/images/team01.png")}
            mainText="Parichat Thapwong"
            subText="PhD student"
          />
          <ArticleCard
            key="team02"
            image={require("../assets/images/team02.png")}
            mainText="Wladzia Czuber-Dochan"
            subText="Reader in Nursing and Applied Health Research"
          />
          <ArticleCard
            key="team03"
            image={require("../assets/images/team03.png")}
            mainText="Christine Norton"
            subText="Professor of Clinical Nursing Research"
          />
          <ArticleCard
            key="team04"
            image={require("../assets/images/team04.png")}
            mainText="Emma Rowland"
            subText="Lecturer in emotional geographies of healthcare"
          />
        </div>
      </div>
      <Faq userLogin={userLogin} isAdmin={isAdmin} setIsAdmin={setIsAdmin} />
      <ContactUs userLogin={userLogin} />
    </div>
  );
};

export default HomePage;
