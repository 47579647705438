import React, {useState} from "react";
import axios from "axios";
import Config from "../assets/configs/configs.json";

const AddFaq = () => {
  const resetForm = {
    question: "",
    answer: "",
  };
  const errorForm = {
    question: "",
    answer: "",
  };

  const [form, setForm] = useState(resetForm);
  const [error, setError] = useState(errorForm);
  const [resError, setResError] = useState("");

  const handleChange = (event) => {
    const {name, value} = event.target;
    let added;
    added = value;
    setForm({
      ...form,
      [name]: added,
    });
    validateForm(event);
  };

  const validateForm = (event) => {
    let {name, value} = event.target;
    setError((prev) => {
      const stateObj = {...prev, [name]: ""};
      switch (name) {
        case "question":
          if (!value) {
            stateObj[name] = "Please enter the question";
          }
          break;
        case "answer":
          if (!value) {
            stateObj[name] = "Please enter the answer";
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let err = false;
    if (!form.question) {
      setError((prev) => ({
        ...prev,
        ["question"]: "Please enter the question.",
      }));
      err = true;
    }
    if (!form.answer) {
      setError((prev) => ({
        ...prev,
        ["answer"]: "Please select answer.",
      }));
      err = true;
    }
    if (err) {
      handleShowResError("Please check error message above.");
      return;
    }

    const body = {question: form["question"], answer: form["answer"]};

    try {
      await axios.post(`${Config.BACKEND_URL}/faq`, body, {
        withCredentials: true,
        headers: {
          user_id: localStorage.getItem("user_id"),
        },
      });
      window.location.assign("/");
    } catch (error) {
      handleShowResError(error.response.data.error);
    }
  };

  const handleShowResError = (text) => {
    setResError(text);
    setTimeout(() => {
      setResError("");
    }, 5000);
  };

  return (
    <div className="addcar-container">
      <div className="addcar-box">
        <h2>Create new FAQ</h2>
        <form onSubmit={handleSubmit} className="addcar-form">
          <label>Question</label>
          <input
            name="question"
            onChange={handleChange}
            onBlur={validateForm}
            value={form.question}
          ></input>
          {error.question && <span className="error">{error.question}</span>}
          <label>Answer</label>
          <textarea
            name="answer"
            onChange={handleChange}
            onBlur={validateForm}
            value={form.description}
          ></textarea>

          <button type="submit">Post FAQ</button>
          {resError && <span className="error">{resError}</span>}
        </form>
      </div>
    </div>
  );
};

export default AddFaq;
