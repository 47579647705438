import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";
import {checkLogin} from "../utils/auth";
import useOutsideClick from "../hooks/useOutsideClick";
import Config from "../assets/configs/configs.json";
import {cookieExists, deleteCookie} from "../utils/cookies";
import {ProtectedLink} from "./ProtectedLink";
import {WholeScreenLoader} from "../components/LoadingComponent";
import * as api from "../api/api";
import {LOG} from "../constant/timeLog";

const Navbar = ({userLogin, setUserLogin}) => {
  const [navbarInfo, setNavbarInfo] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const toggleMenu = () => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
      setMenuOpen(!menuOpen);
    } else {
      alert("You must be logged in to visit this content.");
      window.location.assign("/signin");
    }
  };

  const ref = useOutsideClick(() => setOpenDropdown(false));

  const handleLogout = async () => {
    setLoading(true);
    await api.sendTimeLog({action: LOG.LOGOUT});
    setUserLogin({});
    localStorage.clear();
    (await cookieExists("username")) && (await deleteCookie("username"));
    (await cookieExists("userID")) && (await deleteCookie("userID"));
    await axios.post(
      `${Config.BACKEND_URL}/user/logout`,
      {
        cookie_name: "auth",
      },
      {
        withCredentials: true,
      }
    );
    setLoading(false);
    window.location.assign("/");
  };

  const fetchNavbar = async () => {
    setLoading(true);
    await checkLogin(userLogin, setUserLogin);
    if (!!userLogin && userLogin.isLogin) {
      try {
        const res = await axios.get(`${Config.BACKEND_URL}/user/navbar`, {
          headers: {
            user_id: localStorage.getItem("user_id"),
          },
          withCredentials: true,
        });
        setNavbarInfo(res.data.user);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNavbar();
  }, [userLogin]);

  return (
    <div className="navbar-container">
      <Link to="/" className="logo-wrapper">
        <img
          src={require("../assets/images/logo.png")}
          alt="logo1"
          className="logo1"
        />
        <img
          src={require("../assets/images/logo2.png")}
          alt="logo2"
          className="logo2"
        />
      </Link>
      <div className="content-container">
        <nav className="reponsive-hide">
          <Link to="/" className="content">
            Home
          </Link>
          <div className="content build-your-life">
            Building a Life Beyond IBD
            <div className="dropdown">
              <ul role="menu" className="menu">
                <li className="menu-item">
                  <div className="link sub-content">
                    Information for you
                    <div className="sub-dropdown">
                      <ul role="menu" className="menu">
                        <li className="menu-item">
                          <ProtectedLink
                            to="/informativeModules?topic=understanding-ibd-and-its-impact"
                            className="link"
                          >
                            Understanding IBD and its impact
                          </ProtectedLink>
                        </li>
                        <li className="menu-item">
                          <ProtectedLink
                            to="/informativeModules?topic=empowering-individual-with-ibd"
                            className="link"
                          >
                            Supporting individuals with IBD
                          </ProtectedLink>
                        </li>
                        <li className="menu-item">
                          <ProtectedLink
                            to="/informativeModules?topic=building-bonds-that-last"
                            className="link"
                          >
                            Building bonds that last
                          </ProtectedLink>
                        </li>
                        <li className="menu-item">
                          <ProtectedLink
                            to="/informativeModules?topic=unlocking-inner-resilience"
                            className="link"
                          >
                            Unlocking inner resilience
                          </ProtectedLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="menu-item">
                  <div className="link sub-content">
                    Our Community
                    <div className="sub-dropdown">
                      <ul role="menu" className="menu">
                        <li className="menu-item">
                          <ProtectedLink to="/forum" className="link">
                            Forum
                          </ProtectedLink>
                        </li>
                        <li className="menu-item">
                          <ProtectedLink to="/blog" className="link">
                            Putting yourself in someone else's shoes
                          </ProtectedLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="menu-item">
                  <div className="link sub-content">
                    Other Resources
                    <div className="sub-dropdown">
                      <ul role="menu" className="menu">
                        <li className="menu-item">
                          <ProtectedLink to="/faq" className="link">
                            FAQs
                          </ProtectedLink>
                        </li>
                        <li className="menu-item">
                          <ProtectedLink to="/resource" className="link">
                            Useful links
                          </ProtectedLink>
                        </li>
                        <li className="menu-item">
                          <ProtectedLink to="/booklet" className="link">
                            FamilyBridge for IBD booklets
                          </ProtectedLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ScrollLink to="aboutus" className="content">
            About Us
          </ScrollLink>
          <ScrollLink to="contactus" className="content">
            Contact Us
          </ScrollLink>

          {navbarInfo ? (
            <>
              <div className="profile" ref={ref}>
                <img
                  className="profile-img"
                  src={navbarInfo.image}
                  alt=""
                  style={openDropdown ? {opacity: 0.5} : {}}
                  onClick={toggleDropdown}
                />
                {openDropdown && (
                  <div className="dropdown">
                    <ul role="menu" className="menu">
                      <li className="menu-item">
                        <ProtectedLink
                          to="/profile"
                          className="link"
                          onClick={toggleDropdown}
                        >
                          My profile
                        </ProtectedLink>
                      </li>
                      {navbarInfo.role === "admin" ? (
                        <>
                          <li className="menu-item">
                            <ProtectedLink
                              to="/signup"
                              className="link"
                              onClick={toggleDropdown}
                            >
                              Create New Account
                            </ProtectedLink>
                          </li>
                          <li className="menu-item">
                            <ProtectedLink
                              to="/analytic"
                              className="link"
                              onClick={toggleDropdown}
                            >
                              Analytic
                            </ProtectedLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      <li className="menu-item">
                        <ProtectedLink
                          to="/addPost"
                          className="link"
                          onClick={toggleDropdown}
                        >
                          Add new post
                        </ProtectedLink>
                      </li>
                      <li className="menu-item">
                        <ProtectedLink
                          to="/addFaq"
                          className="link"
                          onClick={toggleDropdown}
                        >
                          Add FAQ
                        </ProtectedLink>
                      </li>
                      <li className="menu-item" onClick={() => handleLogout()}>
                        Logout
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Link to="/signin" className="signin content">
                Sign in
              </Link>
            </>
          )}
        </nav>

        {navbarInfo ? (
          <i
            onClick={toggleMenu}
            style={{marginRight: "16px", color: "#8bbeed"}}
            className="fa-solid fa-bars reponsive-show"
          />
        ) : (
          <>
            <Link to="/signin" className="signin content reponsive-show">
              <i
                style={{marginRight: "16px", color: "#8bbeed"}}
                className="fa-solid fa-user"
              ></i>
            </Link>
          </>
        )}
        <div className={`reponsive-menu ${menuOpen ? "open-menu" : ""}`}>
          <i
            onClick={toggleMenu}
            style={{
              position: "absolute",
              right: 36,
              top: 36,
              fontSize: 24,
              color: "#8bbeed",
            }}
            className="fa-solid fa-square-xmark"
          ></i>

          <div>
            {navbarInfo ? (
              <>
                <div className="responsive-profile">
                  <div>
                    <a
                      href="/profile"
                      style={{textDecoration: "none", color: "black"}}
                      className="responsive-profile-wrapper"
                    >
                      <img src={navbarInfo.image} alt="" />
                      <div>{navbarInfo.username}</div>
                    </a>
                    <div className="responsive-menu-group">
                      <a
                        href="/"
                        style={{textDecoration: "none", color: "black"}}
                        className="responsive-link"
                      >
                        Home
                      </a>
                      <a
                        href="/"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          textAlign: "center",
                        }}
                        className="responsive-link"
                      >
                        Information for you
                      </a>
                      <a
                        href="/forum"
                        style={{textDecoration: "none", color: "black"}}
                        className="responsive-link"
                      >
                        Forum
                      </a>
                      <a
                        href="/blog"
                        style={{textDecoration: "none", color: "black"}}
                        className="responsive-link"
                      >
                        Blog
                      </a>
                      <a
                        onClick={() => setMenuOpen(false)}
                        href="/#aboutus"
                        style={{textDecoration: "none", color: "black"}}
                        className="responsive-link"
                      >
                        About us
                      </a>
                      <a
                        onClick={() => setMenuOpen(false)}
                        href="/#contactus"
                        style={{textDecoration: "none", color: "black"}}
                        className="responsive-link"
                      >
                        Contact us
                      </a>
                    </div>
                    <a
                      className="responsive-menu-item"
                      style={{textDecoration: "none", color: "black"}}
                      href="/addPost"
                    >
                      <div className="link">Create New Post</div>
                    </a>
                    {navbarInfo.role === "admin" ? (
                      <>
                        <a
                          className="responsive-menu-item"
                          style={{textDecoration: "none", color: "black"}}
                          href="/signup"
                        >
                          <div className="link">Create New Account</div>
                        </a>
                        <a
                          className="responsive-menu-item"
                          style={{textDecoration: "none", color: "black"}}
                          href="/addFaq"
                        >
                          <div className="link">Create New FAQ</div>
                        </a>
                        <a
                          className="responsive-menu-item"
                          style={{textDecoration: "none", color: "black"}}
                          href="/analytic"
                        >
                          <div className="link">Analytic</div>
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className="responsive-menu-item menu-logout"
                    onClick={handleLogout}
                  >
                    <div className="link">Logout</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <a href="/signin" className="signin content">
                  Sign in
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      {loading && <WholeScreenLoader />}
    </div>
  );
};

export default Navbar;
