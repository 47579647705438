import Collapsible from "react-collapsible";

const FaqPage = () => {
  return (
    <div className="faqpage-container">
      <div className="faqpage-banner">
        <img src={require("../assets/images/FAQs.png")} alt="blog-banner" />
        <div className="text">
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>
      <div className="container-faqq">
        <Collapsible
          key="1"
          trigger="Is IBD the same as IBS (irritable bowel syndrome)?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            No, IBD (Inflammatory Bowel Disease) and IBS (Irritable Bowel
            Syndrome) both affect the digestive system and can cause abdominal
            pain and bowel changes, but they're different. IBD is an
            inflammatory condition that damages the gut, leading to symptoms
            like frequent bowel movements, pain, diarrhoea, and sometimes
            bleeding. Symptoms vary depending on the type and location of
            inflammation. IBS, on the other hand, is a functional disorder with
            no gut damage. It causes recurring abdominal pain and changes in
            bowel movements. The symptoms may include bloating, constipation,
            and diarrhoea. Endoscopy and radiology tests don't show inflammation
            in IBS. It's important to encourage your loved one to consult a
            healthcare provider for proper diagnosis and treatment if they
            experience persistent digestive issues. It is possible for some
            people to be diagnosed with both IBD and IBS, especially if they
            still have a lot of symptoms such a pain even when the IBD is in
            remission with no inflammation.
          </p>
        </Collapsible>
        <Collapsible
          key="2"
          trigger="How are Crohn's disease (CD) and Ulcerative colitis (UC) different?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            CD can affect any part of the digestive tract and involves all
            layers of the bowel wall, often leading to patchy inflammation. In
            contrast, UC is confined to the colon and rectum, primarily
            affecting the inner bowel lining, usually with continuous
            inflammation along the colon.
          </p>
        </Collapsible>
        <Collapsible
          key="3"
          trigger="Is CD and UC contagious?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            No, CD and UC are not spreadable. Spending time with someone who has
            one of these conditions cannot transmit it to you.
          </p>
        </Collapsible>
        <Collapsible
          key="4"
          trigger="Are CD and UC hereditary?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            CD and UC both have a genetic component, meaning there is an
            increased risk of developing these conditions if a close family
            member has been diagnosed with them. However, the inheritance
            pattern is complex, and having a family history of CD or UC does not
            guarantee that someone will develop the condition. Additionally,
            environmental factors also play a significant role in the
            development of these diseases. Therefore, while there is a genetic
            predisposition/tendency, it is not the only factor that determines
            if IBD will develop, and other factors contribute to the overall
            risk of developing CD or UC.
          </p>
        </Collapsible>
        <Collapsible
          key="5"
          trigger="Can CD or UC progress into cancer?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            While both conditions increase the risk of colorectal cancer, it's
            not guaranteed. The risk factors are duration and extent of
            inflammation (especially in the colon for UC) and presence of PSC
            (liver complication) in UC. Regular screenings are highly
            recommended for early detection. Talk to IBD team about the best
            screening schedule for your loved one.
          </p>
        </Collapsible>
        <Collapsible
          key="6"
          trigger="Will the diseases ever go away? Does person with IBD have to take medications for life?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            IBD is a lifelong condition characterised by periods of remission
            and flare-ups, during which symptoms may vary in intensity. While
            there is currently no cure for IBD, ongoing advances in treatment
            are being made. Many individuals require medication to control
            inflammation and manage symptoms, often needing continuing treatment
            to maintain remission even when they are well during symptom-free
            periods. You might find more information on{" "}
            <a
              href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/treatments?parent=23127&page=1&tags=&category=23127&sort=newest"
              target="_blank"
              rel="noreferrer"
            >
              medication
            </a>{" "}
            by visiting the Crohn's & Colitis UK website.
          </p>
        </Collapsible>
        <Collapsible
          key="7"
          trigger="Will my family member with IBD need surgery, or can they manage with medications alone?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            Whether surgery is needed for IBD or if medication alone is
            sufficient depends on various factors, including the severity of the
            condition, the extent of inflammation, and individual response to
            treatment. Many people with IBD can effectively manage their
            symptoms with medications, and surgery is not necessary. However, in
            some cases, particularly when medications are ineffective or
            complications arise, surgery may be recommended to remove diseased
            portions of the intestine or to address complications such as
            strictures (bowel narrowing) or fistulas (abnormal openings from the
            bowel). It's crucial to have open discussions with healthcare
            providers to determine the best treatment plan for your loved one's
            specific situation. You might find more information on{" "}
            <a
              href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/surgery-and-complications?parent=23199&page=1&tags=&category=23199&sort=newest"
              target="_blank"
              rel="noreferrer"
            >
              surgery
            </a>{" "}
            by visiting the Crohn's & Colitis UK website
          </p>
        </Collapsible>
        <Collapsible
          key="8"
          trigger="Can individuals with IBD still get pregnant and have children?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            Many individuals with Crohn's Disease or Ulcerative Colitis have
            completely healthy pregnancies and deliver healthy babies. However,
            planning ahead and discussing your loved one's situation with their
            IBD healthcare team is crucial. <br />
            <br />
            <span style={{fontWeight: "bold"}}>
              Here are some key points to remember:
            </span>
            <br />
            <br />
            <span style={{fontWeight: "bold"}}>
              Pre-pregnancy planning is essential.
            </span>{" "}
            Aiming for disease remission before pregnancy is ideal, but even
            with active disease, healthy pregnancies are still possible.
            <br />
            <span style={{fontWeight: "bold"}}>
              Open communication with the healthcare team is vital.{" "}
            </span>
            They can ensure your loved one's condition is well-managed before,
            during, and after pregnancy.
            <br />
            <span style={{fontWeight: "bold"}}>
              Medication adjustments might be necessary.
            </span>
            Some medications used to treat Crohn's and Colitis are safe during
            pregnancy, while others might need adjustments. Your loved one
            should continuously communicate any medication changes with their
            healthcare team.
            <br />
            <span style={{fontWeight: "bold"}}>Delivery options: </span>
            While most people with Crohn’s or Colitis can give birth vaginally,
            there may be situations where a caesarean section is recommended.
            The decision will be made with the healthcare team based on your
            loved one's preferences and specific situation.
            <br />
            <br /> Remember, with proper planning and close monitoring by your
            loved one's healthcare team, they can increase their chances of a
            healthy pregnancy and childbirth. You might find more information on{" "}
            <a
              href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/pregnancy-and-breastfeeding"
              target="_blank"
              rel="noreferrer"
            >
              pregnancy
            </a>{" "}
            for people with IBD by visiting the Crohn's & Colitis UK website.
          </p>
        </Collapsible>
        <Collapsible
          key="9"
          trigger="What foods are recommended/avoided for people with IBD? What constitutes a healthy eating plan for them?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            Understanding the dietary needs of your loved one with IBD can be
            challenging, as each person's requirements differ. Whether your
            family member has CD or UC, the severity and location of their
            condition play a crucial role in determining the most suitable diet.
            Encouraging them to focus on maintaining a balanced diet and helping
            them track foods that trigger symptoms can be supportive.
            Additionally, suggesting they consult a registered dietitian or
            nutritionist recommended by their doctor to develop a personalised
            and safe diet plan can be useful. You might find more information on{" "}
            <a
              href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
              target="_blank"
              rel="noreferrer"
            >
              diet
            </a>{" "}
            by visiting the Crohn's & Colitis UK website.
          </p>
        </Collapsible>
        <Collapsible
          key="10"
          trigger="What can I do to look after myself?"
          triggerTagName="div"
          triggerClassName="collapsible-closed-trigger"
          triggerOpenedClassName="collapsible-trigger-open"
        >
          <p>
            Supporting a loved one with CD or UC can be fulfilling, but it also
            comes with challenges. It's crucial to find a balance between
            supporting them and caring for yourself. Here are some tips:
            <br />
            <br />
            <span style={{fontWeight: "bold"}}>Set realistic boundaries: </span>
            Understand your limits and communicate them with your loved one.
            Reassure them of your support even when you can't always provide
            physical assistance.
            <br />
            <br />
            <span style={{fontWeight: "bold"}}>
              Be honest about your feelings:{" "}
            </span>
            Open communication allows for mutual understanding and support.
            Share your concerns and struggles with your loved one to foster a
            deeper connection.
            <br />
            <br />
            <span style={{fontWeight: "bold"}}>Avoid burnout: </span>Take breaks
            when needed and prioritise self-care. Remember, taking care of
            yourself enables you to better support your loved one.
            <br />
            <br />
            <span style={{fontWeight: "bold"}}>Seek support: </span>
            Connect with others who understand your experience, whether through{" "}
            <a href="/forum" target="_blank">
              online forums
            </a>{" "}
            , support groups, or counselling. Sharing your journey with others
            can provide valuable support and insights.
            <br />
            <br />
            <span style={{fontWeight: "bold"}}>
              Share responsibilities:
            </span>{" "}
            Don't hesitate to seek help from others and share caregiving duties
            if possible. Accepting support can lighten the load and ensure
            better care for your loved one.
            <br />
            <br />
            Remember, supporting someone with IBD is a team effort, and taking
            care of yourself is essential for both you and your loved one's
            well-being. Learn more about{" "}
            <a
              href="/informativeModules?topic=unlocking-inner-resilience"
              target="_blank"
              rel="noopener noreferrer"
            >
              unlocking inner resilience
            </a>
            .
          </p>
        </Collapsible>
      </div>
    </div>
  );
};

export default FaqPage;
