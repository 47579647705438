import axios from "axios";
import Config from "../assets/configs/configs.json";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {saveBlog} from "../api/api";
import {set} from "immutable";

const MyMarkAsRead = ({type}) => {
  const [understanding, setUnderstanding] = useState([]);
  const [empowering, setEmpowering] = useState([]);
  const [buildingbonds, setBuildingbonds] = useState([]);
  const [unlocking, setUnlocking] = useState([]);
  const navigate = useNavigate();

  const fetchContent = async () => {
    try {
      setUnderstanding([]);
      setEmpowering([]);
      setBuildingbonds([]);
      setUnlocking([]);
      let path = `${Config.BACKEND_URL}`;
      if (type === "completed") {
        path = path + `/user/module?type=completed`;
      }
      if (type === "bookmark_module") {
        path = path + `/user/module?type=saved`;
      }
      const res = await axios.get(path, {
        withCredentials: true,
        headers: {user_id: localStorage.getItem("user_id")},
      });
      for (const module of res.data.modules) {
        if (module.module === "understanding-ibd-and-its-impact") {
          setUnderstanding((prevArray) => [...prevArray, module.submodule]);
        }
        if (module.module === "empowering-individual-with-ibd") {
          setEmpowering((prevArray) => [...prevArray, module.submodule]);
        }
        if (module.module === "building-bonds-that-last") {
          setBuildingbonds((prevArray) => [...prevArray, module.submodule]);
        }
        if (module.module === "unlocking-inner-resilience") {
          setUnlocking((prevArray) => [...prevArray, module.submodule]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchContent();
  }, [type]);

  const typeMapper = {
    completed: {
      name: "Completed Reading",
    },
    bookmark_module: {
      name: "Bookmarked Reading",
    },
  };

  const modules = {
    "understanding-ibd-and-its-impact": [
      "About Crohn's and Colitis",
      "The Gut",
      "Common Symptoms",
      "What is a Flare-Up?",
      "How do People Get IBD?",
      "How to Get a Diagnosis?",
      "How to Treat IBD?",
      "Our Life is a Roller Coaster!",
      "Learning Points",
    ],
    "empowering-individual-with-ibd": [
      "Fatigue",
      "Pain",
      "Medications",
      "Diet",
      "Toilet access",
      "Emotional support",
      "Seeking support for family members",
      "Learning points & Tools",
    ],
    "building-bonds-that-last": [
      "Family relationship",
      "Parent and non-IBD children",
      "Couple relationship",
      "Communication",
      "Family trips",
      "Learning points",
    ],
    "unlocking-inner-resilience": [
      "Self-Care",
      "Self-Compassion",
      "Goal Setting",
      "Stress Management",
      "Emotional Management",
      "Mindfulness",
      "Time to Exercise",
      "Accepting and Asking for Help",
      "Learning Points & Tools",
    ],
  };

  return (
    <div className="my-mark-as-read">
      <div className="header">
        <h2>{!!typeMapper[type] && typeMapper[type].name}</h2>
        {/* <select
          id="selectOptions"
          value={typeOption}
          onChange={handleTypeChange}
        >
          <option value="all">All</option>
          <option value="blog">Putting Yourself in Someone Else’s Shoes</option>
          <option value="forum">Forum</option>
        </select> */}
      </div>
      <div className="info-container">
        {(type !== "bookmark_module" || understanding.length > 0) && (
          <div className="module">
            <h4 className="title">Understanding IBD and its impact</h4>
            {type === "completed" &&
              modules["understanding-ibd-and-its-impact"].map((module, i) => {
                return (
                  <div className="submodule-container">
                    <p className="submodule">
                      <a
                        href={`/informativeModules?topic=understanding-ibd-and-its-impact#${module}`}
                      >
                        {module}
                      </a>
                    </p>
                    <p className="result">
                      {understanding.includes(module) ? (
                        <p className="read">Read</p>
                      ) : (
                        <p className="not-read">Not Read</p>
                      )}
                    </p>
                  </div>
                );
              })}
            {type === "bookmark_module" &&
              modules["understanding-ibd-and-its-impact"].map((module, i) => {
                return (
                  <p className="bookmark">
                    {understanding.includes(module) ? (
                      <p className="submodule">
                        <a
                          href={`/informativeModules?topic=understanding-ibd-and-its-impact#${module}`}
                        >
                          {module}
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}
                  </p>
                );
              })}
          </div>
        )}
        {(type !== "bookmark_module" || empowering.length > 0) && (
          <div className="module">
            <h4 className="title">Supporting individuals with IBD</h4>
            {type === "completed" &&
              modules["empowering-individual-with-ibd"].map((module, i) => {
                return (
                  <div className="submodule-container">
                    <p className="submodule">
                      <a
                        href={`/informativeModules?topic=empowering-individual-with-ibd#${module}`}
                      >
                        {module}
                      </a>
                    </p>
                    <p className="result">
                      {empowering.includes(module) ? (
                        <p className="read">Read</p>
                      ) : (
                        <p className="not-read">Not Read</p>
                      )}
                    </p>
                  </div>
                );
              })}
            {type === "bookmark_module" &&
              modules["empowering-individual-with-ibd"].map((module, i) => {
                return (
                  <p className="bookmark">
                    {empowering.includes(module) ? (
                      <p className="submodule">
                        <a
                          href={`/informativeModules?topic=empowering-individual-with-ibd#${module}`}
                        >
                          {module}
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}
                  </p>
                );
              })}
          </div>
        )}
        {(type !== "bookmark_module" || buildingbonds.length > 0) && (
          <div className="module">
            <h4 className="title">Building bonds that last</h4>
            {type === "completed" &&
              modules["building-bonds-that-last"].map((module, i) => {
                return (
                  <div className="submodule-container">
                    <p className="submodule">
                      <a
                        href={`/informativeModules?topic=building-bonds-that-last#${module}`}
                      >
                        {module}
                      </a>
                    </p>
                    <p className="result">
                      {buildingbonds.includes(module) ? (
                        <p className="read">Read</p>
                      ) : (
                        <p className="not-read">Not Read</p>
                      )}
                    </p>
                  </div>
                );
              })}
            {type === "bookmark_module" &&
              modules["building-bonds-that-last"].map((module, i) => {
                return (
                  <p className="bookmark">
                    {buildingbonds.includes(module) ? (
                      <p className="submodule">
                        <a
                          href={`/informativeModules?topic=building-bonds-that-last#${module}`}
                        >
                          {module}
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}
                  </p>
                );
              })}
          </div>
        )}
        {(type !== "bookmark_module" || unlocking.length > 0) && (
          <div className="module">
            <h4 className="title">Unlocking inner resilience</h4>
            {type === "completed" &&
              modules["unlocking-inner-resilience"].map((module, i) => {
                return (
                  <div className="submodule-container">
                    <p className="submodule">
                      <a
                        href={`/informativeModules?topic=unlocking-inner-resilience#${module}`}
                      >
                        {module}
                      </a>
                    </p>
                    <p className="result">
                      {unlocking.includes(module) ? (
                        <p className="read">Read</p>
                      ) : (
                        <p className="not-read">Not Read</p>
                      )}
                    </p>
                  </div>
                );
              })}
            {type === "bookmark_module" &&
              modules["unlocking-inner-resilience"].map((module, i) => {
                return (
                  <p className="bookmark">
                    {unlocking.includes(module) ? (
                      <p className="submodule">
                        <a
                          href={`/informativeModules?topic=unlocking-inner-resilience#${module}`}
                        >
                          {module}
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}
                  </p>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyMarkAsRead;
