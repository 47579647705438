import React from "react";
import {motion} from "framer-motion";

const ArticleCard = ({image, mainText, subText, url = null}) => {
  const handleClick = (e, to) => {
    if (!!to) {
      e.preventDefault();
      const userId = localStorage.getItem("user_id");
      if (userId) {
        window.location.assign(to);
      } else {
        alert("You must be logged in to visit this content.");
        window.location.assign("/signin");
      }
    }
  };

  return (
    <motion.a
      onClick={(e) => handleClick(e, url)}
      href={url}
      className="article-card-container"
      initial={{opacity: 0, scale: 0.5}}
      animate={{opacity: 1, scale: 1}}
      style={{textDecoration: "none", color: "black"}}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <div className="article-image">
        <img src={image} alt="" />
      </div>
      <div className="article-text-box">
        <h4 className="article-text">
          {mainText} <br />
        </h4>
        <p className="article-text article-text-sub">{subText}</p>
      </div>
    </motion.a>
  );
};

export default ArticleCard;
