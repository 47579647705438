import React from "react";
import {Link} from "react-router-dom";

export const ProtectedLink = ({to, children}) => {
  const handleClick = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("user_id");
    if (userId) {
      window.location.assign(to);
    } else {
      alert("You must be logged in to visit this content.");
      window.location.assign("/signin");
    }
  };

  return (
    <Link to={to} className="link" onClick={handleClick}>
      {children}
    </Link>
  );
};
