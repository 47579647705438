import React, {useEffect} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";
import Post from "../components/Post";
import {useMutation, useQueryClient, useQuery} from "react-query";
import * as api from "../api/api";
import {protectedPage} from "../utils/auth";
import {WholeScreenLoader} from "../components/LoadingComponent";

const ContentPage = ({userLogin, setUserLogin}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const postId = searchParams.get("id");

  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  if (postId === null) {
    navigate("/404");
  }
  const {
    data: post,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["post", postId],
    queryFn: () => api.getBlogById(postId),
  });

  const likePostMutation = useMutation(api.likeBlog, {
    onMutate: async ({blogId, like}) => {
      await queryClient.cancelQueries(["post", postId]);
      const previousPost = queryClient.getQueryData(["post", postId]);
      queryClient.setQueryData(["post", postId], (oldPost) => ({
        ...oldPost,
        liked: like,
        like_count: like ? oldPost.like_count + 1 : oldPost.like_count - 1,
      }));
      return {previousPost};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["post", postId], context.previousPost);
    },
  });

  const likeCommentMutation = useMutation(api.likeComment, {
    onMutate: async ({commentId, like}) => {
      await queryClient.cancelQueries(["post", postId]);
      const previousPost = queryClient.getQueryData(["post", postId]);
      queryClient.setQueryData(["post", postId], (oldPost) => ({
        ...oldPost,
        comments: oldPost.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              liked: like,
              like_count: like
                ? comment.like_count + 1
                : comment.like_count - 1,
            };
          }
          return comment;
        }),
      }));
      return {previousPost};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["post", postId], context.previousPost);
    },
  });

  const addCommentMutation = useMutation(api.addComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(["post", postId]);
    },
  });

  const savePostMutation = useMutation(api.saveBlog, {
    onMutate: async ({blogId, savedPost}) => {
      await queryClient.cancelQueries(["post", postId]);
      const previousPost = queryClient.getQueryData(["post", postId]);
      queryClient.setQueryData(["post", postId], (oldPost) => ({
        ...oldPost,
        bookmarked: savedPost,
      }));
      return {previousPost};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["post", postId], context.previousPost);
    },
  });

  const handleAddComment = async (blogId, detail) => {
    addCommentMutation.mutate({blogId, detail});
  };

  const handlePostLike = async (blogId, like) => {
    likePostMutation.mutate({blogId, like});
  };

  const handleCommentLike = async (commentId, like) => {
    likeCommentMutation.mutate({commentId, like});
  };

  const handleSavePost = async (blogId, savedPost) => {
    savePostMutation.mutate({blogId, savedPost});
  };

  if (isLoading) {
    return <WholeScreenLoader />;
  }

  if (isError) {
    return <div>Error fetching post</div>;
  }

  return (
    <div className="contentpage-container">
      <div className="content-feed">
        <Post
          key={post.id}
          post={post}
          handlePostLike={handlePostLike}
          handleCommentLike={handleCommentLike}
          handleAddComment={handleAddComment}
          handleSavePost={handleSavePost}
          userLogin={userLogin}
          setUserLogin={setUserLogin}
        />
      </div>
    </div>
  );
};

export default ContentPage;
