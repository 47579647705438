import React, {useEffect, useState, useRef} from "react";
import {useInfiniteQuery} from "react-query";
import * as api from "../api/api";
import {dateDisplay} from "../utils/dateDisplay";
import {useNavigate} from "react-router-dom";
import {protectedPage} from "../utils/auth";

export const Forum = ({forum}) => {
  const navigate = useNavigate();
  const {
    id,
    owner: {username},
    topic,
    comment_count,
    like_count,
    viewed,
    createdAt,
  } = forum;
  return (
    <div className="forum-item" onClick={() => navigate(`/content?id=${id}`)}>
      <h3 className="topic" dangerouslySetInnerHTML={{__html: topic}}></h3>
      <div className="description">
        <span className="date-and-author">
          {dateDisplay(createdAt)} by {username}
        </span>
        <div className="stats">
          <span className="stat">
            <i className="fa-regular fa-thumbs-up"></i>
            {like_count}
          </span>
          <span className="stat">
            <i className="fa-regular fa-comment"></i>
            {comment_count || 0}
          </span>
          <span className="stat">
            <i className="fa-regular fa-eye"></i>
            {viewed}
          </span>
        </div>
      </div>
    </div>
  );
};

const ForumPage = ({userLogin, setUserLogin}) => {
  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  const loadMoreRef = useRef();
  const searchTextRef = useRef();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("newest");
  const [searchText, setSearchText] = useState("");
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["forums", sortBy, searchText],
    queryFn: ({pageParam = 1}) => api.getForums(pageParam, sortBy, searchText),
    getNextPageParam: (lastPage) => {
      return lastPage.pages > lastPage.currentPage
        ? lastPage.currentPage + 1
        : undefined;
    },
  });

  const serachByTextSection = (
    <div className="search-add-container">
      <form
        className="search-container"
        onSubmit={(e) => {
          e.preventDefault();
          setSearchText(searchTextRef.current.value);
        }}
      >
        <button type="submit">
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
        <input type="text" placeholder="Search..." ref={searchTextRef} />
      </form>
      <button className="add-blog" onClick={() => navigate(`/addPost`)}>
        Add post
      </button>
    </div>
  );

  const sortBySection = (
    <div className="sortby-container">
      <p className="sort-title">Sort : </p>
      <div className="sort-item">
        <div
          className={sortBy === "newest" ? "selected sortby" : "sortby"}
          onClick={() => setSortBy("newest")}
        >
          <i className="fa-solid fa-certificate"></i>
          New
        </div>
        <div
          className={sortBy === "mostCommented" ? "selected sortby" : "sortby"}
          onClick={() => setSortBy("mostCommented")}
        >
          <i className="fa-solid fa-comment"></i>
          Comment
        </div>
        <div
          className={sortBy === "mostLiked" ? "selected sortby" : "sortby"}
          onClick={() => setSortBy("mostLiked")}
        >
          <i className="fa-solid fa-thumbs-up"></i>
          Like
        </div>
        <div
          className={sortBy === "mostViewed" ? "selected sortby" : "sortby"}
          onClick={() => setSortBy("mostViewed")}
        >
          <i className="fa-solid fa-eye"></i>
          View
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (isLoading || isFetchingNextPage) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchNextPage();
      }
    });

    const ref = loadMoreRef.current;
    if (ref) observer.observe(ref);

    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [isLoading, isFetchingNextPage, fetchNextPage]);

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="forumpage-container">
      <div className="forumpage-banner">
        <img
          src={require("../assets/images/forumpage/banner.png")}
          alt="blog-banner"
        />
      </div>
      <div className="forum-container">
        {serachByTextSection}
        {sortBySection}
        <div className="forum-list">
          {isLoading || isFetching ? (
            <div className="no-forum">Loading...</div>
          ) : data.pages?.[0].data.length === 0 ? (
            <div className="no-forum">No forums found</div>
          ) : (
            data.pages.map((page) =>
              page.data.map((forum) => <Forum key={forum.id} forum={forum} />)
            )
          )}
        </div>
        {hasNextPage && <div ref={loadMoreRef}>Loading more...</div>}
      </div>
    </div>
  );
};

export default ForumPage;
