import React from "react";

const NoPage = () => {
  return (
    <>
      <div className="not-found-container">
        <h1>404 Not found</h1>
      </div>
    </>
  );
};

export default NoPage;
