import {useInfiniteQuery, useMutation, useQueryClient} from "react-query";
import * as api from "../api/api";
import Post from "../components/Post";
import {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {protectedPage} from "../utils/auth";

const BlogPage = ({userLogin, setUserLogin}) => {
  const queryClient = useQueryClient();
  const loadMoreRef = useRef();
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: "blogs",
    queryFn: ({pageParam = 1}) => api.getBlogs(pageParam),
    getNextPageParam: (lastPage) => {
      return lastPage.pages > lastPage.currentPage
        ? lastPage.currentPage + 1
        : undefined;
    },
  });

  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  const likeBlogMutation = useMutation(api.likeBlog, {
    onMutate: async ({blogId, like}) => {
      await queryClient.cancelQueries("blogs");
      const previousBlogs = queryClient.getQueryData("blogs");
      const newBlogs = previousBlogs.pages.map((page) => ({
        ...page,
        data: page.data.map((blog) => {
          if (blog.id === blogId) {
            return {
              ...blog,
              liked: like,
              like_count: like ? blog.like_count + 1 : blog.like_count - 1,
            };
          }
          return blog;
        }),
      }));
      queryClient.setQueryData("blogs", (oldBlogs) => ({
        pages: newBlogs,
        pageParams: oldBlogs.pageParams,
      }));
      return {previousBlogs};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData("blogs", context.previousBlogs);
    },
  });

  const saveBlogMutation = useMutation(api.saveBlog, {
    onMutate: async ({blogId, savedPost}) => {
      await queryClient.cancelQueries("blogs");
      const previousBlogs = queryClient.getQueryData("blogs");
      const newBlogs = previousBlogs.pages.map((page) => ({
        ...page,
        data: page.data.map((blog) => {
          if (blog.id === blogId) {
            return {
              ...blog,
              bookmarked: savedPost,
            };
          }
          return blog;
        }),
      }));
      queryClient.setQueryData("blogs", (oldBlogs) => ({
        pages: newBlogs,
        pageParams: oldBlogs.pageParams,
      }));
      return {previousBlogs};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData("blogs", context.previousBlogs);
    },
  });

  const handleBlogLike = async (blogId, like) => {
    likeBlogMutation.mutate({blogId, like});
  };

  const handleSaveBlog = async (blogId, savedPost) => {
    saveBlogMutation.mutate({blogId, savedPost});
  };

  useEffect(() => {
    if (isLoading || isFetchingNextPage) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchNextPage();
      }
    });

    const ref = loadMoreRef.current;
    if (ref) observer.observe(ref);

    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [isLoading, isFetchingNextPage, fetchNextPage]);

  if (isError) {
    return <div>Error...</div>;
  }

  return (
    <div className="blogpage-container">
      <div className="blogpage-banner">
        <img
          src={require("../assets/images/blogpage/banner.png")}
          alt="blog-banner"
        />
        <div className="text">
          <h1>Putting yourserlf in someone else's shoes</h1>
          <h2>"The power of empathy"</h2>
        </div>
      </div>
      <div className="blog-action-bar" onClick={() => navigate(`/addPost`)}>
        <button className="add-blog">Share your story</button>
      </div>

      {isLoading ? (
        <div className="no-blog">Loading...</div>
      ) : data?.pages[0]?.data.length === 0 ? (
        <div className="no-blog">No blogs found</div>
      ) : (
        <div className="blog-feed">
          {data.pages.map((page) =>
            page.data.map((blog, index) => (
              <Post
                key={index}
                post={blog}
                type="blog"
                handlePostLike={handleBlogLike}
                handleSavePost={handleSaveBlog}
                userLogin={userLogin}
                setUserLogin={setUserLogin}
              />
            ))
          )}
        </div>
      )}

      {hasNextPage && (
        <div className="loading-blog" ref={loadMoreRef}>
          <h3>Loading more...</h3>
        </div>
      )}
    </div>
  );
};

export default BlogPage;
