import {useMutation, useQueryClient} from "react-query";
import * as api from "../api/api";

const MarkAsRead = ({module, submodule}) => {
  const queryClient = useQueryClient();
  const moduleData = queryClient.getQueryData(["module-completed", module]);
  const value = moduleData?.[submodule];

  const toggleCompletedSubmodule = useMutation(api.addCompletedSubModule, {
    onMutate: async ({module, submodule, action}) => {
      await queryClient.cancelQueries(["module-completed", module]);
      const previousModule = queryClient.getQueryData(["module-completed", module]);
      queryClient.setQueryData(["module-completed", module], (oldModule) => ({
        ...oldModule,
        [submodule]: action,
      }));
      return {previousModule};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["module-completed", module], context.previousModule);
    },
  });

  const handleToggleMarkAsRead = async () => {
    toggleCompletedSubmodule.mutate({module, submodule, action: !value});
  };

  if (!moduleData || moduleData[submodule] === undefined) {
    return null;
  }

  return (
    <div
      className="mark-as-read padding-for-informative-specific"
      style={{
        display: "flex",
        gap: "8px",
        marginLeft: "auto",
        width: "fit-content",
      }}
    >
      <input
        type="checkbox"
        id={submodule}
        checked={value}
        onChange={handleToggleMarkAsRead}
        style={{width: "20px"}}
      />
      <p>
        Mark <span style={{fontWeight: 600}}>{submodule}</span> as read
      </p>
    </div>
  );
};

export default MarkAsRead;
