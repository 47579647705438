import React, {useEffect} from "react";
import YouTubeVideo from "./YoutubeRenderer";
import Collapsible from "react-collapsible";
import {Link as ScrollLink} from "react-scroll";
import {protectedPage} from "../utils/auth";
import {useMediaQuery} from "react-responsive";
import MindMap from "./MindMap";
import MarkAsRead from "./MarkAsRead";
import Pdf from "./Pdf";
import SaveSubmodule from "./SaveSubmodule";
import * as api from "../api/api";
import {LOG} from "../constant/timeLog";

const UnlockingInner = ({isMenuVisible, userLogin, setUserLogin}) => {
  const selfCompassionCards = [
    {
      image: "card1",
      title: "Acknowledge Your Emotions",
      description:
        "Recognise and accept your feelings, whether it's frustration, fear, guilt, or helplessness. Allow yourself to feel these emotions without judgment.",
    },
    {
      image: "card2",
      title: "Practice Self-Kindness",
      description:
        "Treat yourself with the same kindness and understanding you would offer to a friend. Avoid self-criticism and self-blame for circumstances beyond your control.",
    },
    {
      image: "card3",
      title: "Embrace Imperfections",
      description:
        "Understand that nobody is perfect, and caregiving can be challenging. Embrace the imperfections and accept that you will make mistakes along the way.",
    },
    {
      image: "card4",
      title: "Prioritise Self-Care",
      description:
        "Make self-care a priority. Dedicate time to activities that nurture your physical, emotional, and mental well-being. This may include exercise, relaxation, hobbies, or seeking support.",
    },
    {
      image: "card5",
      title: "Set Healthy Boundaries",
      description:
        "Establish clear boundaries to protect your own time, energy, and well-being. Don't overextend yourself.  Communicate your boundaries to other family members.",
    },
    {
      image: "card6",
      title: "Seek Support",
      description:
        "Don't hesitate to seek support from healthcare professionals or join support groups for caregivers. Sharing your experiences and concerns with others can be comforting and informative.",
    },
    {
      image: "card7",
      title: "Embrace Self-Advocacy",
      description: "Give yourself permission to meet your own needs.",
    },
    {
      image: "card8",
      title: "Forgiveness",
      description:
        "Forgive yourself for any shortcomings or moments of frustration. Caregiving can be tough, and forgiveness is an act of self-compassion.",
    },
    {
      image: "card9",
      title: "Empathy",
      description:
        "Practice empathy toward yourself and your family member with IBD. It's essential to understand and compassionately acknowledge each other's experiences and feelings.",
    },
    {
      image: "card10",
      title: "Time Management",
      description:
        "Efficiently manage your time to balance caregiving responsibilities with self-care and other commitments.",
    },
  ];

  const letTryToDoIt = [
    "When feeling stressed or anxious, notice the need for comfort.",
    "Move to a peaceful, private place.",
    "Select a comforting self-touch, like holding\n your own hand or placing a hand on your heart.",
    "Start the self-supportive touch gesture gently. Use light pressure and make slow, deliberate movements.",
    "Focus on deep, calming breaths as you touch.",
    "Acknowledge your emotions without judgment.",
    "Include positive affirmations to reassure yourself.",
    "Practice as long as it comforts you.",
    "Ease up on the touch as you start feeling better.",
  ];

  const overComingSelfCriticism = [
    {
      label: "Awareness",
      description:
        "You notice that you often think, 'I'm not doing enough for my loved one. I'm failing as a caregiver.'",
    },
    {
      label: "Challenge",
      description:
        "Ask yourself, 'Is this thought based on facts or am I being too hard on myself?' Are there moments where you've provided care effectively?",
    },
    {
      label: "Replace",
      description:
        "Replace the negative thought with, 'I'm doing my best to care for my loved one, and it's okay to ask for help when needed. I've been able to provide support in many ways.'",
    },
    {
      label: "Practice Mindfulness",
      description:
        "When you're feeling overwhelmed, take a moment to acknowledge your stress without self-judgment. Say to yourself, 'I'm feeling stressed right now, but that's okay. It's a part of the caregiving journey.'",
    },
    {
      label: "Use Affirmations",
      description:
        "Create affirmations like, 'I am a loving and dedicated caregiver. I can handle the challenges that come my way.'",
    },
    {
      label: "Seek Support",
      description:
        "If you consistently feel overwhelmed or self-critical, consider talking to a therapist, support group, or counsellor to help you cope with the demands of caregiving.",
    },
    {
      label: "Be Consistent",
      description:
        "Implement these strategies regularly to manage your self-talk and emotional well-being.",
    },
  ];

  const goalSettingBulletList = [
    {
      title: "Identify your needs",
      description: "Recognising what areas of self-care you need to address.",
    },
    {
      title: "Be Realistic",
      description:
        "Your goals should be achievable within your current circumstances.",
    },
    {
      title: "Prioritise",
      description:
        "Determine which self-care aspects are most important at the moment.",
    },
    {
      title: "Set SMART Goals",
      description:
        "Make your goals Specific, Measurable, Achievable, Relevant, and Time-bound (SMART).",
    },
    {
      title: "Track Progress",
      description:
        "Use a journal or an app to track your progress. Celebrate small victories along the way to stay motivated.",
    },
    {
      title: "Adjust as Needed",
      description:
        "If your circumstances change or you realise a goal isn't working for you, be willing to adjust or change it.",
    },
  ];

  const smartTextSections = [
    {
      title: "Specific (S): Your goal should be clear and specific.",
      placeholder: 'Example: "I will start a regular jogging routine."',
    },
    {
      title:
        "Measurable (M): Your goal should include a way to measure your progress.",
      placeholder: 'Example: "I will jog for 30 minutes three times a week."',
    },
    {
      title:
        "Achievable (A): Ensure your goal is realistic and attainable given your current circumstances.",
      placeholder:
        'Example: "I will begin with jogging for 30 minutes and gradually increase the duration."',
    },
    {
      title:
        "Relevant (R): Your goal should align with your personal values and long-term objectives.",
      placeholder:
        'Example: "Jogging is important for my overall health and well-being."',
    },
    {
      title: "Time-bound (T): Set a clear timeframe for achieving your goal.",
      placeholder:
        'Example: "I will achieve this jogging routine within the next month."',
    },
  ];

  const stressManagementList = [
    {
      title: "Emotional Distress",
      description:
        "Witnessing a loved one's pain and suffering can be emotionally distressing, leading to feelings of helplessness, sadness, and anxiety.",
    },
    {
      title: "Uncertainty",
      description:
        "IBD is a chronic condition with unpredictable flares and remissions. The uncertainty about the course of the disease can cause stress and anxiety.",
    },
    {
      title: "Role Changes",
      description:
        "Family members may need to take on new roles, such as becoming caregivers, which can disrupt their usual routines and create additional stress.",
    },
    {
      title: "Lifestyle Adjustments",
      description:
        "The need to make dietary and lifestyle changes to accommodate the patient's needs can lead to stress for family members.",
    },
    {
      title: "Social Isolation",
      description:
        "Caring for a family member with IBD may limit the family's ability to socialise, leading to feelings of isolation and loneliness.",
    },
    {
      title: "Caregiver Burnout",
      description:
        "The demands of caregiving can be overwhelming, leading to caregiver burnout, which includes physical and emotional exhaustion.",
    },
    {
      title: "Stigma and Misunderstanding",
      description:
        "Stigmatisation of IBD and misunderstandings from others can contribute to feelings of stress and frustration.",
    },
  ];

  const handlingStressList = [
    {
      title: "Self-Care",
      description:
        "Prioritise your own well-being by engaging in self-care activities like exercise, meditation, or hobbies that bring you joy.",
      image: "stress1",
    },
    {
      title: "Stress-Reduction Techniques",
      description:
        "Practice stress-reduction techniques like deep breathing, progressive muscle relaxation, or mindfulness to manage stress in the moment.",
      image: "stress2",
    },
    {
      title: "Connect with Friends and Family",
      description:
        "Stay connected with friends and family who can provide emotional support and social interaction.",
      image: "stress3",
    },
    {
      title: "Open Communication",
      description:
        "Maintain open and honest communication with the patient and other family members to express your feelings and concerns.",
      image: "stress4",
    },
    {
      title: "Delegate and Share Responsibilities",
      description:
        "Share caregiving responsibilities with other family members or seek help from professional caregivers to reduce your workload.",
      image: "stress5",
    },
    {
      title: "Professional Help",
      description:
        "Consider speaking with a therapist or counsellor to address any emotional challenges you may be facing.",
      image: "stress6",
    },
  ];

  const developingEQ = [
    {
      title: "Self-Awareness",
      description:
        "Start by recognising your emotions when dealing with IBD. Try journaling and self-reflection to identify your emotional triggers.",
    },
    {
      title: "Self-Regulation",
      description:
        "Manage emotions effectively using techniques like deep breathing, mindfulness, and meditation.",
    },
    {
      title: "Empathy",
      description:
        "Understand and share others' feelings through active listening and supportive presence.",
    },
    {
      title: "Effective Communication",
      description:
        "Express emotions clearly, use 'I' statements, and actively listen to your loved one's feelings and needs. For example, using 'I feel worried when I see you in pain' instead of 'You make me worried when you're in pain'.",
    },
  ];

  const benefitEQ = [
    {
      title: "Enhanced Emotional Regulation",
      description:
        "EQ equips you with the skills to recognise and manage your own emotions effectively, reducing stress and promoting well-being.",
    },
    {
      title: "Stronger Relationships",
      description:
        "Understanding and empathising with your loved one's emotional experiences fosters stronger, more supportive relationships.",
    },
    {
      title: "Informed Decision-Making",
      description:
        "EQ enables you to approach challenges with a level head, making informed, emotionally intelligent decisions regarding your loved one's care.",
    },
    {
      title: "Stress Management",
      description:
        "Techniques like mindfulness and self-regulation reduce stress, helping you stay calm amid the uncertainties of IBD.",
    },
  ];

  const stepMediation = [
    "Find a quiet, comfy place to sit or lie down.",
    "Close your eyes if you like.",
    "Focus on your toes, then feet, and move up your body, one part at a time.",
    "Notice any sensations or tension as you go.",
    "Take slow, deep breaths.",
    "When you reach your head, expand your awareness to your whole body.",
    "Open your eyes when you're ready.",
  ];

  const addressingConcers = [
    {
      title: "Time Constraints",
      description:
        "Incorporate quick, effective workouts that fit your schedule.",
      image: "concern1",
    },
    {
      title: "Fatigue",
      description:
        "Opt for low-impact exercises, like gentle stretching or swimming.",
      image: "concern2",
    },
    {
      title: "Stress",
      description:
        "Understand that exercise can reduce stress and improve your emotional well-being.",
      image: "concern3",
    },
  ];

  const exerciseIdeas = [
    {
      title: "Walking",
      description:
        "A simple yet effective way to boost your fitness. Consider family walks to strengthen your bond and improve your health.",
    },
    {
      title: "Swimming",
      description: "A low-impact, full-body workout suitable for all ages.",
    },
    {
      title: "Home-Based Workouts",
      description:
        "Explore online workout routines that you can follow at home.",
    },
    {
      title: "Yoga",
      description:
        "Gentle yoga routines promote relaxation, flexibility, and balance.",
    },
  ];

  const tipsOnHowToAsk = [
    {
      title: "Assess Your Needs",
      description:
        "Assess what you need help with. This could include specific caregiving tasks, emotional support, or respite care to take a break.",
    },
    {
      title: "Identify Potential Helpers",
      description:
        "Make a list of family members, friends, neighbours, or groups who may be willing and able to assist.",
    },
    {
      title: "Have a Conversation",
      description:
        'Use "I" statements to communicate with them. For example, "I\'m feeling overwhelmed, and I could really use some help with grocery shopping."',
    },
    {
      title: "Be Specific",
      description:
        "When asking for help, be specific about what you need. Whether it's running errands or taking on certain caregiving tasks, clarity is key.",
    },
    {
      title: "Be prepared for refusal",
      description:
        "Expect that some people may hesitate or say no. Don't take it personally, and be open to alternatives or asking others. Keep the communication respectful and understanding.",
    },
    {
      title: "Gratefully Accept help",
      description:
        "When someone offers to help, accept their offer with gratitude. Recognise that they are genuinely trying to support you and the person you're caring for.",
    },
  ];

  const selfCareList = [
    {
      title: "Guilty Feelings",
      description:
        "Some caregivers feel it's selfish to prioritise their own needs over their loved one's, hindering self-care.",
    },
    {
      title: "Fear of Letting Down",
      description:
        "Caregivers fear that self-care could mean letting their loved one down or not meet their caregiver responsibilities.",
    },
    {
      title: "Perceived Expectations",
      description:
        "Believing they must always be available; family caregivers sacrifice their well-being due to societal or patient-related pressures.",
    },
    {
      title: "Perfectionism",
      description:
        "Caregivers who hold perfectionist beliefs may think they have to do everything flawlessly and never make mistakes.",
    },
    {
      title: "Misconceptions about Self-Care",
      description:
        "Caregivers might have inaccurate ideas about self-care, thinking it's time-consuming or indulgent.",
    },
    {
      title: "Belief in Self-Sufficiency",
      description:
        "Some family caregivers insist on handling everything without help, impeding self-care.",
    },
  ];

  const selfCompassionList = [
    {
      title: "Self-Kindness",
      description:
        "Being kind to yourself, especially in tough times or when you make mistakes. It's about replacing self-criticism with self-nurturing and understanding.",
    },
    {
      title: "Common Humanity",
      description:
        "Understanding that everyone experiences suffering and imperfection. You are not alone in your challenges and mistakes.",
    },
    {
      title: "Mindfulness",
      description:
        "Mindfulness is a state of mind where you observe your thoughts and feelings without judgment, allowing them to be as they are without trying to push them away or hide from them.",
    },
  ];

  const mindfulMindmap = [
    {title: "Reduced Stress and Increased Calm"},
    {title: "Greater Emotional Regulation"},
    {title: "Cultivation of Self‑Compassion"},
    {title: "Effective Management of Challenging Thoughts"},
    {title: "Enhanced Self‑Awareness"},
  ];

  const learningPoints = [
    {
      title: "Prioritise Self-Care and Self-Compassion:",
      points: [
        "Embrace self-care practices to maintain your well-being.",
        "Cultivate self-compassion, acknowledging your efforts and challenges.",
      ],
    },
    {
      title: "Set Realistic Goals and Exercise Regularly:",
      points: [
        "Establish achievable goals to avoid overwhelming yourself.",
        "Make time for regular exercise to enhance physical and mental resilience.",
      ],
    },
    {
      title: "Practice Stress Management and Emotional Intelligence:",
      points: [
        "Use stress-reduction techniques to manage emotional distress.",
        "Develop emotional intelligence to navigate caregiving challenges.",
      ],
    },
    {
      title: "Seek Support and Communicate Openly:",
      points: [
        "Connect with others for emotional support and practical assistance.",
        "Maintain open communication with your loved one and healthcare professionals.",
      ],
    },
  ];

  const developingEQColor = ["#b2ebf2", "#dcecc8", "#ffe0b1", "#ffccbc"];

  const tipsOnHowToAskColor = [
    "#EF9A9A",
    "#E0BBE4",
    "#FFAB91",
    "#9FA8DA",
    "#90CAF9",
    "#80CBC4",
  ];

  const isMobile = useMediaQuery({query: "(max-width: 900px)"});
  const isBigScreen = useMediaQuery({query: "(min-width: 1700px)"});

  const chunk = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };

  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  return (
    <>
      <div className="unlocking-inner-banner">
        <img
          src={require("../assets/images/unlockingInner/banner.jpg")}
          alt="blog-banner"
        />
        <div className="text">
          {isMobile && <br />}
          {isMobile && <br />}
          <h1>Unlocking{isMobile && <br />} inner</h1>
          <h1>resilience</h1>
        </div>
      </div>
      <div className="unlocking-inner">
        <h2>How to promote self-care and coping strategies?</h2>

        <div className="article-content-container">
          <div id="Self-Care" className="no1">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Self-Care"
                />
                Self-Care
              </h3>
              <hr />
            </div>
            <div className="sub-title">
              <h4 className="sub-title-p">Self-Care for Family</h4>
            </div>
            <div className="no1text">
              <p>
                Living with an individual with IBD can feel like navigating a
                rollercoaster of uncertainties. However, in the relentless
                journey of supporting a loved one with IBD, it's easy to forget
                the most crucial person: yourself. Let's uncover ways to
                prioritise self-care.
              </p>
            </div>
            <div className="sub-title">
              <h4 className="sub-title-p">Why We Forget Self-Care</h4>
            </div>
            <div className="no1text">
              <p>
                Attitudes and beliefs can form personal barriers that stand in
                the way of caring for yourself, especially in the context of
                being a family member of someone with a chronic condition like
                IBD.
                <br />
                <br /> Here's how attitudes and beliefs can create these
                barriers:
              </p>
            </div>
            <div className="checkboxes">
              {selfCareList.map((item, index) => (
                <div key={index} className="checkbox">
                  <input type="checkbox" id={`checkbox${index + 2}`} />
                  <div className="checkbox-text">
                    <h4 htmlFor={`checkbox${index + 2}`}>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="rainbow-box">
              <img
                src={require("../assets/images/unlockingInner/lightbulb.png")}
                alt=""
              />
              <p>
                What attitudes or beliefs have you faced that made self-care
                challenging in your role as a caregiver or in other parts of
                your life?
              </p>
            </div>
            <div className="no1text">
              <p>
                Overcoming these barriers often involves a shift in attitudes
                and beliefs. Caregivers need to recognise that self-care is not
                selfish but necessary for their well-being and their ability to
                provide effective care. Changing these attitudes and beliefs can
                lead to a healthier balance between caregiving and self-care.
                It's essential to address these mental barriers to achieve a
                sustainable and compassionate caregiving role. You can learn
                more about Self-Compassion in the next section.
              </p>
            </div>
            <div className="sub-title">
              <h4 className="sub-title-p">Time to Move Forward</h4>
            </div>
            <div className="no1text">
              <p>
                Once you've started recognising personal barriers to effective
                self-care, you can initiate behaviour change, taking gradual
                steps forward. Let's explore some self-care tools that will not
                only set you on a path of well-being but also empower you to
                craft a happier and healthier life.
              </p>
            </div>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Self-Care"
            />
          </div>

          <div id="Self-Compassion" className="no2">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Self-Compassion"
                />
                Self-Compassion
              </h3>
              <hr />
            </div>
            <div className="no2text">
              <p>
                Amid the challenges of supporting a loved one on their journey
                with IBD, one often overlooked but vital aspect is taking care
                of yourself. Discover the transformative power of
                self-compassion for family caregivers. It's time to put your
                well-being first as you care for your loved ones. Explore how
                self compassion can be your guiding light on this journey of
                love and support.
              </p>
            </div>
            <div className="sub-title">
              <h4 className="sub-title-p">
                The Three Elements of Self-Compassion by Dr. Kristin Neff
              </h4>
            </div>

            <div className="three-textcard-container">
              {selfCompassionList.map((card, index) => (
                <div
                  key={index}
                  className="textcard"
                  style={{
                    backgroundColor:
                      index === 0
                        ? "#FFE5FA"
                        : index === 1
                        ? "#E8E2F7"
                        : "#BDE0FE",
                    borderRadius: "32px",
                  }}
                >
                  <h4 style={{textAlign: "center"}}>{card.title}</h4>
                  <p>{card.description}</p>
                </div>
              ))}
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">
                How to Apply Self-Compassion to Your Care Giving Role
              </h4>
            </div>

            <div className="card-container">
              {selfCompassionCards.map((card, index) => (
                <div key={index} className="card">
                  <h4 style={{textAlign: "center"}}>{card.title}</h4>
                  <div className="card-image">
                    <img
                      src={require(`../assets/images/unlockingInner/${card.image}.png`)}
                      alt={card.title}
                    />
                  </div>
                  <div className="card-text">
                    <p>{card.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p lg">
                Tools for Boosting Your Self-Compassion
              </h4>
            </div>
            <div className="collapsible-container">
              <Collapsible
                trigger="Self-Supportive Touch"
                triggerTagName="div"
                triggerClassName="collapsible-closed-trigger"
                triggerOpenedClassName="collapsible-trigger-open"
              >
                <div className="no2text">
                  <p>
                    Self-supportive touch refers to using touch as a means of
                    self-soothing or providing oneself with comfort and
                    emotional support. It can be a helpful technique for
                    managing stress, anxiety, and emotional distress.
                  </p>
                </div>
                <div className="youtube-container">
                  <YouTubeVideo videoId="5dhlLuqm5qU" />
                </div>
                <p style={{textAlign: "center"}}>
                  "Tools for Practicing Self Compassion."YouTube, 15 Dec.2023,{" "}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=5dhlLuqm5qU"
                  >
                    https://www.youtube.com/watch?v=5dhlLuqm5qU
                  </a>
                </p>
                <div className="flex-center">
                  <div className="sub-title">
                    <h4 className="sub-title-p">Let's try to do it!</h4>
                  </div>
                  <div className="letTryToDoIt">
                    {letTryToDoIt.map((step, index) => (
                      <>
                        <p className="textbox" key={index}>
                          {step}
                        </p>
                        {index !== letTryToDoIt.length - 1 && (
                          <i className="fa-solid fa-arrow-down"></i>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div className="no2text">
                  <p>
                    Some individuals may not find placing a hand over their
                    heart comforting. Feel free to explore alternative areas of
                    your body where a gentle touch brings soothing relief. These
                    options include:
                  </p>
                  <ul>
                    <li>Placing one hand on your cheek</li>
                    <li>Gently stroking your arms for comfort</li>
                    <li>Cradling your face in the warmth of your hands</li>
                  </ul>
                </div>
              </Collapsible>
              <Collapsible
                trigger="Overcoming Self-Criticism"
                triggerTagName="div"
                triggerClassName="collapsible-closed-trigger"
                triggerOpenedClassName="collapsible-trigger-open"
              >
                <div className="no2text">
                  <p>
                    Sometimes, as a family caregiver for someone with IBD, you
                    might not like something about yourself. It's common to feel
                    shame, insecurity, or that you're not 'good enough.'
                    Remember, it's okay to be imperfect – that's just being
                    human.
                    <br /> It's time to change your self-critical thoughts and
                    show kindness to yourself. You can give these steps a try,
                    either by speaking them aloud or thinking them silently.
                  </p>
                </div>

                <div className="flex-center">
                  <div className="bullet-list">
                    <ul>
                      {overComingSelfCriticism.map((step, index) => (
                        <li key={index} className="bullet-item">
                          <h3>{step.label}</h3>
                          <p>{step.description}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Collapsible>
              <Collapsible
                trigger="Self-Compassion Journal"
                triggerTagName="div"
                triggerClassName="collapsible-closed-trigger"
                triggerOpenedClassName="collapsible-trigger-open"
              >
                <div className="no2text">
                  <p>
                    Self-compassion involves treating oneself with the same
                    kindness, care, and understanding that one would offer to a
                    friend when facing personal challenges or setbacks. Keeping
                    a Self-Compassion journal typically involves writing down
                    thoughts, feelings, and experiences related to
                    self-compassion.
                  </p>
                </div>
                <div className="no2text">
                  <p>
                    A self-compassion journal can be a valuable tool in
                    promoting self-compassion, reducing self-criticism, and
                    improving overall well-being. It's a way to actively work on
                    building a kinder relationship with oneself.
                  </p>
                </div>

                <div className="letTryToDoIt">
                  <p className="textbox">
                    In your personal journal, write down
                    <br /> anything that triggered negative emotions,
                    <br />
                    any self-judgment, or any challenging
                    <br />
                    experiences that brought you distress.
                  </p>
                  <i className="fa-solid fa-arrow-down"></i>
                  <p className="textbox">
                    Write down the feelings
                    <br /> that arose in those moments.
                  </p>
                  <i className="fa-solid fa-arrow-down"></i>
                  <p className="textbox">
                    Document your responses to each event,
                    <br /> incorporating mindfulness, common humanity,
                    <br /> and applying kindness to reframe the situation
                    <br /> in a more self-compassionate light.
                  </p>
                </div>

                <div className="no2text">
                  <p>
                    Set aside some quiet time to establish a calm and reflective
                    environment. You can either utilise the self-compassion
                    worksheet provided, or you're welcome to write in your
                    personal journal on your own paper.
                  </p>
                  <br />
                  <a
                    href={require("../assets/images/unlockingInner/Self-Compassion Journal.pdf")}
                    target="_blank"
                    className="pdf-link"
                    onClick={() =>
                      api.sendTimeLog(LOG.DOWNLOAD_SELF_COMPASSION)
                    }
                  >
                    Self-Compassion Journal Worksheet
                    <img
                      alt=""
                      className="clickable"
                      src={require("../assets/images/empowering/pdf-icon.png")}
                    />
                  </a>
                </div>
              </Collapsible>
              <div className="no2text">
                <p>
                  You might find more information on{" "}
                  <ScrollLink to={"Self-compassion"}>
                    <span className="scroll-link-text">self-compassion</span>
                  </ScrollLink>{" "}
                  by visiting Dr. Kristin Neff's website
                  <a
                    href="https://self-compassion.org/"
                    target="_blank"
                    className="pdf-link"
                  >
                    https://self-compassion.org/
                  </a>
                </p>
              </div>
            </div>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Self-Compassion"
            />
          </div>

          <div id="Goal Setting" className="no3">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Goal Setting"
                />
                Goal Setting
              </h3>
              <hr />
            </div>

            <div className="no3text">
              <p>
                After you've decided that taking care of yourself is important,
                it's crucial to set clear goals to make lasting changes.
                <br />
                <br /> Setting goals for self-care involves a thoughtful process
                to ensure they are meaningful and achievable. Here's a simple
                guide on how to set self-care goals:
              </p>
            </div>

            {/* <div style={{width: "100%", height: isMobile ? "auto" : "600px"}}>
              <MindMap
                radius={300}
                angles={[50, 90, 130, 230, 270, 310]}
                data={goalSettingBulletList.map((item, index) => (
                  <div key={index} className="goal-setting-item">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                ))}
              />
            </div> */}

            <ol className="zigzag-rounded-list">
              {goalSettingBulletList.map((item, index) => (
                <li key={index}>
                  <p className={index % 2 === 0 ? "left" : "right"}>
                    <h3>{item.title}</h3>
                    {item.description}
                  </p>
                </li>
              ))}
            </ol>

            <div className="no3text half">
              <p>
                Take a moment to reflect on your self-care needs and set your
                personal goals. You can either type your self-care goals in this
                text box or write them down on paper.
              </p>
            </div>

            <a
              href={require("../assets/images/unlockingInner/SMART Goals.pdf")}
              target="_blank"
              className="pdf-link"
              onClick={() => api.sendTimeLog(LOG.DOWNLOAD_SMART_GOALS)}
            >
              SMART Goals Template
              <img
                alt=""
                className="clickable"
                src={require("../assets/images/empowering/pdf-icon.png")}
              />
            </a>

            <a
              href={require("../assets/images/unlockingInner/Self Care Checklist.pdf")}
              target="_blank"
              className="pdf-link"
              onClick={() => api.sendTimeLog(LOG.DOWNLOAD_SELF_CARE_CHECKLIST)}
            >
              Weekly Self Care Checklist
              <img
                alt=""
                className="clickable"
                src={require("../assets/images/empowering/pdf-icon.png")}
              />
            </a>

            <div className="image-text-container">
              <div className="hide">
                <img
                  src={require("../assets/images/unlockingInner/smart-half.png")}
                  alt=""
                />
              </div>
              <div className="show">
                {smartTextSections.map((section, index) => (
                  <div key={index} className="textinput">
                    <p>{section.title}</p>
                    <textarea type="text" placeholder={section.placeholder} />
                  </div>
                ))}
              </div>
            </div>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Goal Setting"
            />
          </div>

          <div id="Stress Management" className="no4">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Stress Management"
                />
                Stress Management
              </h3>
              <hr />
            </div>

            <p className="no4-description2">
              Family members of individuals with IBD can experience a range of
              stress factors due to the challenges associated with the
              condition.
              <br />
              <br /> Here are some common stress factors that family members of
              individual with IBD may face:
            </p>
            <div className="checkboxes">
              {stressManagementList.map((step, index) => (
                <div key={index} className="checkbox">
                  <input type="checkbox" id={`stressManagementList${index}`} />
                  <div className="checkbox-text">
                    <h4 htmlFor={`stressManagementList${index}`}>
                      {step.title}
                    </h4>
                    <p>{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="rainbow-box">
              <img
                src={require("../assets/images/unlockingInner/lightbulb.png")}
                alt=""
              />
              <p>
                What are the top stressors associated with IBD condition that
                really get under your skin?
              </p>
            </div>
            <p className="no4-description2">
              Now that we've explored the common stress factors that family
              members of individuals with IBD may face, let's shift our focus to
              practical stress management strategies. By addressing these
              challenges, we can work towards enhancing your well-being and
              providing effective support for your loved ones.
            </p>
            <div className="sub-title">
              <h4 className="sub-title-p center">Steps to manage the stress</h4>
            </div>

            <ol className="rounded-list">
              <li>
                <p>
                  Early recognition of warning signs is key for effective stress
                  management. This can involve sleep disturbances, mood swings,
                  and social withdrawal or isolation.
                </p>
              </li>
              <li>
                <p>
                  Find out what is causing the stress, as mentioned in the
                  earlier discussion about&nbsp;
                  <ScrollLink to={"Stress Management"}>
                    <span className="scroll-link-text">
                      stress factors related to IBD.
                    </span>
                  </ScrollLink>
                </p>
              </li>
              <li>
                <p>
                  Figure out what you have control over and what you don't. For
                  instance, we can make changes in ourselves, but we can't fix
                  IBD.
                </p>
              </li>
              <li>
                <p>
                  Taking action to reduce stress empowers us. Stress relief can
                  be simple, including activities like those listed below.
                  Identify what works for you and get started!
                </p>
              </li>
            </ol>

            <div className="sub-title">
              <h4 className="sub-title-p">Tips for handling stress</h4>
            </div>

            <div className="card-container">
              {handlingStressList.map((card, index) => (
                <div key={index} className="card">
                  <div className="card-image">
                    <img
                      src={require(`../assets/images/unlockingInner/${card.image}.png`)}
                      alt={card.title}
                    />
                  </div>
                  <h4>{card.title}</h4>
                  {index === 1 ? (
                    <p>
                      Practice stress-reduction techniques like deep breathing,
                      progressive muscle relaxation, or&nbsp;
                      <ScrollLink to={"Mindfulness"}>
                        <span className="scroll-link-text">mindfulness</span>
                      </ScrollLink>
                      &nbsp;to manage stress in the moment.
                    </p>
                  ) : (
                    <p>{card.description}</p>
                  )}
                </div>
              ))}
            </div>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Stress Management"
            />
          </div>

          <div id="Emotional Management" className="no5">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Emotional Management"
                />
                Emotional Management
              </h3>
              <hr />
            </div>

            <div className="quote-box">
              <p style={{fontStyle: "italic"}}>
                "It’s a rollercoaster of emotions. It’s anger, it’s frustration,
                it’s fear, it’s anxiety, it’s not fair, why is it happening to
                my daughter, what did she do wrong, it’s just horrible
                absolutely horrible."&nbsp;
                <span style={{fontWeight: "600", textAlign: "end"}}>
                  - Kathryn, whose daughter has IBD -
                </span>
              </p>
            </div>

            <div>
              <p>
                There may be frequent and unpredictable changes to the physical
                and emotional health of the person with IBD. This instability in
                health for the person with IBD had an impact on family members’
                lives and relationships with each other, creating fluctuating
                emotions.
              </p>
            </div>

            <div>
              <p>
                Supporting a loved one with Inflammatory Bowel Disease (IBD) can
                be emotionally challenging, and the journey often demands more
                than just well-wishing. Emotionally intelligent individuals
                often find it easier to manage these complexities and offer
                steadfast support. In this article, we explore how harnessing
                the power of Emotional Intelligence (EQ) can be a game-changer
                in emotional management
              </p>
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">
                Understanding Emotional Intelligence
              </h4>
            </div>

            <div>
              <p>
                Emotional Intelligence, often abbreviated as EQ, is the capacity
                to recognise, understand, and manage your own emotions and the
                emotions of others. In the context of supporting a loved one
                with IBD, EQ offers invaluable skills for emotional management.
              </p>
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">Developing EQ Strategies</h4>
            </div>

            {isMobile ? (
              <div
                style={{display: "flex", flexDirection: "column", gap: "16px"}}
              >
                {developingEQ.map((item, index) => (
                  <div key={index} className="textcard">
                    <h3>{item.title}</h3>
                    <hr style={{backgroundColor: developingEQColor?.[index]}} />
                    <p>{item.description}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mindmap-container-2x2">
                <div className="elements">
                  {developingEQ.map((card, index) => (
                    <div className="element">
                      <div className={`element-title pos-${index}`}>
                        <h4 style={{textAlign: "center"}}>{card.title}</h4>
                      </div>
                      <div key={index} className={`description pos-${index}`}>
                        {index === 1 ? (
                          <p>
                            Manage emotions effectively using techniques like
                            deep breathing,&nbsp;
                            <ScrollLink to={"Mindfulness"}>
                              <span className="scroll-link-text">
                                mindfulness
                              </span>
                            </ScrollLink>
                            , and meditation.
                          </p>
                        ) : (
                          <p>{card.description}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="sub-title">
              <h4 className="sub-title-p">
                Benefits of Emotional Intelligence (EQ)
              </h4>
            </div>

            <div className="image-with-content">
              <div className="image">
                <img
                  src={require("../assets/images/unlockingInner/eq.png")}
                  alt=""
                />
              </div>
              <div className="checkboxes">
                {benefitEQ.map((card, index) => (
                  <div key={index} className="checkbox">
                    {/* <input type="checkbox" id={`checkbox${index}`} /> */}
                    <h3>-</h3>
                    <div className="checkbox-text">
                      <h4 htmlFor={`checkbox${index}`}>{card.title}</h4>
                      <p>{card.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <p>
                In the journey of supporting a loved one with IBD, emotional
                management is key. Emotional Intelligence (EQ) strategies
                provide a roadmap for recognising, understanding, and
                effectively managing emotions, both for yourself and in your
                interactions with your loved one. By harnessing EQ skills, you
                can offer unwavering support, nurture strong relationships, and
                promote your own well-being in the face of the emotional
                complexities of IBD.
              </p>
            </div>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Emotional Management"
            />
          </div>

          <div id="Mindfulness" className="no6">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Mindfulness"
                />
                Mindfulness
              </h3>
              <hr />
            </div>

            <div>
              <p>
                Mindfulness is a technique that involves paying attention to the
                present moment without judgment. It means being aware of your
                thoughts, feelings, and surroundings without getting caught up
                in them.
              </p>
            </div>

            {isMobile ? (
              <>
                <h4>Benefits of Mindfulness</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {mindfulMindmap.map((item, index) => (
                    <div key={index} className="mindfulness-item">
                      <p>{item.title}</p>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: isMobile ? "auto" : "700px",
                  backgroundImage: isMobile
                    ? "none"
                    : `url(${require("../assets/images/unlockingInner/mindfulness.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: "32px",
                }}
              >
                <MindMap
                  radius={250}
                  angles={[49, 108, 180, 252, 311]}
                  title={
                    <h3>
                      Benefits
                      {isMobile ? " " : <br />}
                      of
                      {isMobile ? " " : <br />}
                      Mindfulness
                    </h3>
                  }
                  data={mindfulMindmap.map((item, index) => (
                    <div key={index} className="mindfulness-item">
                      <p>{item.title}</p>
                    </div>
                  ))}
                />
              </div>
            )}

            <div className="sub-title">
              <h4 className="sub-title-p">Some common mindfulness exercises</h4>
            </div>

            <div className="collapsible-container">
              <Collapsible
                trigger="Three-minute breathing space"
                triggerTagName="div"
                triggerClassName="collapsible-closed-trigger"
                triggerOpenedClassName="collapsible-trigger-open"
              >
                <div>
                  <p>
                    <span style={{fontWeight: "600"}}>
                      Three-minute breathing space :
                    </span>{" "}
                    a brief mindfulness exercise that helps individuals quickly
                    become more present and centred.
                    <br />
                    <br />
                    The 3-Minute Breathing Space exercise can help reduce
                    stress, increase mindfulness, and promote a sense of calm
                    and presence, making it a useful tool for managing
                    challenging moments or daily stressors. It's a practical and
                    quick way to cultivate mindfulness and self-awareness.
                  </p>
                </div>
                <div className="youtube-container">
                  <YouTubeVideo videoId="amX1IuYFv8A" />
                </div>
                <p style={{textAlign: "center"}}>
                  "Guides Practice: Three-Minute Breathing Space." Youtube, 15
                  Dec 2023,{" "}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=amX1IuYFv8A"
                  >
                    https://www.youtube.com/watch?v=amX1IuYFv8A
                  </a>
                  . More practices can be found on{" "}
                  <a target="_blank" href="https://www.mindful.org/">
                    https://www.mindful.org/
                  </a>
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!isMobile && (
                    <div
                      style={{
                        height: "auto",
                        padding: "32px",
                      }}
                    >
                      <video
                        style={{
                          width: "auto",
                          height: "500px",
                          borderRadius: "32px",
                        }}
                        src={require("../assets/images/unlockingInner/deep.mp4")}
                        type="video/mp4"
                        autoPlay
                        loop
                        muted
                      />
                    </div>
                  )}
                  <ol className="rounded-list" style={{margin: "0 32px"}}>
                    <li>
                      <p>
                        Take a moment to notice how you're feeling and what's on
                        your mind, without trying to change anything. ( 1 minute
                        )
                      </p>
                    </li>
                    <li>
                      <p>
                        Pay attention to your breathing. Take a few deep breaths
                        to help you relax and centre your thoughts. ( 1 minute )
                      </p>
                    </li>
                    <li>
                      <p>
                        Expand your awareness to your whole body and
                        surroundings. Notice how you feel and the world around
                        you. ( 1 minute )
                      </p>
                    </li>
                  </ol>
                </div>
              </Collapsible>
              <Collapsible
                trigger="Body Scan"
                triggerTagName="div"
                triggerClassName="collapsible-closed-trigger"
                triggerOpenedClassName="collapsible-trigger-open"
              >
                <div>
                  <p>
                    Body scan is a technique or guided meditation practice that
                    involves bringing focused attention to different parts of
                    the body. It is often used as a tool to cultivate present
                    -moment awareness, relaxation, and a deeper connection with
                    the body.
                  </p>
                </div>
                <div className="youtube-container">
                  <YouTubeVideo videoId="6iDKF-TrAfE" />
                </div>
                <p style={{textAlign: "center"}}>
                  "Mindful Body Scan for Relaxation".Youtube, 15 Dec 2023.{" "}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=6iDKF-TrAfE"
                  >
                    https://www.youtube.com/watch?v=6iDKF-TrAfE
                  </a>
                  . More practices can be found on {" "}
                  <a target="_blank" href="https://therapyinanutshell.com">
                    https://therapyinanutshell.com
                  </a>
                </p>
                <div className="sub-title">
                  <h3 className="sub-title-p" style={{textAlign: "center"}}>
                    Here's a step-by-step explanation of how to do a body scan
                    meditation:
                  </h3>
                </div>
                <ol className="rounded-list">
                  {stepMediation.map((step, index) => (
                    <li key={index}>
                      <p>{step}</p>
                    </li>
                  ))}
                </ol>
                <img
                  src={require("../assets/images/unlockingInner/sleep.png")}
                  alt=""
                />
              </Collapsible>
              <Collapsible
                trigger="Mindful Tracker"
                triggerTagName="div"
                triggerClassName="collapsible-closed-trigger"
                triggerOpenedClassName="collapsible-trigger-open"
              >
                <div>
                  <p>
                    After completing the mindfulness exercise, take a moment to
                    reflect on your experience. You can record your thoughts,
                    feelings, and any insights that arose during the practice.
                    Write down about how you felt before, during, and after the
                    exercise. This reflection will help you deepen your
                    understanding of your mindfulness practice and track any
                    changes or benefits you may have noticed.
                  </p>
                  <br />
                  <a
                    href={require("../assets/images/unlockingInner/Mindfulness Tracker.pdf")}
                    target="_blank"
                    className="pdf-link"
                    onClick={() =>
                      api.sendTimeLog(LOG.DOWNLOAD_MINDFULNESS_TRACKER)
                    }
                  >
                    Mindfulness Tracker
                    <img
                      alt=""
                      className="clickable"
                      src={require("../assets/images/empowering/pdf-icon.png")}
                    />
                  </a>
                </div>
              </Collapsible>
            </div>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Mindfulness"
            />
          </div>

          <div id="Time to Exercise" className="no7">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Time to Exercise"
                />
                Time to Exercise
              </h3>
              <hr />
            </div>

            <div className="quote-box">
              <p style={{fontStyle: "italic"}}>
                "I lost myself (...)I have no time for me, I don’t have time for
                exercise like I used to. I work really hard in my job and all
                the rest of the time I’m focused on my daughter, I’m focused on
                looking after her."&nbsp;
                <span style={{fontWeight: "600", textAlign: "end"}}>
                  - Kathryn -whose daughter has IBD
                </span>
              </p>
            </div>
            <p>
              Caring for a family member with Inflammatory Bowel Disease (IBD)
              can be emotionally and physically demanding. In the midst of your
              caregiving responsibilities, it's easy to forget to take care of
              yourself. However, exercise can be a powerful tool to help you
              navigate the challenges of caregiving while maintaining your own
              well-being.
            </p>

            <div>
              <div className="sub-title">
                <h4 className="sub-title-p">The Benefits of Exercise</h4>
              </div>
              <br />
              <p>
                When you make exercise a part of your routine, you're not just
                investing in your physical health. You're also giving yourself
                the gift of improved mental and emotional well-being.
              </p>
            </div>

            <div className="sub-title">
              <p
                className="sub-title-p"
                style={{fontWeight: "600", marginLeft: "16px"}}
              >
                Regular physical activity can:
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "32px",
                gap: "64px",
              }}
            >
              {!isMobile && (
                <div
                  style={{
                    height: "auto",
                  }}
                >
                  <video
                    style={{
                      width: "auto",
                      height: "500px",
                      borderRadius: "32px",
                      boxShadow: "0px 0px 8px 0px #000000",
                    }}
                    src={require("../assets/images/unlockingInner/exercise.mp4")}
                    type="video/mp4"
                    autoPlay
                    loop
                    muted
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "64px",
                }}
              >
                <div
                  className="textcard"
                  style={{boxShadow: "0px 0px 16px 0px #80CBC4"}}
                >
                  <p>Reduce stress and anxiety, and promote relaxation</p>
                </div>
                <div
                  className="textcard"
                  style={{boxShadow: "0px 0px 16px 0px #9FA8DA"}}
                >
                  <p>Elevate your mood and boost energy levels</p>
                </div>
                <div
                  className="textcard"
                  style={{boxShadow: "0px 0px 16px 0px #FFC8D8"}}
                >
                  <p>Improve physical health and prevent disease</p>
                </div>
              </div>
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">Addressing Concerns</h4>
            </div>

            <div>
              <p>
                We understand that as a family caregiver, you may have concerns
                and barriers to exercise. You might be juggling a busy schedule
                or feeling fatigued. It's essential to address these concerns.
              </p>
            </div>

            <div className="three-card-container">
              {addressingConcers.map((card, index) => (
                <div key={index} className="card">
                  <div className="card-image">
                    <img
                      src={require(`../assets/images/unlockingInner/${card.image}.png`)}
                      alt={card.title}
                    />
                  </div>
                  <div className="card-text">
                    <h4>{card.title}</h4>
                    <p>{card.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">Getting Started</h4>
            </div>

            <div>
              <p>
                Begin by finding activities that resonate with your interests
                and daily life. Exercise doesn't have to be a separate chore; it
                can be an enjoyable and integral part of your days.
              </p>
            </div>

            <div className="image-with-content">
              <div className="image">
                <img
                  src={require("../assets/images/unlockingInner/getting.png")}
                  alt=""
                />
              </div>
              <div
                className="bullet-list-label-only"
                style={{alignItems: "center"}}
              >
                <ul>
                  <li className="bullet-item">
                    <label>
                      Engage in activities the whole family can enjoy together.
                    </label>
                  </li>
                  <li className="bullet-item">
                    <label>
                      Create a supportive environment where everyone encourages
                      one another to stay active.
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">Exercise ideas</h4>
            </div>

            <div className="four-textcard-container">
              {exerciseIdeas.map((card, index) => (
                <div key={index} className="textcard">
                  <h4>{card.title}</h4>
                  <hr />
                  <p>{card.description}</p>
                </div>
              ))}
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">Safety and Considerations</h4>
            </div>

            <div>
              <p>
                Before you embark on any new exercise programme, especially if
                you have underlying health concerns, consult with a healthcare
                professional. They can offer guidance on exercise suitable for
                your individual fitness levels and medical conditions.
              </p>
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">Tracking Progress</h4>
            </div>

            <div className="textbox">
              <p>
                Keep track of your exercise routines and note any improvements
                in your energy levels, mood, or overall health. Use fitness
                apps, journals, or calendars to stay motivated.
              </p>
              <br />
              <p>
                <a
                  href={require("../assets/images/unlockingInner/Workout Tracker.pdf")}
                  target="_blank"
                  className="pdf-link"
                  onClick={() => api.sendTimeLog(LOG.DOWNLOAD_WORKOUT_TRACKER)}
                >
                  Workout Tracker Template
                  <img
                    alt=""
                    className="clickable"
                    src={require("../assets/images/empowering/pdf-icon.png")}
                  />
                </a>
              </p>
            </div>

            <div className="sub-title">
              <h4 className="sub-title-p">Building a Supportive Community</h4>
            </div>

            <div>
              <p>
                Consider exercising with other family members and IBD patients.
                Join local exercise classes or support groups to connect with
                others facing similar challenges. The power of community and
                support is immeasurable.
              </p>
            </div>

            <p>
              By incorporating exercise into your life, you're not only
              supporting your own well-being, but you're also becoming a better
              caregiver. Remember, self-care is not selfish; it's a powerful
              tool for managing the demands of caregiving and maintaining your
              physical and emotional health.
            </p>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Time to Exercise"
            />
          </div>

          <div id="Accepting and Asking for Help" className="no8">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Accepting and Asking for Help"
                />
                Accepting and Asking for Help
              </h3>
              <hr />
            </div>

            <div className="quote-box">
              <p style={{fontStyle: "italic"}}>
                "I don’t feel that I need to go and download to people because I
                deal with it myself, rightly or wrongly but that’s just the way
                I am as a person"&nbsp;
                <span style={{fontWeight: "600", textAlign: "end"}}>
                  - Kathryn -whose daughter has IBD
                </span>
              </p>
            </div>
            <div>
              <p>
                As a family caregiver, it's essential to recognise that you
                don't have to shoulder the responsibilities and challenges
                alone. Many family caregivers struggle with seeking help because
                they don't want to burden others or admit they can't do it all.
              </p>
              <br />
              <p>
                Asking for help and accepting assistance is not a sign of
                weakness but rather a sign of strength, as it allows you to
                provide better care and maintain your own well-being. You have
                various sources to turn to for help, including family, friends,
                healthcare professionals, and community groups. It's important
                to reach out to them instead of waiting until you're
                overwhelmed, exhausted, or your health is compromised.
              </p>
            </div>
            <br />

            <div className="sub-title">
              <h4 className="sub-title-p">Tips on how to ask</h4>
            </div>

            {isBigScreen ? (
              <div className="zigzag-list">
                {chunk(tipsOnHowToAsk, 2).map((chunk, i) => (
                  <div className="hex-row" key={i}>
                    {chunk.map((item, j) => (
                      <div className="hex" key={j}>
                        <div className="left" />
                        <div className="middle">
                          <h4>{item.title}</h4>
                          <hr
                            style={{
                              backgroundColor:
                                tipsOnHowToAskColor[(i + 1) * (j + 1) - 1],
                            }}
                          />
                          <p>{item.description}</p>
                        </div>
                        <div className="right" />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{display: "flex", flexDirection: "column", gap: "16px"}}
              >
                {tipsOnHowToAsk.map((item, index) =>
                  isMobile ? (
                    <div key={index} className="textcard">
                      <label>{item.title}</label>
                      <hr
                        style={{backgroundColor: tipsOnHowToAskColor[index]}}
                      />
                      <p>{item.description}</p>
                    </div>
                  ) : (
                    <div
                      className="hex"
                      key={index}
                      style={{
                        marginLeft: index % 2 === 0 ? "0" : "auto",
                      }}
                    >
                      <div className="left" />
                      <div className="middle">
                        <h3>{item.title}</h3>
                        <hr
                          style={{backgroundColor: tipsOnHowToAskColor[index]}}
                        />
                        <p>{item.description}</p>
                      </div>
                      <div className="right" />
                    </div>
                  )
                )}
              </div>
            )}

            <div>
              <p>
                Remember that asking for and accepting help is a strength, not a
                weakness. It allows you to provide better care and maintain your
                own well-being, ultimately benefiting both you and the person
                you're caring for. Don't hesitate to lean on your support
                network; you don't have to do it all on your own.
              </p>
            </div>
            <div className="sub-title default">
              <p style={{fontWeight: "600"}} className="sub-title-p">
                IBD impacting your family? Watch 3 parents master self-care and
                build resilience. Get their real-life tips!
              </p>
            </div>
            <div className="youtube-container">
              <YouTubeVideo videoId="_K1_uJrFuI4" />
            </div>
            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Accepting and Asking for Help"
            />
          </div>

          <div id="Learning Points & Tools" className="no9">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="unlocking-inner-resilience"
                  submodule="Learning Points & Tools"
                />
                Learning points & Tools
              </h3>
              <hr />
            </div>

            <div className="textbox">
              <div className="sub-title default">
                <h4 className="sub-title-p">
                  Unlocking Resilience for family members
                </h4>
              </div>
              <div style={{display: "flex"}}>
                <ul>
                  {learningPoints.map((item, index) => (
                    <li
                      className="bullet-item"
                      key={index}
                      style={{marginBottom: "24px"}}
                    >
                      <p style={{fontWeight: "600"}}>{item.title}</p>
                      <ul>
                        {item.points.map((point, i) => (
                          <li key={i}>
                            <p style={{marginTop: "8px", lineHeight: "1.7"}}>
                              {point}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
              <p>
                By integrating these elements into your daily life, you can
                unlock and strengthen your inner resilience as a family member
                supporting someone with IBD.
              </p>
            </div>

            <div className="textbox">
              <div className="sub-title default">
                <h4 className="sub-title-p">Tools</h4>
              </div>
              <br />
              <p>
                <Pdf
                  name="Self-Compassion Journal Worksheet"
                  filePath={require("../assets/images/unlockingInner/Self-Compassion Journal.pdf")}
                  logAction={LOG.DOWNLOAD_SELF_COMPASSION}
                />
                <Pdf
                  name="SMART Goals Template"
                  filePath={require("../assets/images/unlockingInner/SMART Goals.pdf")}
                  logAction={LOG.DOWNLOAD_SMART_GOALS}
                />
                <Pdf
                  name="Weekly Self Care Checklist"
                  filePath={require("../assets/images/unlockingInner/Self Care Checklist.pdf")}
                  logAction={LOG.DOWNLOAD_WEEKLY_SELF}
                />
                <Pdf
                  name="Mindfulness Tracking"
                  filePath={require("../assets/images/unlockingInner/Mindfulness Tracker.pdf")}
                  logAction={LOG.DOWNLOAD_MINDFULNESS_TRACKING}
                />
                <Pdf
                  name="Workout Tracking Template"
                  filePath={require("../assets/images/unlockingInner/Workout Tracker.pdf")}
                  logAction={LOG.DOWNLOAD_WORKOUT_TRACKING}
                />
              </p>
            </div>

            <MarkAsRead
              module="unlocking-inner-resilience"
              submodule="Learning Points & Tools"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UnlockingInner;
