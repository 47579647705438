import React, {useEffect, useState} from "react";
import YouTubeVideo from "./YoutubeRenderer";
import {protectedPage} from "../utils/auth";
import MarkAsRead from "./MarkAsRead";
import SaveSubmodule from "./SaveSubmodule";
import Pdf from "./Pdf";
import {LOG} from "../constant/timeLog";

const Empowering = ({isMenuVisible, userLogin, setUserLogin}) => {
  const openFileInNewTab = (filePath) => {
    window.open(filePath, "_blank");
  };
  const [hoveredPart, setHoveredPart] = useState("");

  const No1FlipCard = ({name, imgPath, detail, link, filePath, fileName}) => {
    return (
      <div className="flip-card">
        <div className="front">
          <img alt="" src={imgPath}></img>
          <p className="name">{name}</p>
        </div>
        <div className="back">
          <p className="name">{name}</p>
          <p className="detail">{detail}</p>
          {filePath && (
            <div
              className="pdf-link"
              onClick={() => openFileInNewTab(filePath)}
            >
              <p className="link">{fileName}</p>
              <img
                alt=""
                className="clickable"
                src={require("../assets/images/empowering/pdf-icon.png")}
              ></img>
            </div>
          )}
          {link && (
            <a
              target="_blank"
              rel="noreferrer"
              href={link}
              className="learn-more"
            >
              Learn more
            </a>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  return (
    <>
      <div className="empowering-banner">
        <img
          src={require("../assets/images/empowering/banner.jpg")}
          alt="blog-banner"
        />
        <div className="text">
          <h1>Supporting individuals with IBD</h1>
        </div>
      </div>
      <div className="empowering">
        <h2>How we can provide support for our loved one with IBD?</h2>
        <div className="article-content-container">
          <div id="Fatigue" className="no1">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Fatigue"
                />
                Fatigue
              </h3>
              <hr />
            </div>
            <p className=" no1text1 description1">
              Many people with IBD experience fatigue. Fatigue is more than just
              feeling tired; it is...
            </p>
            <div className="six-icon">
              <div className="icon1 left icon">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no1-1.png")}
                ></img>
                <p className="name">Extreme Tiredness</p>
              </div>
              <div className="icon2 left icon">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no1-2.png")}
                ></img>
                <p className="name">Exhausted beyond usual</p>
              </div>
              <div className="icon3 left icon">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no1-3.png")}
                ></img>
                <p className="name">Lack of energy</p>
              </div>
              <div className="icon4 left icon">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no1-4.png")}
                ></img>
                <p className="name">Not relieved by enough rest</p>
              </div>
              <div className="icon5 left icon">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no1-5.png")}
                ></img>
                <p className="name">Can come at any time, unpredictable</p>
              </div>
              <div className="icon6 left icon">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no1-6.png")}
                ></img>
                <p className="name">Different from day to day</p>
              </div>
            </div>
            <div className="box1 quote-box middle-box">
              <p>
                <span className="quote">
                  “Fatigue is the one I’ve consistently struggled with from day
                  one. It comes and goes like it’s not always as bad but there
                  are weeks when I just want to sleep all the time”{" "}
                </span>
                <span className="name"> - Kate, living with IBD -</span>
              </p>
            </div>
            <h4 className="subheading">
              Ways to measure and communicate about fatigue
            </h4>
            <div className="box2">
              <ul>
                <li>
                  <span className="name">
                    Learn about the "Spoon Theory" and use it as a way to help
                    individuals with IBD discuss their fatigue with family and
                    others more effectively.
                  </span>{" "}
                  The spoon theory was originated by{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://butyoudontlooksick.com/"
                  >
                    Christine Miserandino
                  </a>
                  , it is a metaphor used to explain what's like to live with a
                  chronic disease or disability. The Spoon Theory is also a
                  metaphor that simplifies the concept of limited energy by
                  comparing it to a set number of "spoons" each day. It's a
                  useful tool for people with IBD to communicate their energy
                  limitations and daily struggles caused by fatigue.
                </li>
              </ul>
            </div>
            <div className="spoon">
              <img
                alt=""
                src={require("../assets/images/empowering/no1-spoon.png")}
              ></img>
              <p className="spoon-textbox">
                Imagine people have a certain number of "spoons" each day, and
                each spoon represents our energy. For people with chronic
                conditions, like IBD, these spoons are limited. Every activity
                they do, even simple ones like getting out of bed, takes away a
                spoon. Once their spoons are gone for the day, they're out of
                energy and may need to rest.{" "}
                <span className="name">
                  Please note that each person is different and each day is
                  different, there is no-one size fits all spoon equation
                </span>
              </p>
            </div>

            <p className=" no1text2 description1">
              So, the Spoon Theory helps others understand that people with IBD
              and fatigue (not everyone have fatigue) have to carefully manage
              their energy because they don't have an endless supply like
              healthy individuals do. It's a way to explain why they might have
              to say no to things or cancel plans because they've run out of
              "spoons" for the day.
            </p>
            <div className="box3">
              <ul>
                <li>
                  <span className="name">Using validated fatigue scale. </span>
                  Empower your loved one with IBD by encouraging them to use a
                  validated fatigue scale, such as{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/measure-your-fatigue"
                  >
                    the Inflammatory Bowel Disease Fatigue (IBD-F) scale.
                  </a>{" "}
                  This tool can help them measure and track their fatigue,
                  making it easier to discuss their symptoms with healthcare
                  professionals, family, and friends.
                </li>
              </ul>
            </div>
            <h4 className="subheading">
              Ways to help people with IBD manage fatigue
            </h4>
            <div className="flip-cards">
              <No1FlipCard
                name="Check if they are having a flare-up or anaemia or nutrient deficiencies"
                imgPath={require("../assets/images/empowering/no1-flip1.png")}
                detail="You can persuade the person with IBD to discuss fatigue with their
            health care team. One of the most crucial steps in managing fatigue
            is to bring the condition under control. If the individual with IBD
            feels tired, it could be a sign of an IBD flare-up. So, it's worth
            letting the IBD team or GP know early on. If the condition is under
            control, the next step could involve testing blood for anaemia or
            deficiencies in nutrients."
              />
              <No1FlipCard
                name="Work/School"
                imgPath={require("../assets/images/empowering/no1-flip2.png")}
                detail="If they're struggling to concentrate, you can recommend they have a conversation with their employers or school/university. Schools and universities are often willing to assist students in managing symptoms like fatigue. They might offer deadline extensions or provide extra exam time to help."
                link="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/employment-and-education?parent=23191&page=1&tags=&category=23191&sort=newest"
              />
              <No1FlipCard
                name="Diet"
                imgPath={require("../assets/images/empowering/no1-flip3.png")}
                detail="There isn't a magic diet to fix fatigue in Crohn's or Colitis, but eating a balanced and healthy diet might help. Many people with these conditions don't get all the nutrients they need for various reasons. If you're not sure how to adjust the diet for a family member with IBD, consider talking to a dietitian."
                link="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
              />
              <No1FlipCard
                name="Stay hydrated"
                imgPath={require("../assets/images/empowering/no1-flip4.png")}
                detail="Staying hydrated is crucial, especially during a flare-up. Consider sipping fluids consistently instead of large quantities at once. If vomiting or diarrhoea is frequent, you might need to replenish sugars and salts. Over-the-counter rehydration solutions can be beneficial."
                link="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/dehydration"
              />
              <No1FlipCard
                name="Physical Activity"
                imgPath={require("../assets/images/empowering/no1-flip5.png")}
                detail="Even though it might seem odd, exercising can actually reduce fatigue for some people. You could propose doing some easy exercises together to make it enjoyable and less like a task. However, keep in mind that sometimes your friend or loved one might not have the energy for even light activity."
              />
              <No1FlipCard
                name="Improving sleep"
                imgPath={require("../assets/images/empowering/no1-flip6.png")}
                detail="It's important for people with fatigue to have a consistent sleep schedule. So, try to avoid making plans that you know will disrupt their usual bedtime."
                link="https://www.nhs.uk/conditions/insomnia/"
              />
            </div>
            <p className="no1summary description1">
              You might find information on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/fatigue"
              >
                fatigue
              </a>{" "}
              and ways to cope with it by visiting the{" "}
              {/* <a
                target="_blank"
                rel="noreferrer"
                href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/fatigue"
              > */}
              Crohn's & Colitis UK website
              {/* </a> */}.
            </p>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Fatigue"
            />
          </div>
          <div id="Pain" className="no2">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Pain"
                />
                Pain
              </h3>
              <hr />
            </div>
            <p className=" no2text1 description1">
              Pain is frequently reported by individuals with inflammatory bowel
              disease, and it has substantial impacts on their overall quality
              of life.
            </p>
            <h4 className="subheading">Understand Types and Causes</h4>
            <div className="background-box1">
              <div className="box1">
                <p className="name">Possible causes</p>
                <div className="checkbox">
                  <div className="rectangle-bullet" />
                  <div className="checkbox-text">
                    <p htmlFor="checkbox1">Severe inflammation in the gut</p>
                  </div>
                </div>
                <div className="checkbox">
                  <div className="rectangle-bullet" />
                  <div className="checkbox-text">
                    <p htmlFor="checkbox1">
                      Strictures, or narrowing of intestines
                    </p>
                  </div>
                </div>
                <div className="checkbox">
                  <div className="rectangle-bullet" />
                  <div className="checkbox-text">
                    <p htmlFor="checkbox1">
                      Small Intestinal Bacterial Overgrowth (SIBO)
                    </p>
                  </div>
                </div>
                <div className="checkbox">
                  <div className="rectangle-bullet" />
                  <div className="checkbox-text">
                    <p htmlFor="checkbox1">Fistulas</p>
                  </div>
                </div>
                <div className="checkbox">
                  <div className="rectangle-bullet" />
                  <div className="checkbox-text">
                    <p htmlFor="checkbox1">
                      Poorly absorbed/digested carbohydrates
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="head2 subheading2">
              Pain from extraintestinal complications
            </h4>
            <p className="info-no2">
              Try hovering/tapping (computer/mobile) on the red dot to see
              additional information.
            </p>
            <div className="no2-anatomy">
              <div className="show-no2 up">
                <div
                  className={`body-no2 ${
                    !!hoveredPart && hoveredPart === "pos1" ? "focus-no2" : ""
                  }`}
                  // onMouseEnter={() => setHoveredPart("pos1")}
                  // onMouseLeave={() => setHoveredPart("")}
                >
                  <img
                    src={require("../assets/images/empowering/eye-pain.png")}
                    alt=""
                  />
                  <p
                    className={`body-no2 ${
                      !!hoveredPart && hoveredPart === "pos1" ? "showp" : ""
                    }`}
                  >
                    Pain or redness in the eyes
                  </p>
                </div>
                <div
                  className={`body-no2 ${
                    !!hoveredPart && hoveredPart === "pos2" ? "focus-no2" : ""
                  }`}
                  // onMouseEnter={() => setHoveredPart("pos2")}
                  // onMouseLeave={() => setHoveredPart("")}
                >
                  <img
                    src={require("../assets/images/empowering/mouth-pain.png")}
                    alt=""
                  />
                  <p
                    className={`body-no2 ${
                      !!hoveredPart && hoveredPart === "pos2" ? "showp" : ""
                    }`}
                  >
                    Mouth ulcers
                  </p>
                </div>
                <div
                  className={`body-no2 ${
                    !!hoveredPart && hoveredPart === "pos3" ? "focus-no2" : ""
                  }`}
                  // onMouseEnter={() => setHoveredPart("pos3")}
                  // onMouseLeave={() => setHoveredPart("")}
                >
                  <img
                    src={require("../assets/images/empowering/back-pain.png")}
                    alt=""
                  />
                  <p
                    className={`body-no2 ${
                      !!hoveredPart && hoveredPart === "pos3" ? "showp" : ""
                    }`}
                  >
                    Back pain
                  </p>
                </div>
              </div>
              <div className="hover-area">
                <img
                  className="body-no2"
                  src={require("../assets/images/empowering/body-pain.png")}
                  alt=""
                />
                <div
                  className={`circle pos1 ${
                    !!hoveredPart && hoveredPart !== "pos1"
                      ? "not-focus-circle-no2"
                      : ""
                  } ${
                    !!hoveredPart && hoveredPart === "pos1"
                      ? "focus-circle-no2"
                      : ""
                  }`}
                  onMouseEnter={() => setHoveredPart("pos1")}
                  onMouseLeave={() => setHoveredPart("")}
                />
                <div
                  className={`circle pos2 ${
                    !!hoveredPart && hoveredPart !== "pos2"
                      ? "not-focus-circle-no2"
                      : ""
                  } ${
                    !!hoveredPart && hoveredPart === "pos2"
                      ? "focus-circle-no2"
                      : ""
                  }`}
                  onMouseEnter={() => setHoveredPart("pos2")}
                  onMouseLeave={() => setHoveredPart("")}
                />
                <div
                  className={`circle pos3 ${
                    !!hoveredPart && hoveredPart !== "pos3"
                      ? "not-focus-circle-no2"
                      : ""
                  } ${
                    !!hoveredPart && hoveredPart === "pos3"
                      ? "focus-circle-no2"
                      : ""
                  }`}
                  onMouseEnter={() => setHoveredPart("pos3")}
                  onMouseLeave={() => setHoveredPart("")}
                />
                <div
                  onMouseEnter={() => setHoveredPart("pos4")}
                  onMouseLeave={() => setHoveredPart("")}
                  className={`circle pos4 ${
                    !!hoveredPart && hoveredPart !== "pos4"
                      ? "not-focus-circle-no2"
                      : ""
                  } ${
                    !!hoveredPart && hoveredPart === "pos4"
                      ? "focus-circle-no2"
                      : ""
                  }`}
                />
                <div
                  onMouseEnter={() => setHoveredPart("pos5")}
                  onMouseLeave={() => setHoveredPart("")}
                  className={`circle pos5 ${
                    !!hoveredPart && hoveredPart !== "pos5"
                      ? "not-focus-circle-no2"
                      : ""
                  } ${
                    !!hoveredPart && hoveredPart === "pos5"
                      ? "focus-circle-no2"
                      : ""
                  }`}
                />
                <div
                  onMouseEnter={() => setHoveredPart("pos6")}
                  onMouseLeave={() => setHoveredPart("")}
                  className={`circle pos6 ${
                    !!hoveredPart && hoveredPart !== "pos6"
                      ? "not-focus-circle-no2"
                      : ""
                  } ${
                    !!hoveredPart && hoveredPart === "pos6"
                      ? "focus-circle-no2"
                      : ""
                  }`}
                />
              </div>
              <div className="show-no2">
                <div
                  // onMouseEnter={() => setHoveredPart("pos4")}
                  // onMouseLeave={() => setHoveredPart("")}
                  className={`body-no2 ${
                    !!hoveredPart && hoveredPart === "pos4" ? "focus-no2" : ""
                  }`}
                >
                  <img
                    src={require("../assets/images/empowering/arm-pain.png")}
                    alt=""
                  />
                  <p
                    className={`body-no2 ${
                      !!hoveredPart && hoveredPart === "pos4" ? "showp" : ""
                    }`}
                  >
                    Skin complications
                  </p>
                </div>
                <div
                  // onMouseEnter={() => setHoveredPart("pos5")}
                  // onMouseLeave={() => setHoveredPart("")}
                  className={`body-no2 ${
                    !!hoveredPart && hoveredPart === "pos5" ? "focus-no2" : ""
                  }`}
                >
                  <img
                    src={require("../assets/images/empowering/knee-pain.png")}
                    alt=""
                  />
                  <p
                    className={`body-no2 ${
                      !!hoveredPart && hoveredPart === "pos5" ? "showp" : ""
                    }`}
                  >
                    Joint pain
                  </p>
                </div>
                <div
                  // onMouseEnter={() => setHoveredPart("pos6")}
                  // onMouseLeave={() => setHoveredPart("")}
                  className={`body-no2 ${
                    !!hoveredPart && hoveredPart === "pos6" ? "focus-no2" : ""
                  }`}
                >
                  <img
                    src={require("../assets/images/empowering/butt-pain.png")}
                    alt=""
                  />
                  <p
                    className={`body-no2 ${
                      !!hoveredPart && hoveredPart === "pos6" ? "showp" : ""
                    }`}
                  >
                    Pain at or near anus
                  </p>
                </div>
              </div>
            </div>
            <h4 className="subheading">
              How to help your loved one manage their pain
            </h4>
            <p className=" no2text1 description1">
              Ask your friend or family member how they deal with their pain,
              and offer to help with it.
            </p>
            <div className="flip-cards">
              <No1FlipCard
                name="Medication"
                imgPath={require("../assets/images/empowering/no2-flip1.png")}
                detail="Some medications can help with IBD pain. Encourage your loved one to speak with their doctor or nurse before starting any medication."
                link="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/treatments?parent=23127&page=1&tags=&category=23127&sort=newest"
              />
              <No1FlipCard
                name="Diet modifications"
                imgPath={require("../assets/images/empowering/no2-flip2.png")}
                detail="Diet plays a role in IBD symptoms, so be cautious when cooking for your loved one. Steer clear of foods that could exacerbate their symptoms. Talk about what seems to make pain worse. Consult with a dietitian or the IBD team before making any major dietary adjustments."
                link="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
              />
              <No1FlipCard
                name="Seeking mental health support"
                imgPath={require("../assets/images/empowering/no2-flip3.png")}
                detail="Stress worsens IBD symptoms. To relieve IBD pain, consider seeing a psychologist, social worker, or a therapist who knows about mind-body treatments which can help to reduce IBD pain. Support groups are also helpful."
              />
              <No1FlipCard
                name="Complementary therapies"
                imgPath={require("../assets/images/empowering/no2-flip4.png")}
                detail="Adding complementary therapies like meditation, mindfulness, yoga, and light exercise to a treatment plan may help to control pain and symptoms."
              />
              <No1FlipCard
                name="Pain tracking"
                imgPath={require("../assets/images/empowering/no2-flip5.png")}
                detail="You can assist them in keeping a record of their pain and other symptoms to identify patterns or potential triggers. Various pain-tracking apps are available. You can also persuade the person with IBD to discuss pain with their health care team."
                // link="https://www.commoninja.com/"
                fileName="Pain Tracker"
                filePath={require("../assets/images/empowering/Pain tracker.pdf")}
              />
            </div>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Pain"
            />
          </div>
          <div id="Medications" className="no3">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Medications"
                />
                Medications
              </h3>
              <hr />
            </div>
            <p className=" no3text1 description1">
              Supporting a family member with Inflammatory Bowel Disease (IBD)
              in managing their medication can be a valuable way to help them
              cope with their condition. Here are some ways you can provide
              support:
            </p>
            <div className="boxes">
              <div className="box123">
                <div className="box1 box">
                  <img
                    alt=""
                    className="detail-img"
                    src={require("../assets/images/empowering/no3-1.png")}
                  ></img>
                  <ul>
                    <li>Learn about IBD and their prescribed medications.</li>
                    <li>Help with medication organisation and reminders</li>
                  </ul>
                  <div
                    className="pdf-link"
                    onClick={() =>
                      openFileInNewTab(
                        require("../assets/images/empowering/Medication tracker.pdf")
                      )
                    }
                  >
                    <p className="link">Medication tracker</p>
                    <img
                      alt=""
                      className="clickable"
                      src={require("../assets/images/empowering/pdf-icon.png")}
                    ></img>
                  </div>
                </div>
                <div className="box23">
                  <div className="box2 box">
                    <ul>
                      <li>
                        Maintain open communication about their condition and
                        treatment plan.
                      </li>
                    </ul>
                    <img
                      alt=""
                      src={require("../assets/images/empowering/no3-2.png")}
                    ></img>
                  </div>
                  <div className="box3 box">
                    <img
                      alt=""
                      src={require("../assets/images/empowering/no3-3.png")}
                    ></img>
                    <ul>
                      <li>
                        Keep an eye out for any side effects that may be related
                        to the medication.
                      </li>
                      <li>
                        Encourage them to report any issues to their healthcare
                        provider.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="box4 box">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no3-4.png")}
                ></img>
                <ul>
                  <li>
                    Respect their autonomy and decisions regarding their
                    treatment.
                  </li>
                  <li>Avoid being overly controlling or judgmental.</li>
                </ul>
              </div>
              <div className="box5 box">
                <p>
                  You can seek further information about medications for Crohn's
                  disease and ulcerative colitis from healthcare provider,
                  pharmacist, the medication's information leaflet, or these
                  websites:{" "}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/treatments?parent=23127&page=1&tags=&category=23127&sort=newest"
                  >
                    Crohn's & Colitis UK website
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="http://www.medicines.org.uk/"
                    rel="noreferrer"
                  >
                    medicines.org.uk
                  </a>
                </p>
              </div>
            </div>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Medications"
            />
          </div>
          <div id="Diet" className="no4">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Diet"
                />
                Diet
              </h3>
              <hr />
            </div>
            <p className=" no4text1 description1">
              Supporting family members with IBD in terms of diet can be crucial
              in helping them manage their condition and improve their overall
              well-being.
            </p>
            <p className=" no4text1 description1">
              Here are some ways you can provide dietary support:
            </p>
            <div className="two-icon two12">
              <div className="icon1 left icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-1.png")}
                ></img>
                <p className="name">Encourage a Balanced Diet</p>
              </div>
              <div className="for-img"></div>
              <div className="icon2 right icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-2.png")}
                ></img>
                <p className="name">Stay Hydrated</p>
              </div>
            </div>
            <div className="two-icon two34">
              <div className="icon3 left icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-3.png")}
                ></img>
                <div className="pdf-link">
                  <p className="name">Identify Trigger Foods</p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
                  >
                    <img
                      alt=""
                      className="clickable"
                      src={require("../assets/images/external-link-symbol.png")}
                    ></img>
                  </a>
                </div>
              </div>
              <div className="for-img">
                <video
                  src={require("../assets/images/empowering/no4-vdo.mp4")}
                  type="video/mp4"
                  className="vdo"
                  autoPlay
                  loop
                  muted
                ></video>
              </div>
              <div className="icon4 right icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-4.png")}
                ></img>
                <div className="pdf-link">
                  <p className="name">Meal Preparation</p>
                  <img
                    className="clickable"
                    alt=""
                    onClick={() =>
                      openFileInNewTab(
                        require("../assets/images/empowering/Meal prep & grocery list.pdf")
                      )
                    }
                    src={require("../assets/images/empowering/pdf-icon.png")}
                  ></img>
                </div>
                {/* <div className="name">Meal Preparation</div> */}
              </div>
            </div>
            <div className="two-icon two56">
              <div className="icon5 left icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-5.png")}
                ></img>
                <p className="name">Consult a Registered Dietitian</p>
              </div>
              <div className="for-img"></div>
              <div className="icon6 right icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-6.png")}
                ></img>
                <div className="pdf-link">
                  <p className="name">Grocery Shopping</p>
                  {/* <img
                    alt=""
                    className="clickable"
                    onClick={() =>
                      openFileInNewTab(
                        require("../assets/images/empowering/Meal prep & grocery list.pdf")
                      )
                    }
                    src={require("../assets/images/empowering/pdf-icon.png")}
                  ></img> */}
                </div>
                {/* <div className="name">Grocery Shopping</div> */}
              </div>
            </div>
            <div className="two-icon two78">
              <div className="icon7 left icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-7.png")}
                ></img>
                <div className="pdf-link">
                  <p className="name">Educate Yourself about Diet</p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
                  >
                    <img
                      alt=""
                      className="clickable"
                      src={require("../assets/images/external-link-symbol.png")}
                    ></img>
                  </a>
                </div>
              </div>
              <div className="for-img"></div>
              <div className="icon8 right icon">
                <img
                  alt=""
                  className="icon-img"
                  src={require("../assets/images/empowering/no4-8.png")}
                ></img>
                <div className="pdf-link">
                  <p className="name">Food Tracking</p>
                  <img
                    alt=""
                    className="clickable"
                    onClick={() =>
                      openFileInNewTab(
                        require("../assets/images/empowering/Food Tracker.pdf")
                      )
                    }
                    src={require("../assets/images/empowering/pdf-icon.png")}
                  ></img>
                </div>
              </div>
            </div>
            <p className="no4text2 full-box">
              You might find more information on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
              >
                diet
              </a>{" "}
              by visiting the Crohn's & Colitis UK website. Crohn's & Colitis UK
              website.
            </p>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Diet"
            />
          </div>
          <div id="Toilet access" className="no5">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Toilet access"
                />
                Toilet access
              </h3>
              <hr />
            </div>
            <div className="no5box1 description1">
              <p>
                <span className="name">Create a Supportive Environment:</span>{" "}
                Make necessary adjustments at home to accommodate the
                individual's needs, such as providing easy toilet access and
                creating a calm and relaxing space.
              </p>
            </div>
            <div className="no5box2 description1">
              <p>
                You can also encourage them to join Crohn's & Colitis UK as a
                member. By{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://crohnsandcolitis.org.uk/get-involved/become-a-member"
                >
                  becoming a member
                </a>
                , they will receive a valuable{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="name"
                  href="https://crohnsandcolitis.org.uk/get-involved/become-a-member/cant-wait-card"
                >
                  'Can't Wait Card'
                </a>{" "}
                that helps communicate the need for urgent access to toilets and{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="name"
                  href="https://crohnsandcolitis.org.uk/get-involved/become-a-member/radar-key"
                >
                  'Radar Key'
                </a>
                . A Radar Key is a specialised key designed to provide access to
                public toilets that are accessible for individuals with specific
                needs or disabilities.
              </p>
            </div>
            <div className="toilet-img">
              <img
                alt=""
                src={require("../assets/images/empowering/no5-1.png")}
              ></img>
              <div className="no5text1">Credit: Crohn's & Colitis UK</div>
            </div>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Toilet access"
            />
          </div>
          <div id="Emotional support" className="no6">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Emotional support"
                />
                Emotional support
              </h3>
              <hr />
            </div>
            <p className=" no6text1 description1">
              Family members can provide valuable emotional support to an
              individual with IBD in the following ways:
            </p>

            <div className="tip1 image-right text-with-img">
              <p className="data">
                <p className="name">Show empathy and understanding:</p>
                <ul>
                  <li>Validate their feelings and experience with IBD</li>
                  <li>Be there to listen!</li>
                  <li>No judgement, just understanding</li>
                  <li>
                    Explore the{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://crohnsandcolitis.org.uk/info-support/support-for-you/mobile-apps/in-my-shoes-24-hours-with-crohn-s-or-colitis"
                    >
                      "In My Shoes"
                    </a>{" "}
                    app developed through a partnership between Crohn’s &
                    Colitis UK and Takeda UK, which offers an interactive way to
                    build empathy for those impacted by IBD.
                  </li>
                </ul>
              </p>
              <img
                alt=""
                src={require("../assets/images/empowering/no6-1.png")}
              ></img>
            </div>
            <div className="tip2 image-left text-with-img">
              <img
                alt=""
                src={require("../assets/images/empowering/no6-2.png")}
              ></img>
              <p className="data">
                <p className="name">Be there to cheer them on!</p>
                <ul>
                  <li>
                    Offer words of encouragement and support when things get
                    tough.
                  </li>
                  <li>
                    Let them know how strong and resilient they are in dealing
                    with their condition.
                  </li>
                  <li>
                    Lift their spirits with positive affirmations and
                    motivation.
                  </li>
                </ul>
              </p>
            </div>
            <div className="tip3 image-right text-with-img">
              <p className="data">
                <p className="name">Provide a safe space for expression:</p>
                <ul>
                  <li>
                    Create a comfy space where they can freely share their
                    emotions.
                  </li>
                  <li>Encourage open and honest talks.</li>
                  <li>Just listen without trying to fix everything</li>
                  <li>Show them you care and understand</li>
                </ul>
              </p>
              <img
                alt=""
                src={require("../assets/images/empowering/no6-3.png")}
              ></img>
            </div>
            <div className="box1 quote-box middle-box">
              <p>
                <span className="quote">
                  "Families, to ask that family member that has IBD to be open
                  and honest and tell them about it because they want to support
                  and they want to fully know everything, the good, the bad, and
                  the ugly just to know everything about It. And how that person
                  feels about it as well."
                </span>
                <span className="name"> - Sara, living with IBD -</span>
              </p>
            </div>
            <div className="tip4 image-left text-with-img">
              <img
                alt=""
                src={require("../assets/images/empowering/no6-4.png")}
              ></img>
              <p className="data">
                <p className="name">
                  Educate yourself about IBD, its impact on mental health, and
                  how to manage it
                </p>
                <ul>
                  <li>
                    Take some time to educate yourself about IBD and It affects
                    your loved one's{" "}
                    <span className="name">mental health and wellbeing</span>.
                  </li>
                  <li>Help them to see the funny side of things</li>
                  <li>
                    Encourage activities that promote joy and relaxation, such
                    as hobbies, exercise, or mindfulness.
                  </li>
                </ul>
              </p>
            </div>
            <div className="box1 quote-box middle-box">
              <p>
                <span className="quote">
                  "So we got a dog, a new puppy to try and cheer our daughter up
                  and it has, it’s been amazing. She adores the dog she really
                  does and the dog brings us all together"
                </span>
                <span className="name"> - Jane, whose daughter has IBD -</span>
              </p>
            </div>
            <div className="tip5 image-right text-with-img">
              <p className="data">
                <p className="name">Connect with support networks:</p>
                <ul>
                  <li>
                    Encourage the individual to connect with support group e.g.{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://crohnsandcolitis.org.uk/info-support/support-for-you/local-networks"
                    >
                      local networks
                    </a>{" "}
                    or online community e.g.{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/crohnsandcolitisuk"
                    >
                      Crohn's & Colitis UK on social media
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://crohnsandcolitis.org.uk/info-support/support-for-you/virtual-social-events"
                    >
                      Virtual Social Event
                    </a>
                  </li>
                  <li>Seek mental health professional help if needed</li>
                </ul>
              </p>
              <img
                alt=""
                src={require("../assets/images/empowering/no6-5.png")}
              ></img>
            </div>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Emotional support"
            />
          </div>
          <div id="Seeking support for family members" className="no7">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Seeking support for family members"
                />
                Seeking support for family members
              </h3>
              <hr />
            </div>
            <div className="no7box1 full-box">
              <p className="intro">
                Seeking external support is crucial for family members when
                dealing with IBD. Crohn's & Colitis UK has developed resources
                on;
              </p>
              <div className="tip1 text-with-img">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no7-1.png")}
                ></img>
                <p className="data">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/employment-and-education/employment-a-guide-for-employees"
                    className="name"
                  >
                    Employment and IBD: A Guide for Employees
                  </a>
                </p>
              </div>
              <div className="tip2 text-with-img">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no7-2.png")}
                ></img>
                <p className="data">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/media/yjvpljrk/children_schools_guide_ed_6d_may_2017.pdf"
                    className="name"
                  >
                    Children and Young People with IBD: A Guide for Schools{" "}
                  </a>
                </p>
              </div>
              <p className="end">
                These guides offer valuable information on how to support
                individuals with IBD in managing their work and school
                responsibilities.
              </p>
            </div>
            <div className="no7box2 full-box">
              <p className="intro">
                If you require financial assistance, it can be beneficial to
                seek resources such as Crohns & Colitis UK's guides on
              </p>
              <div className="tip1 text-with-img">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no7-3.png")}
                ></img>
                <p className="data">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/benefits-and-finances/claiming-disability-living-allowance-dla-for-children"
                    className="name"
                  >
                    Claiming DLA - Children Under 16
                  </a>
                </p>
              </div>
              <div className="tip2 text-with-img">
                <img
                  alt=""
                  src={require("../assets/images/empowering/no7-4.png")}
                ></img>
                <p className="data">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/benefits-and-finances/claiming-pip"
                    className="name"
                  >
                    Claiming Personal Independence Payments (PIP)
                  </a>
                </p>
              </div>
              <p className="intro">
                If you live in Scotland, you might find information on{" "}
                <span className="name purple">
                  Adult Disability Payment (ADP)
                </span>{" "}
                on{" "}
                <a
                  target="_blank"
                  href="https://www.citizensadvice.org.uk/scotland/benefits/sick-or-disabled-people-and-carers/adult-disability-payment-s/adult-disability-payment/adult-disability-payment-s/"
                >
                  Citizens Advice Scotland
                </a>{" "}
                or{" "}
                <a
                  target="_blank"
                  href="https://www.mygov.scot/adult-disability-payment"
                >
                  mygov.scot
                </a>
                .
              </p>
              <p className="end">
                For individuals living in England, you can provide support by
                assisting them in obtaining an NHS prescription prepayment
                certificate, which can help with prescription costs. For
                detailed information on how to apply for the certificate, please
                visit the website{" "}
                <a
                  className="link"
                  target="_blank"
                  href="https://www.gov.uk/get-a-ppc"
                >
                  gov.uk/get-a-ppc
                </a>
                .
              </p>
            </div>
            <p className="vdo-name">
              See some sharing tips from individual with IBD
            </p>
            <div className="vdo-main">
              <div className="youtube-container">
                <YouTubeVideo videoId="whiTkJ8HJ7k" />
              </div>
              <div className="vdo-detail">
                "HOW TO SUPPORT A LOVED ONE WITH INFLAMMATORY BOWEL DISEASE
                (IBD) I THE GRUMBLING GUT". YouTube, 15 Dec 2023,{" "}
                <a
                  target="blank"
                  href="https://www.youtube.com/watch?v=whiTkJ8HJ7k"
                >
                  https://www.youtube.com/watch?v=whiTkJ8HJ7k
                </a>{" "}
                More helpful information can be found on{" "}
                <a target="blank" href="www.thegrumblinggut.com">
                  www.thegrumblinggut.com
                </a>
              </div>
            </div>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Seeking support for family members"
            />
          </div>
          <div id="Learning points & Tools" className="no8">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="empowering-individual-with-ibd"
                  submodule="Learning points & Tools"
                />
                Learning points & Tools
              </h3>
              <hr />
            </div>
            <div className="no8box1 full-box">
              <p>
                <p className="name">
                  Key support strategies for your loved one with IBD
                </p>
                <ol>
                  <li>
                    <p className="name">Health Management:</p>
                    {/* <ul>
                    <li> */}
                    Understand and assist with managing fatigue, pain, and
                    medications if you are able and willing. Collaborate on a
                    balanced diet plan and offer support during times of toilet
                    urgency. Remember, it's all about what feels right for you.
                    Please find below the right tools to assist you.
                    {/* </li>
                  </ul> */}
                  </li>
                  <li>
                    <p className="name">Emotional Well-being:</p>
                    {/* <ul>
                    <li> */}
                    Create an open environment for emotional expression. Support
                    fears and challenges. Take care of your emotional well-being
                    too.
                    {/* </li>
                  </ul> */}
                  </li>
                  <li>
                    <p className="name">Family Unity and External Support:</p>
                    {/* <ul>
                    <li> */}
                    Acknowledge IBD's impact on the family. Encourage dialogue
                    and seek external support if needed.
                    {/* </li>
                  </ul> */}
                  </li>
                </ol>
                <p className="end">
                  Let's create a holistic support system together, doing what
                  feels right for each of us. Your well-being matters.
                </p>
              </p>
            </div>
            <div className="no8box2 full-box">
              <p>
                <h4 className="name topic">Tools</h4>
                <Pdf
                  name="Spoon Theory"
                  filePath={require("../assets/images/empowering/Spoon Theory.pdf")}
                  logAction={LOG.DOWNLOAD_SPOON_THEORY}
                />
                <Pdf
                  name="Pain Tracker"
                  filePath={require("../assets/images/empowering/Pain tracker.pdf")}
                  logAction={LOG.DOWNLOAD_PAIN_TRACKER}
                />
                <Pdf
                  name="Medication Tracker"
                  filePath={require("../assets/images/empowering/Medication tracker.pdf")}
                  logAction={LOG.DOWNLOAD_MEDICATION_TRACKER}
                />
                <Pdf
                  name="Meal plan & Grocery list"
                  filePath={require("../assets/images/empowering/Meal prep & grocery list.pdf")}
                  logAction={LOG.DOWNLOAD_MEAL_PLAN}
                />
                <Pdf
                  name="Food Tracking"
                  filePath={require("../assets/images/empowering/Food Tracker.pdf")}
                  logAction={LOG.DOWNLOAD_FOOD_TRACKING}
                />
              </p>
            </div>
            <div className="no8box3">
              <p>
                Now that you've equipped yourself with tools to support your
                loved one, let's move into the heart of the family. In the next
                module, we'll discover how this individual support weaves into
                the fabric of family resilience, it’s where we uncover the power
                of connection. Get ready to explore how strong family bonds can
                become your secret weapon in navigating life with IBD.
              </p>
            </div>
            <MarkAsRead
              module="empowering-individual-with-ibd"
              submodule="Learning points & Tools"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Empowering;
