const MindMap = ({data = [], title, radius = 100, angles = []}) => {
  const numChildren = data.length;
  const anglesInRadians =
    angles.length > 0
      ? angles.map((angle) => (angle * Math.PI) / 180)
      : Array.from(
          {length: numChildren},
          (_, i) => ((2 * Math.PI) / numChildren) * i
        );

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {title && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          {title}
        </div>
      )}
      {data.map((item, index) => {
        const childAngle = anglesInRadians[index % numChildren] - Math.PI / 2;
        const x = radius * Math.cos(childAngle);
        const y = radius * Math.sin(childAngle);

        return (
          <div
            key={index}
            style={{
              position: "absolute",
              top: `calc(50% + ${y}px)`,
              left: `calc(50% + ${x}px)`,
              transform: "translate(-50%, -50%)",
            }}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default MindMap;
