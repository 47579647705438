import {useRef, useEffect, useState} from "react";
import {dateDisplay} from "../utils/dateDisplay.js";
import {useNavigate} from "react-router-dom";
import {checkLogin, protectedPage} from "../utils/auth.js";
import axios from "axios";
import Config from "../assets/configs/configs.json";

const Post = ({
  post,
  handlePostLike,
  handleCommentLike,
  handleAddComment,
  handleSavePost,
  type,
  userLogin,
  setUserLogin,
}) => {
  const {
    id,
    owner: {username, image},
    topic,
    detail,
    comments,
    tag,
    like_count,
    liked,
    video,
    image: photo,
    bookmarked,
    viewed,
    createdAt,
    status,
  } = post;

  const commentRef = useRef();
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  const getRole = async () => {
    const res = await checkLogin(userLogin, setUserLogin);
    setRole(res.role);
  };

  useEffect(() => {
    getRole();
    protectedPage(userLogin, setUserLogin);
  }, [userLogin]);

  const handleCommentSend = () => {
    handleAddComment(id, commentRef.current.value);
    commentRef.current.value = "";
  };

  const redirectToContent = () => {
    navigate(`/content?id=${id}`);
  };

  const handleChangeStatus = async (blogId, action) => {
    try {
      await axios.patch(
        `${Config.BACKEND_URL}/blog/status`,
        {
          blogId,
          action,
        },
        {
          withCredentials: true,
        }
      );
      window.location.assign("/profile?menu=mypost");
    } catch (error) {
      console.log(error);
    }
  };

  const LikeAction = ({id, liked, like_count, handleLike}) => (
    <div className="action">
      <i
        className={liked ? "fa-solid fa-thumbs-up" : "fa-regular fa-thumbs-up"}
        onClick={() => {
          handleLike(id, !liked);
        }}
      ></i>
      <p>{like_count}</p>
    </div>
  );

  const bookmarkAction = (
    <div className="action">
      <i
        className={
          bookmarked ? "fa-solid fa-bookmark" : "fa-regular fa-bookmark"
        }
        onClick={() => {
          handleSavePost(id, !bookmarked);
        }}
      />
      <p>{bookmarked ? "Unsave" : "Save"}</p>
    </div>
  );

  const commentAction = (
    <div className="action">
      <i
        className="fa-regular fa-comment"
        onClick={type === "blog" ? redirectToContent : undefined}
      />
      <p>{comments.length}</p>
    </div>
  );

  const viewAction = (
    <div className="action">
      <i className="fa-regular fa-eye" />
      <p>{viewed}</p>
    </div>
  );

  const commentsSection = (
    <div className="comments-section">
      {comments.map((comment, index) => {
        const {
          id: commentId,
          owner: {username},
          detail,
          liked,
          like_count,
        } = comment;
        return (
          <div key={index} className="comment">
            <span className="comment-author">{username}</span>
            <p
              className="comment-text"
              dangerouslySetInnerHTML={{__html: detail}}
            />
            <div className="action-bar">
              <LikeAction
                id={commentId}
                liked={liked}
                like_count={like_count}
                handleLike={handleCommentLike}
              />
            </div>
          </div>
        );
      })}
    </div>
  );

  const addComment = (
    <div className="add-comment">
      <textarea
        className="add-comment-input"
        placeholder="Write a comment..."
        ref={commentRef}
      ></textarea>
      <button className="save-button" onClick={handleCommentSend}>
        Send
      </button>
    </div>
  );

  return (
    <div
      className={
        type === "blog" ? "post-container can-click" : "post-container"
      }
    >
      <div
        className="post-header"
        onClick={type === "blog" ? redirectToContent : undefined}
      >
        <div className="profile">
          <img className="image" src={image} alt="profile" />
          <span className="username">{username}</span>
        </div>
        <div className="admin-panel">
          {!!role && role === "admin" && status === "Pending" && (
            <>
              <h3
                className="action approve"
                onClick={() => handleChangeStatus(id, "Verified")}
              >
                Approve
              </h3>
              <h3
                className="action reject"
                onClick={() => handleChangeStatus(id, "Rejected")}
              >
                Reject
              </h3>
            </>
          )}
          {!!role && role === "admin" && status === "Verified" && (
            <>
              <h3
                className="action reject"
                onClick={() => handleChangeStatus(id, "Rejected")}
              >
                Delete
              </h3>
            </>
          )}
        </div>

        <button
          className={type !== "blog" ? "go-back" : "go-back hide"}
          onClick={() => navigate(-1)}
        >
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
      <div
        className="post-body"
        onClick={type === "blog" ? redirectToContent : undefined}
      >
        <h4
          className={type !== "blog" ? "topic" : "topic hide"}
          dangerouslySetInnerHTML={{__html: topic}}
        />
        <p
          className={type !== "blog" ? "detail" : "detail hide"}
          dangerouslySetInnerHTML={{__html: detail}}
          style={{whiteSpace: "pre-line"}}
        />
        {type !== "blog" && video && (
          <video className="video" controls>
            <source src={video} type="video/mp4" />
          </video>
        )}
        {type !== "blog" && photo && (
          <img className="photo" src={photo} alt="post" />
        )}
      </div>
      <div className="post-footer">
        <p className="comment-time">{dateDisplay(createdAt)}</p>
        <div className="action-bar">
          <LikeAction
            id={id}
            liked={liked}
            like_count={like_count}
            handleLike={handlePostLike}
          />
          {commentAction}
          {type !== "blog" && bookmarkAction}
          {viewAction}
        </div>
        {type !== "blog" && (
          <>
            {commentsSection}
            {addComment}
          </>
        )}
      </div>
    </div>
  );
};

export default Post;
