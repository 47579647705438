export const LOG = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    VIEW_HOME: "VIEW_HOME",
    VIEW_UNDERSTANDING: "VIEW_UNDERSTANDING",
    VIEW_EMPOWERING: "VIEW_EMPOWERING",
    VIEW_BUILDING: "VIEW_BUILDING",
    VIEW_UNLOCKING: "VIEW_UNLOCKING",
    VIEW_BLOGS: "VIEW_BLOGS",
    VIEW_FORUMS: "VIEW_FORUMS",
    DOWNLOAD_UNDERSTANDING_BOOKLET: "DOWNLOAD_UNDERSTANDING_BOOKLET",
    DOWNLOAD_EMPOWERING_BOOKLET: "DOWNLOAD_EMPOWERING_BOOKLET",
    DOWNLOAD_BUILDING_BOOKLET: "DOWNLOAD_BUILDING_BOOKLET",
    DOWNLOAD_UNLOCKING_BOOKLET: "DOWNLOAD_UNLOCKING_BOOKLET",
    DOWNLOAD_SPOON_THEORY: "DOWNLOAD_SPOON_THEORY",
    DOWNLOAD_PAIN_TRACKER: "DOWNLOAD_PAIN_TRACKER",
    DOWNLOAD_MEDICATION_TRACKER: "DOWNLOAD_MEDICATION_TRACKER",
    DOWNLOAD_MEAL_PLAN: "DOWNLOAD_MEAL_PLAN",
    DOWNLOAD_FOOD_TRACKING: "DOWNLOAD_FOOD_TRACKING",
    DOWNLOAD_SELF_COMPASSION: "DOWNLOAD_SELF_COMPASSION",
    DOWNLOAD_SMART_GOALS: "DOWNLOAD_SMART_GOALS",
    DOWNLOAD_WEEKLY_SELF: "DOWNLOAD_WEEKLY_SELF",
    DOWNLOAD_MINDFULNESS_TRACKING: "DOWNLOAD_MINDFULNESS_TRACKING",
    DOWNLOAD_WORKOUT_TRACKING: "DOWNLOAD_WORKOUT_TRACKING",
};
