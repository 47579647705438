export const listOfCards = [
  {
    image: require("../assets/images/article01.jpeg"),
    mainText: "Understanding IBD and its impact",
    subText: `"Exploring IBD's symptoms, tests, treatments, and impact on individuals and families"`,
    url: "/informativeModules?topic=understanding-ibd-and-its-impact",
  },
  {
    image: require("../assets/images/article02.jpeg"),
    mainText: "Supporting individuals with IBD",
    subText: `"Guiding family members to provide practical and emotional support to individuals with IBD"`,
    url: "/informativeModules?topic=empowering-individual-with-ibd",
  },
  {
    image: require("../assets/images/article03.jpg"),
    mainText: "Building bonds that last",
    subText: `"Secrets to Strengthening Family Relationships when living with IBD"`,
    url: "/informativeModules?topic=building-bonds-that-last",
  },
  {
    image: require("../assets/images/article04.jpg"),
    mainText: "Unlocking inner resilience",
    subText: `"Finding your path to self-care and coping as a family member of someone with IBD"`,
    url: "/informativeModules?topic=unlocking-inner-resilience",
  },
];
