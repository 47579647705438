import React, {useEffect, useState} from "react";
import Collapsible from "react-collapsible";
import axios from "axios";
import Config from "../assets/configs/configs.json";
import ConfirmModal from "../components/ConfirmModal";

const Faq = ({userLogin, isAdmin, setIsAdmin}) => {
  const [faqs, setFaqs] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [focusFaq, setFocusFaq] = useState();

  const decodeHtmlEntities = (text) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const fetchFAQ = async () => {
    try {
      const res = await axios.get(`${Config.BACKEND_URL}/faq`, {
        withCredentials: true,
      });
      const decodedFAQs = res.data.map((faq) => ({
        ...faq,
        question: decodeHtmlEntities(faq.question),
        answer: decodeHtmlEntities(faq.answer),
      }));
      setFaqs(decodedFAQs);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFAQ = async (id) => {
    try {
      const res = await axios.delete(`${Config.BACKEND_URL}/faq/${id}`, {
        withCredentials: true,
      });
      setFaqs(res.data);
      window.location.assign("/");
    } catch (error) {
      console.error(error);
    }
  };
  const onDelete = async (id) => {
    await setFocusFaq(id);
    setShowConfirm(true);
  };

  const fetchRole = async () => {
    setIsAdmin(userLogin.role === "admin");
  };

  useEffect(() => {
    fetchFAQ();
  }, []);

  useEffect(() => {
    fetchRole();
  }, [userLogin]);

  return (
    <div id="faqs" className="faq-section">
      <div className="topic-section">
        <h2>
          FAQ{" "}
          <a className="faq-anchor" href="/faq">
            (click here to see more faq)
          </a>
        </h2>
        <hr />
      </div>
      <div className="faq-content-wrapper">
        {faqs.map((faq, index) => (
          <div className="container">
            <Collapsible
              key={index}
              trigger={faq.question}
              triggerTagName="div"
              triggerClassName="collapsible-closed-trigger"
              triggerOpenedClassName="collapsible-trigger-open"
            >
              <p>{faq.answer}</p>
            </Collapsible>
            {isAdmin && (
              <button
                onClick={() => onDelete(faq.id)}
                style={{cursor: "pointer"}}
              >
                Remove above FAQ
              </button>
            )}

            <ConfirmModal
              showModalSignal={showConfirm}
              setModalSignal={setShowConfirm}
              header="Delete this FAQ?"
              message="This action cannot be undone"
              onPickLeft={() => deleteFAQ(focusFaq)}
              onPickRight={() => setShowConfirm(false)}
              leftTxt={"Delete"}
              rightTxt={"Cancel"}
              leftBGColor={"red"}
              leftColor={"white"}
              rightBGColor={"grey"}
              rightColor={"white"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
