import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {motion} from "framer-motion";
import {useLocation, useSearchParams} from "react-router-dom";
import {Link as ScrollLink, scroller} from "react-scroll";
import {useMutation, useQuery, useQueryClient} from "react-query";
import UnderstandingIBD from "../components/UnderstandingIBD";
import UnlockingInner from "../components/UnlockingInner";
import BuildingBonds from "../components/BuildingBonds";
import Empowering from "../components/Empowering";
import BackToTopButton from "../components/BackToTopButton";
import FloatingButton from "../components/FloatingButton";
import * as api from "../api/api";
import TrackedElement from "../components/TrackedElement";
import {LOG} from "../constant/timeLog";

const InformativeModulesPage = ({userLogin, setUserLogin}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [path, setPath] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const toggleTimeoutRef = useRef();
  const queryClient = useQueryClient();
  const location = useLocation();
  const [submodule, setSubmodule] = useState("");

  useEffect(() => {
    const topic = searchParams.get("topic");
    setPath(topic);
    window.scrollTo(0, 0);
    if (location.hash) {
      const hash = location.hash;
      const id = hash.substring(1);
      const decodedId = decodeURIComponent(id);
      setSubmodule(decodedId);
    }
  }, [searchParams]);

  useEffect(() => {
    let timeout;
    if (!!submodule) {
      timeout = setTimeout(() => {
        scroller.scrollTo(submodule, {
          duration: 1000,
        });
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [submodule]);

  const {
    data: moduleCompleted,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["module-completed", path],
    queryFn: () => api.getCompletedModule(path),
    enabled: !!path,
  });

  const {data: moduleSaved} = useQuery({
    queryKey: ["module-saved", path],
    queryFn: () => api.getSavedModule(path),
    enabled: !!path,
  });

  const renderTopic = () => {
    switch (path) {
      case "understanding-ibd-and-its-impact":
        return (
          <TrackedElement
            logAction={LOG.VIEW_UNDERSTANDING}
            element={
              <UnderstandingIBD
                userLogin={userLogin}
                setUserLogin={setUserLogin}
                isMenuVisible={isMenuVisible}
              />
            }
          />
        );
      case "building-bonds-that-last":
        return (
          <TrackedElement
            logAction={LOG.VIEW_BUILDING}
            element={
              <BuildingBonds
                userLogin={userLogin}
                setUserLogin={setUserLogin}
                isMenuVisible={isMenuVisible}
              />
            }
          />
        );
      case "unlocking-inner-resilience":
        return (
          <TrackedElement
            logAction={LOG.VIEW_UNLOCKING}
            element={
              <UnlockingInner
                userLogin={userLogin}
                setUserLogin={setUserLogin}
                isMenuVisible={isMenuVisible}
              />
            }
          />
        );
      case "empowering-individual-with-ibd":
        return (
          <TrackedElement
            logAction={LOG.VIEW_EMPOWERING}
            element={
              <Empowering
                userLogin={userLogin}
                setUserLogin={setUserLogin}
                isMenuVisible={isMenuVisible}
              />
            }
          />
        );
      default:
        return <></>;
    }
  };

  const menuItems = {
    "understanding-ibd-and-its-impact": [
      "About Crohn's and Colitis",
      "The Gut",
      "Common Symptoms",
      "What is a Flare-Up?",
      "How do People Get IBD?",
      "How to Get a Diagnosis?",
      "How to Treat IBD?",
      "Our Life is a Roller Coaster!",
      "Learning Points",
    ],
    "empowering-individual-with-ibd": [
      "Fatigue",
      "Pain",
      "Medications",
      "Diet",
      "Toilet access",
      "Emotional support",
      "Seeking support for family members",
      "Learning points & Tools",
    ],
    "building-bonds-that-last": [
      "Family relationship",
      "Parent and non-IBD children",
      "Couple relationship",
      "Communication",
      "Family trips",
      "Learning points",
    ],
    "unlocking-inner-resilience": [
      "Self-Care",
      "Self-Compassion",
      "Goal Setting",
      "Stress Management",
      "Emotional Management",
      "Mindfulness",
      "Time to Exercise",
      "Accepting and Asking for Help",
      "Learning Points & Tools",
    ],
  };

  const articleList = [
    "understanding-ibd-and-its-impact",
    "empowering-individual-with-ibd",
    "building-bonds-that-last",
    "unlocking-inner-resilience",
  ];

  const navigateBar = (position = "") => {
    const hasPrevious = articleList.indexOf(path) > 0;
    const hasNext = articleList.indexOf(path) < articleList.length - 1;
    return (
      <div className={position === "top" ? "navigate-bar-top" : "navigate-bar"}>
        {hasPrevious && (
          <div
            className="button previous"
            onClick={() =>
              setSearchParams({
                topic: articleList[articleList.indexOf(path) - 1],
              })
            }
          >
            <i className="fas fa-chevron-left"></i>
            <p>Prev</p>
          </div>
        )}
        {hasNext && (
          <div
            className="button next"
            onClick={() =>
              setSearchParams({
                topic: articleList[articleList.indexOf(path) + 1],
              })
            }
          >
            <p>Next</p>
            <i className="fas fa-chevron-right"></i>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      if (toggleTimeoutRef.current) {
        clearTimeout(toggleTimeoutRef.current);
      }
    };
  }, []);

  return !!menuItems[path] ? (
    <div className="informative-container">
      <FloatingButton path={path} module={moduleCompleted} />
      {navigateBar("top")}
      <>{renderTopic()}</>
      {navigateBar()}
      <BackToTopButton />
    </div>
  ) : (
    <div className="informative-container"></div>
  );
};

export default InformativeModulesPage;
