import React, {useState, useEffect} from "react";
import {motion} from "framer-motion";
import Collapsible from "react-collapsible";

const HowToUseModal = ({isOpen, closeModal, userLogin, setUserLogin}) => {
  const [dontShowToday, setDontShowToday] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const today = new Date().toDateString();
    const dontShowModalToday = localStorage.getItem("dontShowModalToday");
    if (dontShowModalToday === today) {
      closeModal();
    }
  }, [closeModal, userLogin]);

  const handleDontShowTodayChange = (e) => {
    const checked = e.target.checked;
    setDontShowToday(checked);
    if (checked) {
      const today = new Date().toDateString();
      localStorage.setItem("dontShowModalToday", today);
    } else {
      localStorage.removeItem("dontShowModalToday");
    }
  };

  return !isOpen || loading ? (
    <></>
  ) : (
    <div className="modal-overlay" onClick={closeModal}>
      <motion.div
        initial={{opacity: 0, scale: 0.5}}
        animate={{opacity: 1, scale: 1}}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="howto-content-container">
          <h2 className="reponsive-hide-small">
            Welcome to Your FamilyBridge for IBD Online Support Resource
            Journey!
          </h2>
          <h2 className="reponsive-show-small">Manual Handbook</h2>
          <div className="content-howto">
            <div className="howto-left">
              <div className="howto-img">
                <img src={require("../assets/images/Access.png")} alt="" />
              </div>
              <div className="howto-desc-box">
                <h3>How to use our website</h3>
                <ul>
                  <li>
                    Once you've completed the baseline questionnaires, get ready
                    to dive into your 8-week journey!
                  </li>
                  <li>
                    Log in using your registered credentials to unlock 24/7
                    access to the online intervention.
                  </li>
                  <li>
                    Encourage aiming for at least one hour per week with the
                    website.
                  </li>
                  <li>
                    Explore content that suits your needs and preferences – it's
                    your space!
                  </li>
                </ul>
                <h3 className="show-assist">Getting assistance</h3>
                <p className="show-assist">
                  For additional assistance regarding the FamilyBridge for IBD
                  website, please contact the web coordinator through the{" "}
                  <a onClick={closeModal} href="#contactus">
                    Contact Us
                  </a>{" "}
                  section
                </p>
              </div>
            </div>
            <div className="howto-right">
              <div className="howto-img">
                <img src={require("../assets/images/prefer.png")} alt="" />
              </div>
              <div className="howto-desc-box">
                <h3>Our website offers</h3>
                <div className="howto-collapsible">
                  <Collapsible
                    key="Information for you"
                    trigger="Information for you"
                    triggerTagName="div"
                    triggerClassName="collapsible-closed-trigger"
                    triggerOpenedClassName="collapsible-trigger-open"
                  >
                    <div>
                      <div className="content-manual">
                        <h4>Topics include</h4>
                        <ul>
                          <li>understanding IBD and its impacts</li>
                          <li>supporting individuals with IBD</li>
                          <li>building bonds that last</li>
                          <li>unlocking inner resilience</li>
                        </ul>
                      </div>
                    </div>
                  </Collapsible>
                  <Collapsible
                    key="Our community"
                    trigger="Our community"
                    triggerTagName="div"
                    triggerClassName="collapsible-closed-trigger"
                    triggerOpenedClassName="collapsible-trigger-open"
                  >
                    <div className="content-manual">
                      <h4>Blog & Peer Support Forum</h4>
                      <ul>
                        <li>
                          Connect with like-minded individuals in the "Blog and
                          Forum" area.
                        </li>
                        <li>
                          Share stories, ask questions, and join positive
                          discussions.
                        </li>
                        <li>
                          Our moderator keeps things respectful - goodbye to
                          misleading or hurtful content!
                        </li>
                      </ul>
                    </div>
                  </Collapsible>
                  <Collapsible
                    key="Other resources"
                    trigger="Other resources"
                    triggerTagName="div"
                    triggerClassName="collapsible-closed-trigger"
                    triggerOpenedClassName="collapsible-trigger-open"
                  >
                    <div className="content-manual">
                      <h4>Further Resources & FAQs</h4>
                      <ul>
                        <li>
                          Check out helpful links and FAQs in the "Further
                          Resources" and “FAQs” sections.
                        </li>
                        <li>
                          If you prefer, you can download FamilyBridge for IBD
                          booklets.
                        </li>
                      </ul>
                    </div>
                  </Collapsible>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-howto">
          <div className="footer-assist">
            <h3 className="footer-assist-h3">Getting assistance</h3>
            <p className="footer-assist-p">
              For additional assistance regarding the FamilyBridge for IBD
              website, please contact the web coordinator through the 'Contact
              Us' section
            </p>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="dontShowToday"
              checked={dontShowToday}
              onChange={handleDontShowTodayChange}
            />
            <label htmlFor="dontShowToday"> Don't show for today</label>
          </div>
          {!userLogin.isLogin && (
            <div className="modal-login">
              <a href="/signin">
                <button>Login Now</button>
              </a>
            </div>
          )}
        </div>
        <button onClick={closeModal} className="close-button">
          X
        </button>
      </motion.div>
    </div>
  );
};

export default HowToUseModal;
