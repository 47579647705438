import axios from "axios";
import Config from "../assets/configs/configs.json";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {saveBlog} from "../api/api";
import ConfirmModal from "./ConfirmModal";
import {WholeScreenLoader} from "./LoadingComponent";
import {dateDisplay} from "../utils/dateDisplay.js";

const MyPostAndBookmark = ({type}) => {
  const [postList, setpostList] = useState([]);
  const [bookmarkDelete, setBookmarkDelete] = useState(false);
  const [typeOption, setTypeOption] = useState("all");
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focusPost, setFocusPost] = useState();

  const fetchContent = async () => {
    try {
      let path = `${Config.BACKEND_URL}`;
      let params = {};
      if (type === "mypost") {
        path = path + "/blog/me";
        params = {
          size: 99999,
          sortBy: "newest",
        };
      } else if (type === "bookmark") {
        path = path + "/user/savedpost";
      } else if (type === "postApproval") {
        path = path + "/blog/admin?status=Pending";
      } else if (type === "postMngmt") {
        path = path + "/blog/admin";
      }
      if (typeOption !== "all") {
        params.type = typeOption;
      }
      const res = await axios.get(path, {
        withCredentials: true,
        params: params,
        headers: {user_id: localStorage.getItem("user_id")},
      });
      if (type === "mypost") setpostList(res.data.data);
      else if (type === "bookmark") setpostList(res.data.savedPost);
      else if (type === "postApproval" || type === "postMngmt")
        setpostList(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchContent();
  }, [type, bookmarkDelete, typeOption]);

  useEffect(() => {
    setTypeOption("all");
  }, [type]);

  const handleChangeStatus = async (blogId, action) => {
    setShowConfirm(false);
    setLoading(true);
    try {
      await axios.patch(
        `${Config.BACKEND_URL}/blog/status`,
        {
          blogId,
          action,
        },
        {
          withCredentials: true,
        }
      );
      setLoading(false);
      fetchContent();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const handleSavePost = async (blogId, savedPost) => {
    try {
      await saveBlog({blogId, savedPost});
      setBookmarkDelete(!bookmarkDelete);
    } catch (err) {
      console.error(err);
    }
  };

  const onDelete = async (id) => {
    await setFocusPost(id);
    setShowConfirm(true);
  };

  const deleteAction = (postId) => {
    return (
      <div>
        <h3 className="action reject" onClick={() => onDelete(postId)}>
          ✖ Delete
        </h3>
        <ConfirmModal
          id={postId}
          showModalSignal={showConfirm}
          setModalSignal={setShowConfirm}
          header="Delete this Post?"
          message="This action cannot be undone"
          onPickLeft={() => handleChangeStatus(focusPost, "Rejected")}
          onPickRight={() => setShowConfirm(false)}
          leftTxt={"Delete"}
          rightTxt={"Cancel"}
          leftBGColor={"red"}
          leftColor={"white"}
          rightBGColor={"grey"}
          rightColor={"white"}
        />
      </div>
    );
  };

  const bookmarkAction = (id, bookmarked) => {
    return (
      <div className="bookmark-action">
        <i
          className={
            bookmarked ? "fa-solid fa-bookmark" : "fa-regular fa-bookmark"
          }
          onClick={() => {
            handleSavePost(id, !bookmarked);
          }}
        />
        {<p>{bookmarked ? "Unsave" : "Save"}</p>}
      </div>
    );
  };

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/content?id=${id}`);
  };

  const handleTypeChange = (event) => {
    const selectedValue = event.target.value;
    setTypeOption(selectedValue);
  };
  const typeMapper = {
    mypost: {
      name: "My Posts",
    },
    bookmark: {
      name: "Bookmarks",
    },
    postApproval: {
      name: "Pending Post",
    },
    postMngmt: {
      name: "Post Management",
    },
  };
  return (
    <div className="my-post-and-bookmark">
      <div className="header">
        <h2>{!!typeMapper[type] && typeMapper[type].name}</h2>
        <select
          id="selectOptions"
          value={typeOption}
          onChange={handleTypeChange}
        >
          <option value="all">All</option>
          <option value="blog">Putting Yourself in Someone Else's Shoes</option>
          <option value="forum">Forum</option>
        </select>
      </div>
      <div className="info-container">
        {postList.map((post, i) => {
          return (
            <div className="text">
              <div className="label" onClick={() => handleClick(post["id"])}>
                <h3
                  className="topic"
                  dangerouslySetInnerHTML={{__html: post["topic"]}}
                />
                <div className="description">
                  {type === "mypost" && `${dateDisplay(post["createdAt"])}`}
                  {(type === "bookmark" ||
                    type === "postApproval" ||
                    type === "postMngmt") &&
                    `${post["owner"]["username"]} (${dateDisplay(
                      post["createdAt"]
                    )})`}
                </div>
                <div className="description">
                  {post["type"] === "blog"
                    ? "Putting Yourself in Someone Else’s Shoes"
                    : "Forum"}
                </div>
              </div>
              <div className="value">
                {type === "mypost" && (
                  <div
                    className={`status 
                    ${post["status"] === "Pending" ? "pending" : ""} 
                    ${post["status"] === "Verified" ? "verified" : ""}`}
                  >
                    {post["status"]}
                  </div>
                )}
                {type === "bookmark" &&
                  bookmarkAction(post["id"], post["bookmarked"])}
                <div className="admin-panel">
                  {type === "postApproval" && (
                    <div>
                      <h3
                        className="action approve"
                        onClick={() =>
                          handleChangeStatus(post["id"], "Verified")
                        }
                      >
                        ✔ Approve
                      </h3>
                      <h3
                        className="action reject"
                        onClick={() =>
                          handleChangeStatus(post["id"], "Rejected")
                        }
                      >
                        ✖ Reject
                      </h3>
                    </div>
                  )}
                  {(type === "postMngmt" || type === "mypost") &&
                    deleteAction(post["id"])}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {loading && <WholeScreenLoader />}
    </div>
  );
};

export default MyPostAndBookmark;
