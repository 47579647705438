import axios from "axios";
import Config from "../assets/configs/configs.json";

export const checkLogin = async (userLogin, setUserLogin) => {
  if (localStorage.getItem("user_id")) {
    try {
      if (!userLogin || !userLogin.isLogin) {
        const res = await axios.get(`${Config.BACKEND_URL}/user/check-login`, {
          headers: {
            user_id: localStorage.getItem("user_id"),
          },
          withCredentials: true,
          timeout: 10000,
        });
        setUserLogin(res.data);
        return res.data;
      }
      return userLogin;
    } catch (error) {
      console.log("still have user_id but no jwt: ", error);
      return error;
    }
  } else {
    return false;
  }
};

export const protectedPage = async (userLogin, setUserLogin) => {
  const result = await checkLogin(userLogin, setUserLogin);
  if (!localStorage.getItem("user_id") || !result || !result.isLogin) {
    window.location.assign("/signin");
  }
};
