import axios from "axios";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import MyProfile from "../components/MyProfile";
import MyPostAndBookmark from "../components/MyPostAndBookmark";
import MyMarkAsRead from "../components/MyMarkAsRead";
import Config from "../assets/configs/configs.json";

const ProfilePage = () => {
  const userMenus = {
    me: "Profile",
    mypost: "Post",
    bookmark: "Bookmark",
    completed: "Completed Reading",
    bookmark_module: "Bookmarked Reading",
  };

  const adminMenus = {
    me: "Profile",
    mypost: "Post",
    bookmark: "Bookmark",
    completed: "Completed Reading",
    bookmark_module: "Bookmarked Reading",
    postApproval: "Pending Post",
    postMngmt: "Post Management",
  };

  const [isEdit, setIsEdit] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [menu, setMenu] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const changeParamsMenu = (event) => {
    searchParams.set("menu", event.target.value);
    setSearchParams(searchParams);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 800; // set the maximum width of the image
        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const dataUrl = canvas.toDataURL(file.type);
        setUserInfo({
          ...userInfo,
          image: dataUrl,
        });
      };
      img.src = event.target.result;
    };

    if (file) {
      setImageFile(file);
      reader.readAsDataURL(file);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const id = localStorage.getItem("user_id");
      const res = await axios.post(
        `${Config.BACKEND_URL}/user/info`,
        {
          id: id,
        },
        {
          withCredentials: true,
        }
      );

      const {
        username,
        email,
        prefix,
        firstName,
        lastName,
        phoneNumber,
        image,
        role,
      } = res.data;

      const selectedUserInfo = {
        username,
        email,
        prefix,
        firstName,
        lastName,
        phoneNumber,
        image,
        role,
      };

      setIsAdmin(role === "admin" ? true : false);
      setUserInfo(selectedUserInfo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (isAdmin === null) {
      return;
    }
    if (
      searchParams.get("menu") === null ||
      (isAdmin === false && !(searchParams.get("menu") in userMenus)) ||
      (isAdmin === true && !(searchParams.get("menu") in adminMenus))
    ) {
      searchParams.set("menu", "me");
      setSearchParams(searchParams);
    }
    setMenu(searchParams.get("menu"));
  }, [searchParams, isAdmin]);

  return (
    <div className="profilepage-container">
      <div className="profile-container">
        <div className="content menu-pane">
          <div className="profile-tag card">
            <img className="profile-picture" src={userInfo.image} alt="" />
            {isEdit && (
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleImageChange}
                accept="image/*"
              />
            )}
            <h3>{`${userInfo.firstName} ${userInfo.lastName}`}</h3>
            <p>{`@${userInfo.username}`}</p>
          </div>
          <div className="menu card">
            {!isAdmin &&
              Object.keys(userMenus).map((key) => {
                return (
                  <button
                    value={key}
                    key={`${key}-${userMenus[key]}`}
                    className={key === menu ? "selected" : ""}
                    onClick={changeParamsMenu}
                  >
                    {userMenus[key]}
                  </button>
                );
              })}
            {isAdmin &&
              Object.keys(adminMenus).map((key) => {
                return (
                  <button
                    value={key}
                    key={`${key}-${adminMenus[key]}`}
                    className={key === menu ? "selected" : ""}
                    onClick={changeParamsMenu}
                  >
                    {adminMenus[key]}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="content detail-pane">
          <div className="card">
            {menu === "me" && (
              <MyProfile
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                imageFile={imageFile}
                fetchUserInfo={fetchUserInfo}
              />
            )}
            {(menu === "mypost" ||
              menu === "bookmark" ||
              menu === "postApproval" ||
              menu === "postMngmt") && <MyPostAndBookmark type={menu} />}
            {(menu === "completed" || menu === "bookmark_module") && (
              <MyMarkAsRead type={menu} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
