import React, {useRef, useEffect} from "react";
import YouTubeVideo from "./YoutubeRenderer";
import {protectedPage} from "../utils/auth";
import MarkAsRead from "./MarkAsRead";
import SaveSubmodule from "./SaveSubmodule";

const BuildingBonds = ({isMenuVisible, userLogin, setUserLogin}) => {
  const communicationRef = useRef();
  const no2PostIt = [
    {
      topic: "Acknowledgement",
      detail:
        "Validate their feelings and experiences. Recognise that it's normal for siblings to have a range of emotions, including worry, frustration, jealousy, or guilt. Let them know it's okay to feel these emotions.",
    },
    {
      topic: "Quality time",
      detail:
        "Spend quality time with siblings individually. Engage in activities they enjoy and show an interest in their hobbies and interests. This can help them feel valued and important.",
    },
    {
      topic: "Shared responsibility",
      detail:
        "Encourage non-IBD siblings to be active participants in their sibling's care. They can help with small tasks, attend medical appointments, and offer emotional support.",
    },
    {
      topic: "Celebrate achievement",
      detail:
        "Celebrate milestones and achievements of both the IBD child and non-IBD siblings. This reinforces a sense of equality and pride within the family.",
    },
    {
      topic: "Offer reassurance",
      detail:
        "Reassure siblings of your love and commitment. Let them know that IBD doesn't change the importance of their role in the family.",
    },
    {
      topic: "Support group",
      detail:
        "Connect with other families dealing with IBD to share experiences and learn from one another. This network can offer valuable support and insights.",
    },
  ];
  const no4PostIt = [
    {
      topic: "Create a Safe space",
      detail:
        "Establish an open and non-judgmental environment where family members and partners feel comfortable discussing the impact of IBD on their lives. Ensure that all voices are heard and respected.",
    },
    {
      topic: "Active Listening",
      detail:
        "Practice active listening by giving your full attention to the person speaking, making eye contact, and showing empathy. Avoid interrupting and allow the speaker to express their thoughts and feelings.",
    },
    {
      topic: `Use "I" Statements`,
      detail: `Encourage family members to use "I" statements when expressing themselves. For example, say, "I feel worried when I see you in pain," rather than, "You make me worried."`,
    },
    {
      topic: "Honesty and Transparency",
      detail:
        "Encourage honesty and transparency in your communication. Share your own thoughts, feelings, and concerns openly, and encourage others to do the same. This creates a space for vulnerability and connection.",
    },
    {
      topic: "Empathy",
      detail:
        "Practice empathy by trying to understand the emotions and experiences of your family member or partner with IBD. Acknowledge their struggles and offer emotional support.",
    },
    {
      topic: "Schedule Check-Ins",
      detail:
        "Set aside time for regular check-ins to discuss how everyone is coping with the challenges of IBD. This can be a designated time for family meetings or couple conversations.",
    },
    {
      topic: "Clarify Expectations",
      detail:
        "Be clear about your expectations and ask for clarification when needed. This helps avoid misunderstandings and miscommunications.",
    },
    {
      topic: "Seek Professional Help",
      detail:
        "If communication challenges persist, consider seeking the assistance of a counsellor or therapist who can provide guidance and facilitate productive conversations.",
    },
    {
      topic: "Practice Patience",
      detail:
        "Remember that IBD can be physically and emotionally taxing. Practice patience, especially during times when your family member or partner may be struggling with symptoms or emotional stress.",
    },
    {
      topic: "Conflict Resolution",
      detail:
        "Learn effective conflict resolution techniques. Address disagreements constructively, focusing on finding solutions rather than placing blame.",
    },
  ];
  const postIt = (topic, detail) => {
    return (
      <div className="postit">
        <h4 className="name">{topic}</h4>
        <p className="detail">{detail}</p>
      </div>
    );
  };
  const handleNo1LinkToCommunication = () => {
    if (communicationRef.current) {
      communicationRef.current.scrollIntoView({behavior: "smooth"});
    }
  };

  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  return (
    <>
      <div className="building-bonds-banner">
        <img
          src={require("../assets/images/buildingbonds/banner.jpg")}
          alt="blog-banner"
        />
        <div className="text">
          <h1>Building bonds that last</h1>
        </div>
      </div>
      <div className="building-bonds">
        <h2>How to improve your family/couple relationships?</h2>
        <div className="article-content-container">
          <div id="Family relationship" className="no1">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="building-bonds-that-last"
                  submodule="Family relationship"
                />
                Family relationship
              </h3>
              <hr />
            </div>
            <div className="no1text1 quote-box middle-box">
              {/* <p> */}
              <span className="quote">
                "IBD did impact on our relationship for quite a while.
                Resentfulness on both sides because he was resentful of me
                because he thought I was trying to mother him and I suppose I
                was and I was resentful of him because of the way he was."
              </span>
              <span className="name"> - Tammy, whose son has IBD -</span>
              {/* </p> */}
            </div>
            <p className="description1">
              In a world filled with unique challenges, one constant remains:
              the importance of family. When a loved one is diagnosed with IBD,
              the dynamics within a family can shift. But here's the good news —
              IBD, while challenging, can also provide opportunities for growth,
              resilience, and deeper connections.
            </p>
            <p className="description1">
              In this section, we'll explore practical strategies and insights
              to help your family not just cope with IBD but to thrive together.
            </p>
            <div className="no1text2 box-with-img img-right">
              <div className="full-box text">
                <p>
                  <span className="link" onClick={handleNo1LinkToCommunication}>
                    Enhance communication:
                  </span>{" "}
                  Promote open communication and encourage everyone to express
                  thoughts and feelings without judgment. Actively listen to one
                  another and create a safe space for dialogue. Understand each
                  family member's needs, concerns, and experiences with IBD.{" "}
                </p>
              </div>

              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-1.png")}
              ></img>
            </div>
            <div className="quote-no-box">
              <span className="quote">
                "Just to have open and honest conversations and include everyone
                ...unless you are a child I don’t think you need to be shielded
                from it".{" "}
              </span>
              <span className="name">- Morta, whose sibling has IBD -</span>
            </div>
            <div className="no1text4 box-with-img img-left">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-2.png")}
              ></img>
              <div className="full-box">
                <p>
                  <span className="name">
                    Foster empathy and understanding:{" "}
                  </span>
                  Understand the challenges of the individual with IBD, both
                  physically and emotionally. Provide support, compassion, and
                  care for comfort during difficult times. See improving family
                  communication
                </p>
              </div>
            </div>
            <div className="quote-no-box">
              <span className="quote">
                "I would say think about it from the idea that this person needs
                your support and it’s about being patient with them and if they
                do have for example panic attacks or anxiety it’s about being
                patient with that person and being there and being supportive is
                probably what I would say".{" "}
              </span>
              <span className="name">- Max, whose mother has IBD -</span>
            </div>
            <div className="no1text6 box-with-img img-right">
              <div className="full-box">
                <p>
                  <span className="name">Promote shared decision-making:</span>{" "}
                  Involve all family members in managing IBD, including
                  treatment choices, lifestyle adjustments, and medical
                  appointments. Encourage collaboration and consider everyone's
                  opinions and preferences.
                </p>
              </div>
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-3.png")}
              ></img>
            </div>
            <div className="no1text7 box-with-img img-left">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-4.png")}
              ></img>
              <div className="full-box">
                <p>
                  <span className="name">Set clear boundaries:</span> Establish
                  boundaries within the family regarding the individual's needs
                  and limitations due to IBD. Foster a supportive environment
                  while respecting each family member's autonomy and personal
                  space.
                </p>
              </div>
            </div>
            <div className="no1text8 box-with-img img-right">
              <div className="full-box">
                <p>
                  <span className="name">Be flexible with roles:</span> IBD may
                  disrupt traditional family roles. Adapt and take on different
                  roles as needed to support the individual with IBD and to
                  support each other. This flexibility reduces stress and
                  maintains balance in the family.
                </p>
              </div>
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-5.png")}
              ></img>
            </div>
            <div className="no1text9 box-with-img img-left">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-6.png")}
              ></img>
              <div className="full-box">
                <p>
                  <a
                    href="/informativeModules?topic=unlocking-inner-resilience"
                    className="name"
                  >
                    Promote self-care:
                  </a>{" "}
                  Prioritise self-care for all family members, including the
                  individual with IBD. Encourage activities that enhance
                  physical and emotional well-being, like exercise, hobbies, and
                  relaxation techniques. Self-care reduces stress and improves
                  the overall family dynamic.
                </p>
              </div>
            </div>
            <div className="no1text10 box-with-img img-right">
              <div className="full-box">
                <p>
                  <span className="name">Normalise emotions:</span> Recognise
                  and normalise the range of emotions experienced by the
                  individual with IBD and their family members. Create a safe
                  space for everyone to express feelings and communicate about
                  how they feel without judgment.
                </p>
              </div>
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-7.png")}
              ></img>
            </div>
            <div className="no1text11 box-with-img img-left">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-8.png")}
              ></img>
              <div className="full-box">
                <p>
                  <span className="name">Maintain a sense of humour: </span>
                  Finding moments of humour and light-heartedness can help
                  alleviate stress and tension within the family. Sharing
                  laughter and positive experiences can strengthen bonds and
                  create a more positive family environment.
                </p>
              </div>
            </div>
            <div className="quote-no-box">
              <span className="quote">
                "With my brothers I tend to make a lot of jokes. (...) I’m very
                emotional about my Crohn’s I tend to deal with it with humour, I
                deal with a lot of stuff with humour and with my brothers I make
                fun of the fact that I’m sick. ".{" "}
              </span>
              <span className="name">- Patty, living with IBD -</span>
            </div>
            <div className="no1text13 box-with-img img-right">
              <div className="full-box">
                <p>
                  <span className="name">Seek professional support:</span>{" "}
                  Consult a family therapist or counsellor specialising in
                  chronic illness or family dynamics. They can facilitate
                  communication, guide conflict resolution, and provide coping
                  strategies for the entire family.
                </p>
              </div>
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no1-9.png")}
              ></img>
            </div>
            <p className=" no1text14 summary description1">
              Each family is unique, and it's important to tailor these
              suggestions to your specific family dynamics and circumstances for
              example, some people feel that living with IBD brings them closer
              as a family, but others find it strains relationships.
            </p>
            <MarkAsRead
              module="building-bonds-that-last"
              submodule="Family relationship"
            />
          </div>
          <div id="Parent and non-IBD children" className="no2">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="building-bonds-that-last"
                  submodule="Parent and non-IBD children"
                />
                Parent and non-IBD children
              </h3>
              <hr />
            </div>
            <div className="no2text1 quote-box middle-box">
              <p>
                <span className="quote">
                  "There was no-one, for a lot of it there was no-one that I was
                  going home to. I felt lonelier. I felt very rushed, I think
                  dad would pick me up from school and we’d go to hospital. (…)
                  I felt I didn’t have any, like a lot of them you’d go home and
                  they have time to cuddle on the sofa with their parents and
                  stuff like that and I didn’t have that. So I think home for
                  them was a lot warmer at that point whereas mine was just, it
                  was almost three people in the house living quite separate
                  lives."{" "}
                </span>
                <span className="name">- Morta, whose sibling has IBD -</span>
              </p>
            </div>
            <p className=" no2text2 description1">
              Siblings may sometimes experience a sense of being overlooked or
              neglected when parents or caregivers prioritise the needs of the
              sibling with IBD. This situation can potentially give rise to
              feelings of neglect or jealousy, which may impact the dynamics and
              relationships within the family.
            </p>
            <p className=" no2text2">
              Here are some keys approaches to support siblings of an individual
              with IBD:
            </p>
            <div className="six-postit">
              {no2PostIt.map((post) => postIt(post.topic, post.detail))}
            </div>
            <p className=" no2text4 summary description1">
              Remember that providing support to siblings is an ongoing process.
              Tailor your approach to their individual needs and personalities.
              The goal is to help them cope with the impact of IBD on the family
              and to foster a sense of understanding and unity.
            </p>
            <MarkAsRead
              module="building-bonds-that-last"
              submodule="Parent and non-IBD children"
            />
          </div>
          <div id="Couple relationship" className="no3">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="building-bonds-that-last"
                  submodule="Couple relationship"
                />
                Couple relationship
              </h3>
              <hr />
            </div>
            <div className="no3text1 quote-box middle-box">
              <p>
                <span className="quote">
                  "There are times when we’re really distant to each other
                  because it can be my moods, because I’m tired or yes basically
                  because of the fatigue I’m quite distant and low mood. So a
                  little bit of bickering. So that’s a bit difficult but we’re
                  getting back on track".{" "}
                </span>
                <span className="name">- Tom, living with IBD -</span>
              </p>
            </div>
            <p className=" no3text2 description1">
              Living with IBD can be tough on a couple's relationship, impacting
              both emotional and physical aspects. It can affect your intimacy,
              communication, and lifestyle. In this journey, partners often find
              themselves juggling responsibilities and offering support during
              medical appointments and treatments. Despite these challenges,
              facing IBD together can bring you closer and lead to personal
              growth within your relationship.
            </p>
            <h4 className="no3subheading">
              Tips for improving the couple relationship
            </h4>
            <p className=" no3text3 description1">
              Here are some tips for improving the couple relationship when one
              or both partners are living with IBD:
            </p>
            <div className="tip1 image-right text-with-img">
              <p className="data">
                <p className="name">Understanding the Impact of IBD</p>
                <ol>
                  <li>
                    <span className="name">Understanding IBD:</span> Start by
                    educating yourselves about IBD. Learn about the condition,
                    its symptoms, and the treatment options available. This
                    knowledge will empower you both to face IBD together.
                  </li>
                  <li>
                    <span className="name">Emotional Impact:</span> Recognise
                    that IBD can have an emotional impact. Understand the common
                    emotional challenges your partner may face, such as anxiety,
                    frustration, and fear.
                  </li>
                </ol>
              </p>
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no3-1.png")}
              ></img>
            </div>
            <div className="tip2 image-left text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no3-2.png")}
              ></img>
              <p className="data">
                <p className="name">Effective Communication</p>
                <ol>
                  <li>
                    <span className="name">Create a Safe Space:</span> Foster
                    open and non-judgmental communication. Create a safe space
                    where both partners can express their thoughts and feelings
                    about IBD.
                  </li>
                  <li>
                    <span className="name">Active Listening:</span> Develop
                    active listening skills to truly understand each other's
                    perspectives and concerns.
                  </li>
                </ol>
              </p>
            </div>
            <div className="tip3 image-right text-with-img">
              <p className="data">
                <p className="name">Providing Support</p>
                <ol>
                  <li>
                    <span className="name">Offer Emotional Support:</span> Learn
                    how to provide emotional support during flare-ups and
                    challenging times. Be empathetic, reassuring, and patient.
                  </li>
                  <li>
                    <span className="name">Dietary Accommodations:</span> If
                    dietary changes are needed, explore ways to accommodate
                    them. Find IBD-friendly recipes and make adjustments
                    together.
                  </li>
                </ol>
              </p>
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no3-3.png")}
              ></img>
            </div>
            <div className="tip4 image-left text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no3-4.png")}
              ></img>
              <p className="data">
                <p className="name">
                  Maintain Intimacy and Physical Connection:
                </p>{" "}
                Communicate openly about the impact of IBD on intimacy,{" "}
                <a
                  target="_blank"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/sex-and-relationships"
                >
                  sex and relationships
                </a>
                , and find alternative ways if needed to maintain a fulfilling
                physical connection and affection.
              </p>
            </div>
            <div className="tip5 image-right text-with-img">
              <p className="data">
                <p className="name">Make Decisions Together:</p> Involve both
                partners in decision-making, considering. preferences and needs
                related to treatment and lifestyle adjustments. If relevant to
                your relationship, It's crucial to openly discuss topics like
                family planning such as{" "}
                <a
                  target="_blank"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/reproductive-health-and-fertility"
                >
                  reproductive health
                </a>
                ,{" "}
                <a
                  target="_blank"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/pregnancy-and-breastfeeding"
                >
                  pregnancy and breastfeeding
                </a>{" "}
                and genetic considerations to ensure a comprehensive
                understanding and involvement from both partners.
              </p>
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no3-5.png")}
              ></img>
            </div>
            <div className="tip6 image-left text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no3-6.png")}
              ></img>
              <p className="data">
                <p className="name">Building Resilience</p>
                <ol>
                  <li>
                    <span className="name">
                      Resilience-Building Activities:
                    </span>{" "}
                    Discover activities that can help you both build resilience
                    and cope with the challenges of IBD together.
                  </li>
                  <li>
                    <span className="name">Seeking Support:</span> Encourage
                    each other to seek support outside the relationship. Keep in
                    touch with your friends. Support groups or counselling can
                    provide a valuable outlet for sharing experiences and
                    getting guidance.
                  </li>
                </ol>
              </p>
            </div>
            <p className="no3text4">
              When you are dating someone with IBD, please see some suggestions
              from this video;
            </p>
            <div className="no3vdo youtube-container">
              <YouTubeVideo videoId="3BTLv86lDA4" />
            </div>
            <div className="vdo-detail">
              "TIPS FOR DATING SOMEONE WITH INFLAMMATORY BOWEL DISEASE (IBD) I
              WHAT TO EXPECT I THE GRUMBLING GUT ". YouTube, 15 Dec 2023,{" "}
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=3BTLv86lDA4"
              >
                https://www.youtube.com/watch?v=3BTLv86lDA4
              </a>
            </div>
            <p className=" no3text5 summary description1">
              Living with IBD can be a challenging journey, but by strengthening
              your communication, providing emotional support, and adapting to
              changes together, your relationship can emerge even stronger.
              Embrace the journey with empathy, understanding, and a commitment
              to facing IBD as a team.
            </p>
            <MarkAsRead
              module="building-bonds-that-last"
              submodule="Couple relationship"
            />
          </div>
          <div id="Communication" ref={communicationRef} className="no4">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="building-bonds-that-last"
                  submodule="Communication"
                />
                Communication
              </h3>
              <hr />
            </div>
            <div className="no4text1 quote-box middle-box">
              <p>
                <span className="quote">
                  “We’ve found ourselves in a very balanced relationship and I
                  think a week into our relationship my partner had said
                  something and I said look this relationship, any relationship
                  works on open and honest communication.”{" "}
                </span>
                <span className="name">- Mark, whose partner has IBD -</span>
              </p>
            </div>
            <p className=" no4text2 description1">
              Communication is the cornerstone of successfully navigating the
              challenges posed by IBD, fostering understanding, support, and the
              resilience necessary to maintain healthy family and couple
              relationships.
            </p>
            <h4 className="no4subheading">Communication tips</h4>
            <div className="ten-postit">
              {no4PostIt.map((post) => postIt(post.topic, post.detail))}
            </div>
            <p className="no4text3">
              Discover tips for active listening and empathy skills in these
              recommended videos;
            </p>
            <div className="no4vdo1">
              <div className="no4vdobig youtube-container">
                <YouTubeVideo videoId="7wUCyjiyXdg" />
              </div>
              <div className="vdo-detail">
                "Active Listening Skills". YouTube, 15 Dec 2023,{" "}
                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=7wUCyjiyXdg&t=50s"
                >
                  https://www.youtube.com/watch?v=7wUCyjiyXdg&t=50s
                </a>
              </div>
            </div>
            <div className="no4vdo2">
              <div className="no4vdobig youtube-container">
                <YouTubeVideo videoId="1Evwgu369Jw" />
              </div>
              <div className="vdo-detail">
                "Brene' Brown on Empathy". YouTube, 15 Dec 2023,{" "}
                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=1Evwgu369Jw&t=86s"
                >
                  https://www.youtube.com/watch?v=1Evwgu369Jw&t=86s
                </a>{" "}
                RSA Short: Brene' Brown on Empathy Reproduced with kind
                permission of the RSA: www.theRAS.org
              </div>
            </div>
            <h4 className="no4subheading no4text3 name">
              Here's a family communication exercise you can try:
            </h4>
            <div className="tip1 image-right text-with-img">
              <p className="data">
                <p className="name">Family Appreciation Circle:</p>
                <ul>
                  <li>
                    Sit or stand in a circle as a family and take turns
                    expressing appreciation for one another.
                  </li>
                  <li>
                    Each family member can share something they appreciate or
                    admire about another family member.
                  </li>
                  <li>Encourage specific examples and genuine compliments. </li>
                  <li>
                    The focus is on building positive connections and
                    highlighting each other's strengths.
                  </li>
                </ul>
              </p>
              <div className="no4vdosmall youtube-container">
                <YouTubeVideo videoId="SKa_1WsGoEU" />
                <div className="vdo-detail">
                  "Dr. Ed with Henry Kellem and The Family Gratitude Circle".
                  YouTube, 15 Dec 2023,{" "}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=SKa_1WsGoEU&t=2s"
                  >
                    https://www.youtube.com/watch?v=SKa_1WsGoEU&t=2s
                  </a>
                </div>
              </div>
            </div>
            <div className="tip2 image-left text-with-img">
              <div className="no4vdosmall youtube-container">
                <YouTubeVideo videoId="6_KZYfJ-kKY" />
                <div className="vdo-detail">
                  "Full House - This is the talking stick". YouTube, 15 Dec
                  2023,{" "}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=6_KZYfJ-kKY&t=5s"
                  >
                    https://www.youtube.com/watch?v=6_KZYfJ-kKY&t=5s
                  </a>
                </div>
              </div>
              <p className="data">
                <p className="name">"Talking Stick" or "Talking Ball":</p>
                <ul>
                  <li>
                    Choose a small object like a stick or ball to serve as a
                    talking symbol.
                  </li>
                  <li>
                    Only the person holding the object is allowed to speak,
                    while others listen without interruption.
                  </li>
                  <li>
                    Pass the object around the family, giving each member an
                    opportunity to share their thoughts or feelings on a
                    specific topic or question.
                  </li>
                  <li>
                    This exercise promotes active listening, patience, and
                    respect for each person's turn to speak.
                  </li>
                </ul>
              </p>
            </div>
            <div className="tip3 image-right text-with-img">
              <p className="data">
                <p className="name">Reflective Listening:</p>
                <ul>
                  <li>Divide family members into pairs.</li>
                  <li>
                    Each person takes turns sharing a personal experience,
                    thought, or concern while the other person listens
                    attentively.
                  </li>
                  <li>
                    After the speaker finishes sharing, the listener summarises/
                    clarifies what they heard without adding their own opinions
                    or judgments.
                  </li>
                  <li>
                    The roles are then reversed, and the process continues.
                  </li>
                  <li>
                    This exercise enhances active listening skills and promotes
                    understanding.
                  </li>
                </ul>
              </p>
              <div className="no4vdosmall youtube-container">
                <YouTubeVideo videoId="YBqsav6jCVQ" />
                <div className="vdo-detail">
                  "Reflective Listening". YouTube, 15 Dec 2023,{" "}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=YBqsav6jCVQ&t=15s"
                  >
                    https://www.youtube.com/watch?v=YBqsav6jCVQ&t=15s
                  </a>
                </div>
              </div>
            </div>
            <div className="tip4 image-left text-with-img">
              <div className="no4vdosmall youtube-container">
                <YouTubeVideo videoId="dtgWY0mNN1A" />
                <div className="vdo-detail">
                  "Family Journal". YouTube, 15 Dec 2023,{" "}
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=dtgWY0mNN1A&t=1s"
                  >
                    https://www.youtube.com/watch?v=dtgWY0mNN1A&t=1s
                  </a>
                </div>
              </div>
              <p className="data">
                <p className="name">Family Journaling:</p>
                <ul>
                  <li>
                    Provide each family member with a journal or shared
                    document.
                  </li>
                  <li>
                    Set aside specific times {"(e.g., once a week)"} for family
                    members to write or type their thoughts, feelings, or
                    experiences.
                  </li>
                  <li>
                    Encourage everyone to express themselves freely and honestly
                    in their journal entries.
                  </li>
                  <li>
                    Optionally, designate time to share and discuss the journal
                    entries as a family, promoting further conversation and
                    understanding.
                  </li>
                </ul>
              </p>
            </div>
            <div className="no4summary summary full-box">
              <p>
                The key to these exercises is creating a safe and non-judgmental
                space for open communication. Adapt the exercises to suit the
                needs and dynamics of your family. Consistency and participation
                from all family members will contribute to improving
                communication and fostering stronger connections within the
                family.
              </p>
            </div>
            <MarkAsRead
              module="building-bonds-that-last"
              submodule="Communication"
            />
          </div>
          <div id="Family trips" className="no5">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="building-bonds-that-last"
                  submodule="Family trips"
                />
                Family trips
              </h3>
              <hr />
            </div>
            <div className="no5text1 quote-box middle-box">
              <p>
                <span className="quote">
                  "We were in another country, and my mum had to put in effort
                  to choose restaurants. It had a significant psychological
                  impact in terms of realizing the seriousness of the situation.
                  The fact that it could conceivably ruin a family holiday would
                  probably cause some level of anxiety"{" "}
                </span>
                <span className="name">- Jack, whose mother has IBD -</span>
              </p>
            </div>
            <div className="no5text2">
              <p>
                Having a family member or partner with IBD can significantly
                impact trips and holidays. Dietary restrictions, the need for
                easy access to restrooms, travel-related anxiety, medication and
                treatment considerations, fatigue, and the unpredictability of
                IBD flare-ups can all affect trip planning and execution.
              </p>
              <br />
              <p>
                Planning a family trip when a member has IBD necessitates
                careful consideration to ensure the comfort and safety of all
                participants. Nevertheless, having IBD should not deter families
                from enjoying trips that have the potential to strengthen family
                or couple bonds.
              </p>
            </div>
            <h4 className="no5subheading tip1 ">
              Here are some suggestions for a successful family/couple trip:
            </h4>
            <div className="tip1 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-1.png")}
              ></img>
              <p className="data">
                <p className="name">Choose the Right Destination</p>
                Opt for a destination that offers accessible and reliable
                medical facilities, including hospitals or clinics with IBD
                specialists. Familiarise yourself with local healthcare
                options.You can access this information for various countries on{" "}
                <a
                  target="_blank"
                  href="https://www.ibdpassport.com/ibd-clinics-network"
                >
                  IBD Passport
                </a>
                .
              </p>
            </div>
            <div className="tip2 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-2.png")}
              ></img>
              <p className="data">
                <p className="name">Travel Insurance</p>
                Invest in comprehensive travel insurance that covers medical
                emergencies. Ensure it includes coverage for IBD-related issues,
                like flare-ups or unexpected hospital visits.{" "}
                <a
                  target="_blank"
                  href="https://www.nhs.uk/using-the-nhs/healthcare-abroad/apply-for-a-free-uk-global-health-insurance-card-ghic/"
                >
                  Applying for healthcare cover abroad
                </a>
                .
              </p>
            </div>
            <div className="tip3 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-3.png")}
              ></img>
              <p className="data">
                <p className="name">Travel During Stable Periods</p>
                Plan your trip during periods when the IBD patient is in
                relatively stable health. Avoid traveling during flare-ups if
                possible.
              </p>
            </div>
            <div className="tip4 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-4.png")}
              ></img>
              <p className="data">
                <p className="name">Consult a Healthcare Professional</p>
                Speak to your healthcare provider before the trip to discuss any
                adjustments to medications, dietary restrictions, or other
                concerns related to travel.
              </p>
            </div>
            <div className="tip5 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-5.png")}
              ></img>
              <p className="data">
                <p className="name">Pack Medications and Supplies</p>
                Bring an ample supply of medications, ostomy supplies (if
                needed), and any dietary items that help manage IBD symptoms.
                Carry these items in your carry-on bag.
              </p>
            </div>
            <div className="tip6 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-6.png")}
              ></img>
              <p className="data">
                <p className="name">Stay Hydrated</p>
                Encourage everyone to stay well-hydrated, particularly the IBD
                patient. Dehydration can worsen symptoms, so carry enough water
                or oral rehydration solutions.
              </p>
            </div>
            <div className="tip7 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-7.png")}
              ></img>
              <p className="data">
                <p className="name">Plan Rest Stops</p>
                If you're traveling by car, plan frequent rest stops to allow
                the IBD patient to use the restroom and take breaks.
              </p>
            </div>
            <div className="tip8 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-8.png")}
              ></img>
              <p className="data">
                <p className="name">Choose IBD-Friendly Foods</p>
                Look for restaurants or grocery stores that offer{" "}
                <a
                  target="_blank"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
                >
                  IBD-friendly food options
                </a>
                . Research restaurants with menus that accommodate dietary
                restrictions.
              </p>
            </div>
            <div className="tip9 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-9.png")}
              ></img>
              <p className="data">
                <p className="name">Consider Accommodations</p>
                Choose accommodations that are IBD-friendly, such as hotels or
                vacation rentals with private bathrooms, easy access to
                restrooms, and comfortable bedding.
              </p>
            </div>
            <div className="tip10 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-10.png")}
              ></img>
              <p className="data">
                <p className="name">
                  Carry a Medical Alert Card and Can't Wait Card
                </p>
                Prepare a medical alert card with essential information about
                the IBD patient, including the diagnosis, medications, and
                emergency contacts. Keep a digital copy as well. Don't forget a
                Crohn’s & Colitis UK{" "}
                <a
                  target="_blank"
                  href="https://crohnsandcolitis.org.uk/info-support/become-a-member/cant-wait-card"
                >
                  Can’t Wait Card
                </a>
                . This is available in 29 different languages.
              </p>
            </div>
            <div className="tip11 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-11.png")}
              ></img>
              <p className="data">
                <p className="name">Create a Travel Itinerary</p>
                Share your travel itinerary, including planned rest stops and
                bathroom breaks, with family members. This helps manage
                expectations and ensures everyone is on the same page.
              </p>
            </div>
            <div className="tip12 text-with-img">
              <img
                alt=""
                src={require("../assets/images/buildingbonds/no5-12.png")}
              ></img>
              <p className="data">
                <p className="name">Create an Emergency Plan</p>
                Discuss what to do in case of a severe symptom flare-up or
                unexpected complications.
              </p>
            </div>
            <div className="no5text3 full-box">
              <p>
                Remember that with careful planning and consideration, family
                trips are very possible with IBD. The key is to ensure the IBD
                patient's comfort and safety while allowing everyone to enjoy
                quality time together.
              </p>
            </div>
            <p className="no5summary summary description1">
              You might find more information on{" "}
              <a
                target="_blank"
                href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/travelling-with-crohns-or-colitis"
              >
                travelling with Crohn’s or Colitis
              </a>{" "}
              by visiting the Crohn's & Colitis UK website.
            </p>
            <p className="vdo-name name">
              IBD doesn't define them. See how two families navigate laughter,
              love, and hope in "Navigating Family Bonds"
            </p>
            <div className="youtube-container">
              <YouTubeVideo videoId="7rxmQUOmke0" />
            </div>
            <MarkAsRead
              module="building-bonds-that-last"
              submodule="Family trips"
            />
          </div>
          <div id="Learning points" className="no6">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="building-bonds-that-last"
                  submodule="Learning points"
                />
                Learning points
              </h3>
              <hr />
            </div>
            <div className="no6text1 full-box">
              <p>
                <p className="name">Building a strong family unit:</p>
                <ul>
                  <li>
                    Foster a collaborative support system through shared
                    learning and a strong network of support.
                  </li>
                  <li>
                    Embrace open and inclusive communication where everyone
                    feels heard and understood.
                  </li>
                  <li>
                    Balance adaptable support for the person with IBD with
                    shared activities and respect for individual recharge time.
                  </li>
                </ul>
              </p>
            </div>
            <div className="no6text2">
              <p>
                We're warriors facing IBD together. But even warriors need to
                rest. So let's focus on self-care. It's not selfish, it's the
                fuel that empowers us to better support each other. Join us as
                we explore self-care and resilience in the next chapter, not
                just for ourselves, but for our stronger, united front.
              </p>
            </div>
            <MarkAsRead
              module="building-bonds-that-last"
              submodule="Learning points"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildingBonds;
