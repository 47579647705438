import * as api from "../api/api";

const Pdf = ({name, filePath, logAction}) => {
  const handleOnClick = () => {
    if (logAction) {
      api.sendTimeLog({action: logAction});
    }
    window.open(filePath, "_blank");
  };

  return (
    <div className="pdf">
      <img
        className="clickable"
        src={require("../assets/images/empowering/pdf-icon.png")}
        onClick={handleOnClick}
        alt=""
      ></img>
      <span className="name">{name}</span>
      <button className="clickable" type="button" onClick={handleOnClick}>
        Download <i className="fa fa-download"></i>
      </button>
    </div>
  );
};

export default Pdf;