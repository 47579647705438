import React, {useState} from "react";
import axios from "axios";
import Config from "../assets/configs/configs.json";

const ContactUs = ({userLogin}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const contactAdmin = async () => {
    if (!!userLogin && !!userLogin.isLogin) {
      try {
        const res = await axios.post(
          `${Config.BACKEND_URL}/user/contact-us`,
          {
            email,
            message,
          },
          {
            headers: {
              username: localStorage.getItem("username"),
            },
            withCredentials: true,
          }
        );
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    } else {
      window.location.assign("/signin");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await contactAdmin();
    alert(`Thank you for contacting us. We'll be in touch soon.`);
    setEmail("");
    setMessage("");
  };

  return (
    <div id="contactus" className="contact-section">
      <div className="topic-section">
        <h2>Contact Us</h2>
        <hr />
      </div>
      <div className="content-contact">
        <p>
          If you need to contact us regarding FamilyBridge for IBD or if you
          encounter any difficulties accessing any of the materials, please use
          the form below.
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">E-mail to contact *</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div>
            <label htmlFor="message">Message *</label>
            <textarea
              id="message"
              value={message}
              onChange={handleMessageChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
