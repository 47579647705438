import React, {useEffect, useState} from "react";
import YouTubeVideo from "./YoutubeRenderer";
import {motion} from "framer-motion";
import {TypingComponent} from "../hooks/useTypingEffect";
import {protectedPage} from "../utils/auth";
import MarkAsRead from "./MarkAsRead";
import SaveSubmodule from "./SaveSubmodule";

const UnderstandingIBD = ({isMenuVisible, userLogin, setUserLogin}) => {
  const [hoveredPart, setHoveredPart] = useState("");
  const [hoveredBowelPart, setHoveredBowelPart] = useState("");
  const [hoveredIBDPart, setHoveredIBDPart] = useState("");

  const anatomyImageList = {
    Anatomy: require("../assets/images/anatomy/body.png"),
    mouth: require("../assets/images/anatomy/mouth.png"),
    esopha: require("../assets/images/anatomy/esopha.png"),
    liver: require("../assets/images/anatomy/liver.png"),
    stomach: require("../assets/images/anatomy/stomach.png"),
    "large-intestine": require("../assets/images/anatomy/large-intestine.png"),
    "small-intestine": require("../assets/images/anatomy/small-intestine.png"),
    anus: require("../assets/images/anatomy/anus.png"),
    "anus-tip": require("../assets/images/anatomy/anus-tip.png"),
  };
  const anatomyNameList = {
    mouth: "Mouth",
    esopha: "Esophagus",
    liver: "Liver",
    stomach: "Stomach",
    "large-intestine": "Large intestine (Colon)",
    "small-intestine": "Small intestine",
    anus: "Rectum",
    "anus-tip": "Anus",
  };

  const itemVariants = {
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {delay: i * 0.2},
    }),
    hidden: {opacity: 0, x: -100},
  };

  // useEffect(() => {
  //   protectedPage(userLogin, setUserLogin);
  // }, []);

  return (
    <div className="understanding-container">
      <div className="understanding-banner">
        <img
          src={require("../assets/images/understanding/banner.jpg")}
          alt="blog-banner"
        />
        <div className="text">
          <h1>Understanding IBD and its impact</h1>
        </div>
      </div>
      <div className="understanding-ibd">
        <h2 className="padding-for-informative-less">
          What do I need to know about Crohn's and Colitis?
        </h2>
        <div className="article-content-container">
          <div
            id="About Crohn's and Colitis"
            className="no1 padding-for-informative"
          >
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="About Crohn's and Colitis"
                />
                About Crohn's and Colitis
              </h3>
              <hr />
            </div>
            <div className="no1text">
              <p>
                Crohn's Disease and Ulcerative Colitis are part of a group of
                illnesses called inflammatory bowel diseases, or IBD.
                <br />
                <br />
                IBD, is a lifelong condition. This means that once someone has
                it, they'll have it for the rest of their life. This doesn't
                mean they'll always feel sick, though. Luckily, there are ways
                to manage it and make life still enjoyable. Treatments like
                medicines, surgery, and changes in what they eat can help them
                feel much better.
              </p>
              <br />
              <p>
                Interested in learning more about Crohn's and Colitis? Check out
                this informative video on YouTube:{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://youtu.be/vuytsHjQvqc?si=arAaxKiU8fVYulVn"
                >
                  What are Crohn's and Colitis
                </a>
              </p>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="About Crohn's and Colitis"
            />
          </div>
          <div id="The Gut" className="no2 padding-for-informative">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="The Gut"
                />
                The Gut
              </h3>
              <hr />
            </div>
            <div className="no2text">
              <p>
                To understand Crohn's and Colitis better, it's helpful to know
                that the gut, also called the digestive system, is responsible
                for processing food and nutrients and eliminating waste. It
                starts at the mouth when you eat and ends at the anus where
                waste exits the body.
              </p>
            </div>
            <div className="instruct">
              <p>
                Try hovering/tapping (computer/mobile) over the diagram to view
                more.
              </p>
            </div>
            <div className="anatomy">
              <div className="anatomy-container">
                <img
                  src={anatomyImageList["Anatomy"]}
                  alt="Anatomy"
                  className="body-image"
                />
                <img
                  onMouseEnter={() => setHoveredPart("esopha")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["esopha"]}
                  alt="esopha"
                  className={`esopha-image ${
                    !!hoveredPart && hoveredPart !== "esopha" ? "fade-out" : ""
                  }`}
                />
                <img
                  onMouseEnter={() => setHoveredPart("mouth")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["mouth"]}
                  alt="mouth"
                  className={`mouth-image ${
                    !!hoveredPart && hoveredPart !== "mouth" ? "fade-out" : ""
                  }`}
                />
                <img
                  onMouseEnter={() => setHoveredPart("liver")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["liver"]}
                  alt="liver"
                  className={`liver-image ${
                    !!hoveredPart && hoveredPart !== "liver" ? "fade-out" : ""
                  }`}
                />
                <img
                  onMouseEnter={() => setHoveredPart("stomach")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["stomach"]}
                  alt="stomach"
                  className={`stomach-image ${
                    !!hoveredPart && hoveredPart !== "stomach" ? "fade-out" : ""
                  }`}
                />
                <img
                  onMouseEnter={() => setHoveredPart("large-intestine")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["large-intestine"]}
                  alt="large-intestine"
                  className={`large-intestine-image ${
                    !!hoveredPart && hoveredPart !== "large-intestine"
                      ? "fade-out"
                      : ""
                  }`}
                />
                <img
                  onMouseEnter={() => setHoveredPart("small-intestine")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["small-intestine"]}
                  alt="small-intestine"
                  className={`small-intestine-image ${
                    !!hoveredPart && hoveredPart !== "small-intestine"
                      ? "fade-out"
                      : ""
                  }`}
                />
                <img
                  onMouseEnter={() => setHoveredPart("anus")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["anus"]}
                  alt="anus"
                  className={`anus-image ${
                    !!hoveredPart && hoveredPart !== "anus" ? "fade-out" : ""
                  }`}
                />
                <img
                  onMouseEnter={() => setHoveredPart("anus-tip")}
                  onMouseLeave={() => setHoveredPart("")}
                  src={anatomyImageList["anus-tip"]}
                  alt="anus-tip"
                  className={`anus-tip-image ${
                    !!hoveredPart && hoveredPart !== "anus-tip"
                      ? "fade-out"
                      : ""
                  }`}
                />
              </div>
              <div
                style={{display: !!hoveredPart ? "" : "none"}}
                className="description-anatomy"
              >
                <div>
                  <p>{anatomyNameList[hoveredPart]}</p>
                  <img src={anatomyImageList[hoveredPart]} alt={hoveredPart} />
                </div>
              </div>
            </div>
            <div className="bottom-n2-text">
              <h3 className="title-p">The intestines (bowel)</h3>
              <p>
                Your intestines are like a curvy pathway that starts after the
                stomach. It's part of digestive system, which is like the body's
                food processor. <br /> <br /> The intestines (bowel) come in two
                parts: the small intestine and the large intestine.
                <ul>
                  <li>
                    The small intestine is where most of the important nutrients
                    are absorbed into body. It's really long but thin.
                  </li>
                  <li>
                    The large intestine is shorter and wider. In the large
                    intestine the body takes what's left after taking out the
                    good stuff and forms it into stool.
                  </li>
                </ul>
              </p>
            </div>
            <div className="instruct">
              <p>
                Try hovering/tapping (computer/mobile) over the diagram to view
                more.
              </p>
            </div>
            <div className="bottom-n2-image">
              <div>
                <img
                  style={{zIndex: !!isMenuVisible ? 1 : 1000}}
                  onMouseEnter={() => setHoveredBowelPart("healthy-bowel")}
                  onMouseLeave={() => setHoveredBowelPart("")}
                  className="bowel"
                  src={require("../assets/images/anatomy/healthy-bowel.png")}
                  alt="healthy-bowel"
                />
                <img
                  style={{zIndex: !!isMenuVisible ? 2 : 1002}}
                  onMouseEnter={() => setHoveredBowelPart("healthy-bowel")}
                  onMouseLeave={() => setHoveredBowelPart("")}
                  className={`subbowel ${
                    !!hoveredBowelPart && hoveredBowelPart === "healthy-bowel"
                      ? "show-subbowel"
                      : ""
                  }`}
                  src={require("../assets/images/anatomy/healthy-sub.png")}
                  alt="healthy-sub"
                />
                <p className="under-img-txt">Healthy bowel</p>
                <p
                  className={`on-not-show`}
                  style={{zIndex: !!isMenuVisible ? 1 : 1001}}
                ></p>
              </div>
              <div>
                <img
                  style={{zIndex: !!isMenuVisible ? 1 : 1000}}
                  onMouseEnter={() => setHoveredBowelPart("coltisonoe-sub")}
                  onMouseLeave={() => setHoveredBowelPart("")}
                  className="bowel"
                  src={require("../assets/images/anatomy/coltisone.png")}
                  alt="coltisone"
                />
                <img
                  style={{zIndex: !!isMenuVisible ? 2 : 1002}}
                  onMouseEnter={() => setHoveredBowelPart("coltisonoe-sub")}
                  onMouseLeave={() => setHoveredBowelPart("")}
                  className={`subbowel ${
                    !!hoveredBowelPart && hoveredBowelPart === "coltisonoe-sub"
                      ? "show-subbowel"
                      : ""
                  }`}
                  src={require("../assets/images/anatomy/coltisonoe-sub.png")}
                  alt="coltisonoe-sub"
                />
                <p className="under-img-txt">Ulcerative Colitis</p>
                <p
                  className={`on-not-show ${
                    !!hoveredBowelPart && hoveredBowelPart === "coltisonoe-sub"
                      ? "bottom-under-img-txt"
                      : ""
                  }`}
                  style={{zIndex: !!isMenuVisible ? 1 : 999}}
                >
                  UC affects only the <br />
                  large intestine (bowel)
                </p>
              </div>
              <div>
                <img
                  style={{zIndex: !!isMenuVisible ? 1 : 1000}}
                  onMouseEnter={() => setHoveredBowelPart("cdone-sub")}
                  onMouseLeave={() => setHoveredBowelPart("")}
                  className="bowel"
                  src={require("../assets/images/anatomy/cdone.png")}
                  alt="cdone"
                />
                <img
                  onMouseEnter={() => setHoveredBowelPart("cdone-sub")}
                  onMouseLeave={() => setHoveredBowelPart("")}
                  className={`subbowel ${
                    !!hoveredBowelPart && hoveredBowelPart === "cdone-sub"
                      ? "show-subbowel"
                      : ""
                  }`}
                  style={{zIndex: !!isMenuVisible ? 2 : 1002}}
                  src={require("../assets/images/anatomy/cdone-sub.png")}
                  alt="cdone-sub"
                />
                <p className="under-img-txt">Crohn's disease</p>
                <p
                  className={`on-not-show ${
                    !!hoveredBowelPart && hoveredBowelPart === "cdone-sub"
                      ? "bottom-under-img-txt"
                      : ""
                  }`}
                  style={{zIndex: !!isMenuVisible ? 1 : 999}}
                >
                  UC can affect any <br />
                  part of the gut
                </p>
              </div>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="The Gut"
            />
          </div>
          <div id="Common Symptoms" className="no3 padding-for-informative">
            <div className="title">
              <h3 className="title-p">
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="Common Symptoms"
                />
                Common Symptoms
              </h3>
              <hr />
            </div>
            <div className="contentno3">
              <p className="upperno3">
                Symptoms of IBD can vary from person to person, and not everyone
                experiences all of them. The severity of symptoms can differ
                among individuals.
              </p>
              <div className="no3imagewrapper">
                <div>
                  <img
                    src={require("../assets/images/abdominal-pain.png")}
                    alt="abdominal-pain"
                  />
                  <p className="under-img-txt">Abdominal pain</p>
                </div>
                <div>
                  <img
                    src={require("../assets/images/fatigue.png")}
                    alt="fatigue"
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="under-img-txt"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/fatigue"
                  >
                    <p>Fatigue </p>
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </div>
                <div>
                  <img
                    src={require("../assets/images/diarrhea.png")}
                    alt="diarrhea"
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="under-img-txt"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/diarrhoea"
                  >
                    <p>Diarrhoea </p>
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  </a>
                </div>
                <div>
                  <img
                    src={require("../assets/images/weightloss.png")}
                    alt="weightloss"
                  />
                  <p className="under-img-txt">Losing weight</p>
                </div>
                <div>
                  <img
                    src={require("../assets/images/bloody-stool.png")}
                    alt="bloody-stool"
                  />
                  <p className="under-img-txt">Bloody stool</p>
                </div>
              </div>
              <p className="pre-end-no3">
                IBD can also impact areas beyond the digestive tract, leading to
                various manifestations such as mouth ulcers,{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/joints"
                >
                  joint pain
                </a>
                , and complications related to the eyes, skin,{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/bones"
                >
                  bones
                </a>
                , or liver. <br />
                <br />
                You might find more information on{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://ccuk-main.wpnc-uat.site/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms?parent=23151&page=1&tags=&category=23151&sort=newest"
                >
                  common symptoms
                </a>{" "}
                of IBD by visiting the Crohn's & Colitis UK website.
              </p>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="Common Symptoms"
            />
          </div>
          <div id="What is a Flare-Up?" className="no4">
            <div className="title padding-for-informative">
              <h3 className="title-p">
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="About Crohn's and Colitis"
                />
                What is a Flare-Up?
              </h3>
              <hr />
            </div>
            <div className="no4-content ">
              <p className="no4-description1 padding-for-informative">
                A{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/flare-ups"
                >
                  flare-up
                </a>{" "}
                happens when symptoms return, and a person start feeling unwell.
                It's unique to each person, and the symptoms can change from
                person to person and can also depend on where Crohn's or Colitis
                is in the digestive system.
              </p>
              <p className="no4-description2 margin-for-informative-less">
                ​If your relative's symptoms are sudden or severe and you think
                they might need urgent medical help, call{" "}
                <span style={{fontWeight: 600}}>111</span> for immediate medical
                attention. If you believe it's a life-threatening emergency,
                call <span style={{fontWeight: 600}}>999</span> immediately.
              </p>
              <div className="no4-image padding-for-informative">
                <h3>Some signs could include:</h3>
                <div className="no4-image-wrapper">
                  <div>
                    <img
                      src={require("../assets/images/severe-diarrha.png")}
                      alt=""
                    />
                    <p className="under-img-txt">
                      Large amounts of diarrhoea leading to dehydration
                    </p>
                  </div>
                  <div>
                    <img
                      src={require("../assets/images/severe-abdominal.png")}
                      alt=""
                    />
                    <p className="under-img-txt">Severe abdominal pain</p>
                  </div>
                  <div>
                    <img
                      src={require("../assets/images/severe-fever.png")}
                      alt=""
                    />
                    <p className="under-img-txt">Fever</p>
                  </div>
                  <div>
                    <img
                      src={require("../assets/images/vomitting.png")}
                      alt=""
                    />
                    <p className="under-img-txt">
                      Severe vomiting and malnourishment
                    </p>
                  </div>
                  <div>
                    <img
                      src={require("../assets/images/heavy-blood.png")}
                      alt=""
                    />
                    <p className="under-img-txt">Heavy rectal bleeding</p>
                  </div>
                </div>
                <p>
                  These are just a few examples of a possible emergency
                  condition. There are also other serious situations that need
                  quick medical help. <br />
                  You might find more information on{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/symptoms/flare-ups"
                  >
                    flare-up
                  </a>{" "}
                  by visiting the Crohn's & Colitis UK website.
                </p>
              </div>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="What is a Flare-Up?"
            />
          </div>
          <div
            id="How do People Get IBD?"
            className="no5 padding-for-informative"
          >
            <div className="title">
              <h3>
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="How do People Get IBD?"
                />
                How do People Get IBD?
              </h3>
              <hr />
            </div>
            <div className="no5content">
              <p>
                The exact causes of Crohn's disease (CD) and Ulcerative colitis
                (UC) are not fully understood. <br />
                Scientists believe it may involve a combination of four factors:
              </p>
              <div className="no5contentwrapper">
                <div className="no5imagecontent">
                  <img src={require("../assets/images/diagram.png")} alt="" />
                  <div
                    className={`topic1 ${
                      !!hoveredIBDPart && hoveredIBDPart === "1"
                        ? "up-scale"
                        : ""
                    }`}
                  >
                    <p>1</p>
                  </div>
                  <div
                    className={`topic2 ${
                      !!hoveredIBDPart && hoveredIBDPart === "2"
                        ? "up-scale"
                        : ""
                    }`}
                  >
                    <p>2</p>
                  </div>
                  <div
                    className={`topic3 ${
                      !!hoveredIBDPart && hoveredIBDPart === "3"
                        ? "up-scale"
                        : ""
                    }`}
                  >
                    <p>3</p>
                  </div>
                  <div
                    className={`topic4 ${
                      !!hoveredIBDPart && hoveredIBDPart === "4"
                        ? "up-scale"
                        : ""
                    }`}
                  >
                    <p>4</p>
                  </div>
                </div>
                <div className="no5textcontent">
                  <p
                    style={{zIndex: !!isMenuVisible ? 1 : 1000}}
                    onMouseEnter={() => setHoveredIBDPart("1")}
                    onMouseLeave={() => setHoveredIBDPart("")}
                  >
                    <span>1.</span> The balance of intestine bacteria
                  </p>
                  <p
                    style={{zIndex: !!isMenuVisible ? 1 : 1000}}
                    onMouseEnter={() => setHoveredIBDPart("2")}
                    onMouseLeave={() => setHoveredIBDPart("")}
                  >
                    <span>2.</span> Genetic component
                  </p>
                  <p
                    style={{zIndex: !!isMenuVisible ? 1 : 1000}}
                    onMouseEnter={() => setHoveredIBDPart("3")}
                    onMouseLeave={() => setHoveredIBDPart("")}
                  >
                    <span>3.</span> The immune response
                  </p>
                  <p
                    style={{zIndex: !!isMenuVisible ? 1 : 1000}}
                    onMouseEnter={() => setHoveredIBDPart("4")}
                    onMouseLeave={() => setHoveredIBDPart("")}
                  >
                    <span>4.</span> Environmental triggers
                  </p>
                </div>
              </div>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="How do People Get IBD?"
            />
          </div>
          <div
            id="How to Get a Diagnosis?"
            className="no6 padding-for-informative"
          >
            <div className="title">
              <h3>
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="How to Get a Diagnosis?"
                />
                How to Get a Diagnosis?
              </h3>
              <hr />
            </div>
            <div className="no6content">
              <p className="topper">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/understanding-crohns-and-colitis/how-to-get-a-diagnosis"
                >
                  Tests
                </a>{" "}
                for Crohn's and Colitis help the health care team in both
                diagnosing and keeping track of the disease, enabling them to
                determine the most effective treatment.
              </p>
              <div>
                <img src={require("../assets/images/diagnotic.png")} alt="" />
              </div>
              <p className="footer">
                You might find more information on{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/understanding-crohns-and-colitis/how-to-get-a-diagnosis"
                >
                  tests
                </a>{" "}
                for IBD by visiting the Crohn's & Colitis UK website.
              </p>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="How to Get a Diagnosis?"
            />
          </div>
          <div id="How to Treat IBD?" className="no7 padding-for-informative">
            <div className="title">
              <h3>
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="How to Treat IBD?"
                />
                How to Treat IBD?
              </h3>
              <hr />
            </div>
            <div className="no7content">
              <p className="no7textcontent">
                While there are no known cures for IBD at the moment, there are
                effective treatment choices for managing it. <br />
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/understanding-crohns-and-colitis/treatments"
                >
                  Treatment
                </a>{" "}
                of IBD, varies depending on the severity of symptoms and the
                extent of gut involvement. These may include{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/treatments?parent=23127&page=1&tags=&category=23127&sort=newest"
                >
                  Medication
                </a>
                ,{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/living-with-crohns-or-colitis/food"
                >
                  food modifications
                </a>
                , and, in some cases,{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/surgery-and-complications"
                >
                  surgery
                </a>
                .
              </p>
              <div className="no7maincontent">
                <div className="no7doctor">
                  <img src={require("../assets/images/Doctor.png")} alt="" />
                  <div className="bubble-container">
                    <div className="bubble">
                      <TypingComponent
                        text="Each case of IBD is unique, there is no one-size-fits-all solution, and treatment approaches will vary for each individual."
                        speed={25}
                      />
                    </div>
                  </div>
                </div>
                <div className="no7goal">
                  <h3>Treatment goals</h3>
                  <div>
                    {[
                      "Achieve remission",
                      "Control inflammation",
                      "Prevent flare-ups",
                      "Prevent and manage complications",
                      "Maximise quality of life",
                    ].map((item, index) => (
                      <motion.li
                        key={index}
                        variants={itemVariants}
                        initial="hidden"
                        whileInView="visible"
                        custom={index}
                        className="textgoal"
                        viewport={{once: true, amount: 0.5}}
                      >
                        {item}
                      </motion.li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="no7bottomtextcontent">
                <p>
                  You might find more information on{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis/all-information-about-crohns-and-colitis/understanding-crohns-and-colitis/treatments"
                  >
                    treatments
                  </a>{" "}
                  for IBD by visiting the Crohn's & Colitis UK website.
                </p>
              </div>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="How to Treat IBD?"
            />
          </div>
          <div
            id="Our Life is a Roller Coaster!"
            className="no8 padding-for-informative"
          >
            <div className="title">
              <h3>
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="Our Life is a Roller Coaster!"
                />
                Our Life is a Roller Coaster!
              </h3>
              <hr />
            </div>
            <div className="no8texttop">
              <p style={{fontStyle: "italic"}}>
                “It was a rollercoaster, it was up, down, up, down. She’d be OK
                and then she wouldn’t be OK” -{" "}
                <span style={{fontWeight: 700}}>
                  Amelia, whose sister has IBD -
                </span>
              </p>
            </div>
            <div className="no8texttop2">
              <p>
                Living with IBD doesn't just affect the individual – it touches
                every aspect of family life. The unpredictable nature of the
                person's IBD threw the family's emotions into a constant
                rollercoaster. From emotional well-being to physical health,
                relationships, social activities, finances, and even leisure
                time and travel plans, the impact is far-reaching. But amid the
                challenges, families discover remarkable strength and
                resilience. On our website, you'll find practical advice,
                inspiring stories, and a supportive community to navigate this
                journey together. 
              </p>
            </div>
            <div className="here-directly">
              <p>
                Hear from an individual with IBD about her journey and the
                impact of IBD on her family
              </p>
              <div className="vid-container">
                <div className="youtube-container">
                  <YouTubeVideo videoId="cAejhm6g4No" />
                </div>
              </div>
            </div>
            <div className="here-directly">
              <p>
                Hear directly from family members of individuals living with IBD
                as they share the profound impact of IBD on thier families.
              </p>
              <div className="vid-container">
                <div className="youtube-container">
                  <YouTubeVideo videoId="euUDbZl3QN8" />
                </div>
              </div>
            </div>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="Our Life is a Roller Coaster!"
            />
          </div>
          <div id="Learning Points" className="no9 padding-for-informative">
            <div className="title">
              <h3>
                <SaveSubmodule
                  module="understanding-ibd-and-its-impact"
                  submodule="Learning Points"
                />
                Learning Points
              </h3>
              <hr />
            </div>
            <div className="no8box1 full-box">
              <p>
                <p className="name">
                  Key support strategies for your loved one with IBD
                </p>
                <ol>
                  <li>
                    <p className="name">Chronic illness:</p>
                    IBD is a chronic inflammation of the digestive tract.
                  </li>
                  <li>
                    <p className="name">Common Symptom:</p>
                    Symptoms include abdominal pain, diarrhoea, fatigue, and
                    rectal bleeding, varying in severity and causing
                    unpredictable flares and remissions.
                  </li>
                  <li>
                    <p className="name">Causes:</p>
                    The exact cause is unclear, but factors include genetics,
                    immune response, environment, and gut health may contribute.
                  </li>
                  <li>
                    <p className="name">Diagnosis:</p>
                    Diagnosis involves blood tests, stool tests, imaging, and
                    sometimes endoscopy.
                  </li>
                  <li>
                    <p className="name">Treatment Options:</p>
                    While there's no cure, treatments focus on inflammation
                    control and symptom management. Options include medications,
                    dietary changes, and surgery if necessary.
                  </li>
                  <li>
                    <p className="name">Impact on Daily Life:</p>
                    IBD significantly affects daily life, impacting physical and
                    mental health, social interactions, and overall well-being
                    for individuals and their families.
                  </li>
                </ol>
              </p>
            </div>
            <p className="no9-bottom">
              <TypingComponent
                text="As we come to the end of our exploration on 'Understanding IBD and
            Its Impact,' let's turn our focus to something incredibly close to
            our hearts: family. In the next chapter of our journey, we're going
            to delve into how we can support our loved ones facing IBD, build
            even stronger family connections, and prioritise self-care as we
            navigate this journey together. Think of these upcoming modules as a
            warm embrace, guiding you not just in understanding IBD, but also in
            approaching it as a united family, hand in hand."
                speed={25}
              />
            </p>
            <MarkAsRead
              module="understanding-ibd-and-its-impact"
              submodule="Learning Points"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderstandingIBD;
