import {useEffect} from "react";
import * as api from "../api/api";

const TrackedElement = ({logAction, element}) => {
  useEffect(() => {
    api.sendTimeLog({action: logAction});
  }, [logAction]);

  return element;
};

export default TrackedElement;
