import React, {useEffect, useState, useRef} from "react";
import {motion} from "framer-motion";
import {Link as ScrollLink} from "react-scroll";

const FloatingButton = ({path, module}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [allowToggle, setAllowToggle] = useState(true);
  const [isShow, setShow] = useState(isMenuVisible);
  const menuRef = useRef();
  const toggleTimeoutRef = useRef();

  useEffect(() => {
    const handleClickOutside = async (event) => {
      if (!isMenuVisible) return;
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        isMenuVisible
      ) {
        setAllowToggle(false);
        setIsMenuVisible(false);
        if (toggleTimeoutRef.current) {
          clearTimeout(toggleTimeoutRef.current);
        }
        toggleTimeoutRef.current = setTimeout(() => {
          if (isShow) {
            setShow(false);
          }
          setAllowToggle(true);
        }, 500);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const toggleMenu = () => {
    if (!allowToggle) return;
    setAllowToggle(false);
    setIsMenuVisible((prev) => !prev);
    if (toggleTimeoutRef.current) {
      clearTimeout(toggleTimeoutRef.current);
    }
    if (!isShow) {
      setShow(true);
    }
    toggleTimeoutRef.current = setTimeout(() => {
      if (isShow) {
        setShow(false);
      }
      setAllowToggle(true);
    }, 500);
  };
  const menuItems = {
    "understanding-ibd-and-its-impact": [
      "About Crohn's and Colitis",
      "The Gut",
      "Common Symptoms",
      "What is a Flare-Up?",
      "How do People Get IBD?",
      "How to Get a Diagnosis?",
      "How to Treat IBD?",
      "Our Life is a Roller Coaster!",
      "Learning Points",
    ],
    "empowering-individual-with-ibd": [
      "Fatigue",
      "Pain",
      "Medications",
      "Diet",
      "Toilet access",
      "Emotional support",
      "Seeking support for family members",
      "Learning points & Tools",
    ],
    "building-bonds-that-last": [
      "Family relationship",
      "Parent and non-IBD children",
      "Couple relationship",
      "Communication",
      "Family trips",
      "Learning points",
    ],
    "unlocking-inner-resilience": [
      "Self-Care",
      "Self-Compassion",
      "Goal Setting",
      "Stress Management",
      "Emotional Management",
      "Mindfulness",
      "Time to Exercise",
      "Accepting and Asking for Help",
      "Learning Points & Tools",
    ],
  };
  const menuContainerVariants = {
    hidden: {opacity: 0},
    visible: {opacity: 1},
  };

  const menuItemVariants = {
    hidden: {y: 20, opacity: 0},
    visible: {y: 0, opacity: 1},
  };

  const buttonVariants = {
    hover: {scale: 1.1, rotate: 360},
    tap: {scale: 0.95},
  };

  useEffect(() => {
    return () => {
      if (toggleTimeoutRef.current) {
        clearTimeout(toggleTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="floating-button-container" ref={menuRef}>
      <motion.div
        initial="hidden"
        animate={isMenuVisible ? "visible" : "hidden"}
        variants={menuContainerVariants}
        transition={{staggerChildren: 0.01}}
        style={{
          pointerEvents: isMenuVisible ? "auto" : "none",
          display: isShow ? "flex" : "none",
        }}
        className="menu"
      >
        {menuItems[path].map((item, index) => (
          <ScrollLink to={item}>
            <motion.div
              key={index}
              variants={menuItemVariants}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 20,
              }}
              className={module?.[item] ? "menu-item completed" : "menu-item"}
            >
              {item}
            </motion.div>
          </ScrollLink>
        ))}
      </motion.div>
      <motion.button
        whileHover="hover"
        whileTap="tap"
        onClick={toggleMenu}
        className="floating-button"
        variants={buttonVariants}
        transition={{duration: 0.5}}
      >
        +
      </motion.button>
    </div>
  );
};

export default FloatingButton;
