import React, {useState, useEffect} from "react";
import axios from "axios";
import Config from "../assets/configs/configs.json";
import {useForm} from "react-hook-form";
import {WholeScreenLoader} from "../components/LoadingComponent";
import * as api from "../api/api";
import {LOG} from "../constant/timeLog";

const Signin = ({signin, signup}) => {
  const [resError, setResError] = useState("");
  const [linkGoogle, setGoogleUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({defaultValues: {role: "user"}, mode: "all"});

  const handleSignUp = async (event) => {
    const {username, password, role} = event;
    const data = {user: {username: username, password, role}};

    try {
      await axios.post(`${Config.BACKEND_URL}/user`, data, {
        withCredentials: true,
      });
      api.sendTimeLog({action: LOG.LOGIN});
      window.location.assign("/");
    } catch (error) {
      console.error(error);
      handleShowResError(error.response.data.error);
    }
  };

  const handleSignIn = async (event) => {
    setLoading(true);
    const {username, password} = event;
    const data = {user: {username, password}};
    try {
      const res = await axios.post(`${Config.BACKEND_URL}/user/login`, data, {
        withCredentials: true,
      });
      const user_id = res.headers.user_id;
      const username = res.headers.username;
      const session_id = res.headers.session_id;
      const role = res.headers.role;
      await localStorage.setItem("user_id", user_id);
      await localStorage.setItem("username", username);
      await localStorage.setItem("session_id", session_id);
      if (!!role && role === "intervention") {
        await localStorage.setItem("is_tracked", true);
        await api.sendTimeLog({action: LOG.LOGIN});
      }
      setLoading(false);
      window.location.assign("/");
    } catch (error) {
      setLoading(false);
      console.error(error);
      handleShowResError(
        error?.response?.data?.error
          ? error.response.data.error
          : "username or password is invalid"
      );
    }
    setLoading(false);
  };

  const handleShowResError = (text) => {
    setResError(text);
    setTimeout(() => {
      setResError("");
    }, 3000);
  };

  useEffect(() => {
    setGoogleUrl(`${Config.BACKEND_URL}/auth/google`);
  }, []);

  return (
    <div className="signin-container">
      <div className="signin-box">
        <h2>{signup ? "Create New Account" : "Sign in"}</h2>
        <form
          onSubmit={handleSubmit(signup ? handleSignUp : handleSignIn)}
          className="signin-form"
        >
          <label>Username</label>
          <input
            id="username"
            type="text"
            className={errors.email ? "error-validate" : ""}
            {...register("username", {
              required: "Username is required",
            })}
          />
          {errors.email?.message && (
            <span className="error">{errors.email?.message}</span>
          )}
          <label>Password</label>
          <div className="hideable-input">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              className={errors.password ? "error-validate" : ""}
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: signup ? 8 : 0,
                  message: "Password required 8 characters",
                },
              })}
            />
            <i
              className={
                showPassword ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"
              }
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
          {errors.password?.message && (
            <span className="error">{errors.password?.message}</span>
          )}
          {signup && (
            <>
              <label>Confirm password</label>
              <div className="hideable-input">
                <input
                  id="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  className={errors.confirmPassword ? "error-validate" : ""}
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (val) => {
                      if (watch("password") !== val) {
                        return "Passwords do not match";
                      }
                    },
                  })}
                />
                <i
                  className={
                    showPassword
                      ? "fa-regular fa-eye-slash"
                      : "fa-regular fa-eye"
                  }
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              {errors.confirmPassword?.message && (
                <span className="error">{errors.confirmPassword?.message}</span>
              )}
              <div className="radio-group">
                <label>
                  <input type="radio" value="user" {...register("role")} />
                  Control
                </label>
                <label>
                  <input
                    type="radio"
                    value="intervention"
                    {...register("role")}
                  />
                  Intervention
                </label>
              </div>
            </>
          )}
          <div className="option-login">
            <a href="/forgotPassword">Change password?</a>
          </div>
          <button type="submit">{signup ? "Get Started" : "Sign in"}</button>
          {resError && <span className="error">{resError}</span>}
        </form>
      </div>
      {loading && <WholeScreenLoader />}
    </div>
  );
};

export default Signin;
