import {useInfiniteQuery, useMutation, useQueryClient} from "react-query";
import * as api from "../api/api";
import Post from "../components/Post";
import {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {protectedPage} from "../utils/auth";
import {LOG} from "../constant/timeLog";

const BookletPage = ({userLogin, setUserLogin}) => {
  useEffect(() => {
    protectedPage(userLogin, setUserLogin);
  }, []);

  const Pdf = ({name, filePath, logAction}) => {
    const handleOnClick = () => {
      if (logAction) {
        api.sendTimeLog({action: logAction});
      }
      window.open(filePath, "_blank");
    };

    return (
      <div className="pdf">
        <img
          className="clickable"
          src={require("../assets/images/empowering/pdf-icon.png")}
          onClick={handleOnClick}
          alt=""
        ></img>
        <span className="name">{name}</span>
        <button className="clickable" type="button" onClick={handleOnClick}>
          Download <i className="fa fa-download"></i>
        </button>
      </div>
    );
  };
  return (
    <div className="bookletpage-container">
      <div className="bookletpage-banner">
        <img
          src={require("../assets/images/booklet-banner.png")}
          alt="blog-banner"
        />
        <div className="text">
          <h1>FamilyBridge for IBD booklet </h1>
        </div>
      </div>
      <div className="article-container">
        <p className="text1">
          We understand that everyone learns differently. To support your
          ongoing journey with IBD, we offer the{" "}
          <span className="name">FamilyBridge for IBD booklet</span> as a
          downloadable PDF. This resource provides valuable information, and
          you're welcome to access it offline at your own pace.
        </p>
        <div className="full-box">
          <Pdf
            name="Understanding IBD and Its Impact"
            filePath={require("../assets/images/booklet/Understanding IBD and Its impact.pdf")}
            logAction={LOG.DOWNLOAD_UNDERSTANDING_BOOKLET}
          />
          <Pdf
            name="Supporting Individuals with IBD"
            filePath={require("../assets/images/booklet/Supporting Individuals with IBD booklet.pdf")}
            logAction={LOG.DOWNLOAD_EMPOWERING_BOOKLET}
          />
          <Pdf
            name="Building Bonds That Last"
            filePath={require("../assets/images/booklet/Building Bonds That Last.pdf")}
            logAction={LOG.DOWNLOAD_BUILDING_BOOKLET}
          />
          <Pdf
            name="Unlocking Inner Resilience"
            filePath={require("../assets/images/booklet/Unlocking Inner Resilience.pdf")}
            logAction={LOG.DOWNLOAD_UNLOCKING_BOOKLET}
          />
        </div>
        <div className="full-box">
          <Pdf
            name="Spoon Theory"
            filePath={require("../assets/images/empowering/Spoon Theory.pdf")}
            logAction={LOG.DOWNLOAD_SPOON_THEORY}
          />
          <Pdf
            name="Pain Tracker"
            filePath={require("../assets/images/empowering/Pain tracker.pdf")}
            logAction={LOG.DOWNLOAD_PAIN_TRACKER}
          />
          <Pdf
            name="Medication Tracker"
            filePath={require("../assets/images/empowering/Medication tracker.pdf")}
            logAction={LOG.DOWNLOAD_MEDICATION_TRACKER}
          />
          <Pdf
            name="Meal plan & Grocery list"
            filePath={require("../assets/images/empowering/Meal prep & grocery list.pdf")}
            logAction={LOG.DOWNLOAD_MEAL_PLAN}
          />
          <Pdf
            name="Food Tracking"
            filePath={require("../assets/images/empowering/Food Tracker.pdf")}
            logAction={LOG.DOWNLOAD_FOOD_TRACKING}
          />
          <Pdf
            name="Self-Compassion Journal Worksheet"
            filePath={require("../assets/images/unlockingInner/Self-Compassion Journal.pdf")}
            logAction={LOG.DOWNLOAD_SELF_COMPASSION}
          />
          <Pdf
            name="SMART Goals Template"
            filePath={require("../assets/images/unlockingInner/SMART Goals.pdf")}
            logAction={LOG.DOWNLOAD_SMART_GOALS}
          />
          <Pdf
            name="Weekly Self Care Checklist"
            filePath={require("../assets/images/unlockingInner/Self Care Checklist.pdf")}
            logAction={LOG.DOWNLOAD_WEEKLY_SELF}
          />
          <Pdf
            name="Mindfulness Tracking"
            filePath={require("../assets/images/unlockingInner/Mindfulness Tracker.pdf")}
            logAction={LOG.DOWNLOAD_MINDFULNESS_TRACKING}
          />
          <Pdf
            name="Workout Tracking Template"
            filePath={require("../assets/images/unlockingInner/Workout Tracker.pdf")}
            logAction={LOG.DOWNLOAD_WORKOUT_TRACKING}
          />
        </div>
      </div>
    </div>
  );
};

export default BookletPage;
