import React, {useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import NoPage from "./pages/NoPage";
import SigninPage from "./pages/SigninPage";
import ProfilePage from "./pages/ProfilePage";
import ForumPage from "./pages/ForumPage";
import ContentPage from "./pages/ContentPage";
import AddPostPage from "./pages/AddPostPage";
import HowToUseModal from "./components/HowToUseModal";
import InformativeModulesPage from "./pages/InformativeModulesPage";
import BlogPage from "./pages/BlogPage";
import {QueryClient, QueryClientProvider} from "react-query";
import AddFaq from "./components/AddFaq";
import BookletPage from "./pages/BookletPage";
import ResourcePage from "./pages/ResourcePage";
import FaqPage from "./pages/FaqPage";
import AnalyticPage from "./pages/AnalyticPage";
import {LOG} from "./constant/timeLog";
import TrackedElement from "./components/TrackedElement";
import AddFaqPage from "./pages/AddFaqPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [userLogin, setUserLogin] = useState({});
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Navbar userLogin={userLogin} setUserLogin={setUserLogin} />
          <Routes>
            <Route
              path="/"
              exact
              element={
                <TrackedElement
                  logAction={LOG.VIEW_HOME}
                  element={
                    <HomePage
                      userLogin={userLogin}
                      setUserLogin={setUserLogin}
                      setModalOpen={setModalOpen}
                    />
                  }
                />
              }
            />
            <Route
              path="/forum"
              exact
              element={
                <TrackedElement
                  logAction={LOG.VIEW_FORUMS}
                  element={
                    <ForumPage
                      userLogin={userLogin}
                      setUserLogin={setUserLogin}
                    />
                  }
                />
              }
            />
            <Route
              path="/blog"
              exact
              element={
                <TrackedElement
                  logAction={LOG.VIEW_BLOGS}
                  element={
                    <BlogPage
                      userLogin={userLogin}
                      setUserLogin={setUserLogin}
                    />
                  }
                />
              }
            />
            <Route
              path="/content"
              exact
              element={
                <ContentPage
                  userLogin={userLogin}
                  setUserLogin={setUserLogin}
                />
              }
            />
            <Route
              path="/informativeModules"
              exact
              element={
                <InformativeModulesPage
                  userLogin={userLogin}
                  setUserLogin={setUserLogin}
                />
              }
            />
            <Route
              path="/booklet"
              exact
              element={
                <BookletPage
                  userLogin={userLogin}
                  setUserLogin={setUserLogin}
                />
              }
            />
            <Route path="/faq" exact element={<FaqPage />} />
            <Route
              path="/resource"
              exact
              element={
                <ResourcePage
                  userLogin={userLogin}
                  setUserLogin={setUserLogin}
                />
              }
            />
            <Route path="/addPost" exact element={<AddPostPage />} />
            <Route path="/addFaq" exact element={<AddFaqPage />} />
            <Route path="/signin" element={<SigninPage signin />} />
            <Route path="/signup" element={<SigninPage signup />} />
            <Route path="/resetPassword" element={<ResetPasswordPage />} />
            <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/analytic" element={<AnalyticPage />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
          <HowToUseModal
            isOpen={isModalOpen}
            closeModal={() => setModalOpen(false)}
            userLogin={userLogin}
            setUserLogin={setUserLogin}
          />
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
};

export default App;
