import {useEffect, useState} from "react";
import * as api from "../api/api";
import {WholeScreenLoader} from "../components/LoadingComponent";

const mockAnalyticData = {
  overall: {
    averageSessionTime: 0,
    totalSessionTime: 0,
    totalSessionCount: 0,
    longestSessionTime: 0,
    shortestSessionTime: 0,
    totalSectionVisited: {
      VIEW_HOME: 0,
      VIEW_UNDERSTANDING: 0,
      VIEW_EMPOWERING: 0,
      VIEW_BUILDING: 0,
      VIEW_UNLOCKING: 0,
      VIEW_BLOGS: 0,
      VIEW_FORUMS: 0,
      DOWNLOAD_UNDERSTANDING_BOOKLET: 0,
      DOWNLOAD_EMPOWERING_BOOKLET: 0,
      DOWNLOAD_BUILDING_BOOKLET: 0,
      DOWNLOAD_UNLOCKING_BOOKLET: 0,
      DOWNLOAD_SPOON_THEORY: 0,
      DOWNLOAD_PAIN_TRACKER: 0,
      DOWNLOAD_MEDICATION_TRACKER: 0,
      DOWNLOAD_MEAL_PLAN: 0,
      DOWNLOAD_FOOD_TRACKING: 0,
      DOWNLOAD_SELF_COMPASSION: 0,
      DOWNLOAD_SMART_GOALS: 0,
      DOWNLOAD_WEEKLY_SELF: 0,
      DOWNLOAD_MINDFULNESS_TRACKING: 0,
      DOWNLOAD_WORKOUT_TRACKING: 0,
    },
    averageSectionVisited: {
      VIEW_HOME: 0,
      VIEW_UNDERSTANDING: 0,
      VIEW_EMPOWERING: 0,
      VIEW_BUILDING: 0,
      VIEW_UNLOCKING: 0,
      VIEW_BLOGS: 0,
      VIEW_FORUMS: 0,
      DOWNLOAD_UNDERSTANDING_BOOKLET: 0,
      DOWNLOAD_EMPOWERING_BOOKLET: 0,
      DOWNLOAD_BUILDING_BOOKLET: 0,
      DOWNLOAD_UNLOCKING_BOOKLET: 0,
      DOWNLOAD_SPOON_THEORY: 0,
      DOWNLOAD_PAIN_TRACKER: 0,
      DOWNLOAD_MEDICATION_TRACKER: 0,
      DOWNLOAD_MEAL_PLAN: 0,
      DOWNLOAD_FOOD_TRACKING: 0,
      DOWNLOAD_SELF_COMPASSION: 0,
      DOWNLOAD_SMART_GOALS: 0,
      DOWNLOAD_WEEKLY_SELF: 0,
      DOWNLOAD_MINDFULNESS_TRACKING: 0,
      DOWNLOAD_WORKOUT_TRACKING: 0,
    },
  },
  users: [],
};

const AnalyticPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(mockAnalyticData);
  const [user, setUser] = useState(null);
  const [timeframe, setTimeframe] = useState(0);

  useEffect(() => {
    const fetchDate = async () => {
      const res = await api.getAnalyticData();
      setData(res);
      setLoading(false);
    };
    const fetchDateByTimeframe = async () => {
      const res = await api.getAnalyticDataByTimeframe(timeframe);
      setData(res);
      setLoading(false);
    };
    if (timeframe == 0) {
      fetchDate();
    } else {
      fetchDateByTimeframe();
    }
  }, [timeframe]);

  const onChange = (e) => {
    setLoading(true);
    setTimeframe(e.target.value);
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedTime = [];

    if (hours > 0) {
      formattedTime.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    }

    if (minutes > 0) {
      formattedTime.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    }

    if (remainingSeconds > 0) {
      formattedTime.push(
        `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
      );
    }

    return formattedTime.join(" ");
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {loading && <WholeScreenLoader />}
      <div style={{padding: "84px 12%"}}>
        <br />
        <br />
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1>Analytics</h1>
          <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
            <p>Get analytic from last: </p>
            <select style={{width: "360px"}} onChange={(e) => onChange(e)}>
              <option key={0} value={0}>
                all time
              </option>
              <option key={1} value={1}>
                24 hours
              </option>
              <option key={2} value={2}>
                2 days
              </option>
              <option key={7} value={7}>
                1 week
              </option>
              <option key={14} value={14}>
                2 weeks
              </option>
              <option key={31} value={31}>
                1 month
              </option>
              <option key={92} value={92}>
                3 months
              </option>
            </select>
          </div>
        </div>
        <br />
        <br />
        <br />
        <h2>
          Overall{" "}
          <span style={{fontSize: "24px"}}>(Calculated from all users)</span>
        </h2>
        <hr />
        <br />
        <div style={{padding: "12px 24px"}}>
          <div>
            <h3 style={{textDecoration: "underline"}}>Session</h3>
            <br />
            <p>
              Number of session:{" "}
              <span style={{fontWeight: 700}}>
                {data.overall.totalSessionCount} sessions
              </span>
            </p>
            <p>
              Average session time :{" "}
              <span style={{fontWeight: 700}}>
                {formatTime(data.overall.averageSessionTime || 0)}
              </span>
            </p>
            <p>
              Total session time:{" "}
              <span style={{fontWeight: 700}}>
                {formatTime(data.overall.totalSessionTime || 0)}
              </span>
            </p>
            <p>
              Longest session time:{" "}
              <span style={{fontWeight: 700}}>
                {formatTime(data.overall.longestSessionTime || 0)}
              </span>
            </p>
            <p>
              Shortest session time:{" "}
              <span style={{fontWeight: 700}}>
                {formatTime(data.overall.shortestSessionTime || 0)}
              </span>
            </p>
            <br />
          </div>
          <br />
          <div>
            <h3 style={{textDecoration: "underline"}}>
              Number of section visit (times)
            </h3>
            <ul>
              {Object.keys(data.overall.totalSectionVisited).map((section) => (
                <li key={section}>
                  <span style={{fontSize: "18px"}}>{section}</span>:{" "}
                  <span style={{fontWeight: 700}}>
                    {data.overall.totalSectionVisited[section]}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <br />
          <div>
            <h3 style={{textDecoration: "underline"}}>
              Average number of section visits per session (times)
            </h3>
            <ul>
              {Object.keys(data.overall.averageSectionVisited).map(
                (section) => (
                  <li key={section}>
                    <span style={{fontSize: "18px"}}>{section}</span>:{" "}
                    <span style={{fontWeight: 700}}>
                      {data.overall.averageSectionVisited[section] > 0
                        ? data.overall.averageSectionVisited[section].toFixed(1)
                        : 0}
                    </span>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <br />
        <br />
        <h2>Individual analytic</h2>
        <hr />
        <br />
        <select
          style={{width: "360px"}}
          onChange={(e) =>
            setUser(data.users.find((u) => u.userID === e.target.value))
          }
        >
          <option value="">Select a user</option>
          {data.users.map((user) => (
            <option key={user.userID} value={user.userID}>
              {user.username}
            </option>
          ))}
        </select>
        <br />
        <br />
        {user && (
          <>
            <div>
              <br />
              <h3 style={{textDecoration: "underline"}}>Session</h3>
              <br />
              <p>
                Total session time:{" "}
                <span style={{fontWeight: 700}}>
                  {formatTime(user.totalSessionTime || 0)}
                </span>
              </p>
              <p>
                Average session time:{" "}
                <span style={{fontWeight: 700}}>
                  {formatTime(user.averageSessionTime || 0)}
                </span>
              </p>
              <p>
                Longest session time:{" "}
                <span style={{fontWeight: 700}}>
                  {formatTime(user.longestSessionTime || 0)}
                </span>
              </p>
              <p>
                Shortest session time:{" "}
                <span style={{fontWeight: 700}}>
                  {formatTime(user.shortestSessionTime || 0)}
                </span>
              </p>
              <p>
                Session count:{" "}
                <span style={{fontWeight: 700}}>
                  {user.sessionCount} sessions
                </span>
              </p>
              <br />
              <br />
              <h3 style={{textDecoration: "underline"}}>
                Number of section visit (times)
              </h3>
              <ul>
                {Object.entries(user.actionCount).map(([action, count]) => (
                  <li key={`${user.userID}-actionCount-${action}`}>
                    <span style={{fontSize: "18px"}}>{action}</span> :{" "}
                    <span style={{fontWeight: 700}}>{count}</span>
                  </li>
                ))}
              </ul>
              <br />
              <h3 style={{textDecoration: "underline"}}>
                Average number of section visits per session (times)
              </h3>
              <ul>
                {Object.entries(user.averageActionCount).map(
                  ([action, count]) => (
                    <li key={`${user.userID}-averageActionCount-${action}`}>
                      <span style={{fontSize: "18px"}}>{action}</span> :{" "}
                      <span style={{fontWeight: 700}}>
                        {count > 0 ? count.toFixed(1) : 0}
                      </span>
                    </li>
                  )
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AnalyticPage;
