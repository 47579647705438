import axios from "axios";
import Config from "../assets/configs/configs.json";
import {cookieExists, deleteCookie} from "../utils/cookies";
import {LOG} from "../constant/timeLog";

const BASE_URL = Config.BACKEND_URL;

const ownerV1 = {
  id: 3,
  username: "admin",
  image: "https://i.imgur.com/1qkYVqQ.png",
};

const commentV1 = {
  id: 2,
  owner: ownerV1,
  detail: "This is a sample comment.",
  liked: false,
  like_count: 10,
};

const mockBlog = {
  id: 1,
  owner: ownerV1,
  topic: "Sample Topic",
  detail: "This is a sample blog post for testing purposes.",
  type: "blog",
  comments: [commentV1, commentV1, commentV1],
  tag: ["sample", "test"],
  like_count: 10,
  liked: false,
  photo: "https://pbs.twimg.com/media/GFZfAEuXIAAWM1k?format=jpg&name=small",
  video: null,
  bookmarked: false,
  createdAt: "2021-04-01T00:00:00.000Z",
};

export const getForums = async (page, sortBy, searchText) => {
  const res = await axios.get(
    `${BASE_URL}/blog?topic=${searchText}&type=forum&sortBy=${sortBy}&size=10&page=${page}`,
    {
      headers: {
        user_id: localStorage.getItem("user_id"),
      },
      withCredentials: true,
    }
  );
  if (res.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return res.data;
};

export const getBlogs = async (page) => {
  const res = await axios.get(
    `${BASE_URL}/blog?type=blog&sortBy=newest&size=6&page=${page}`,
    {
      headers: {
        user_id: localStorage.getItem("user_id"),
      },
      withCredentials: true,
    }
  );
  if (res.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return res.data;
};

export const getBlogById = async (id) => {
  const res = await axios.get(`${BASE_URL}/blog/${id}`, {
    headers: {
      user_id: localStorage.getItem("user_id"),
    },
    withCredentials: true,
  });
  if (res.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return res.data;
};

export const likeBlog = async ({blogId, like}) => {
  const res = await axios
    .patch(
      `${BASE_URL}/blog/like`,
      {
        blogId,
        like,
      },
      {
        headers: {
          user_id: localStorage.getItem("user_id"),
        },
        withCredentials: true,
      }
    )
    .catch((error) => {
      if (error.response.status === 403) {
        alert("Cannot perform this action in the Unverified Post.");
        throw new Error("Forbidden");
      } else if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
    });

  return res.data;
};

export const likeComment = async ({commentId, like}) => {
  const res = await axios
    .patch(
      `${BASE_URL}/comment/like`,
      {
        commentId,
        like,
      },
      {
        headers: {
          user_id: localStorage.getItem("user_id"),
        },
        withCredentials: true,
      }
    )
    .catch((error) => {
      if (error.response.status === 403) {
        alert("Cannot perform this action in the Unverified Post.");
        throw new Error("Forbidden");
      } else if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
    });
  return res.data;
};

export const addComment = async ({blogId, detail}) => {
  const res = await axios
    .post(
      `${BASE_URL}/comment`,
      {
        blogId,
        detail,
      },
      {
        headers: {
          user_id: localStorage.getItem("user_id"),
        },
        withCredentials: true,
      }
    )
    .catch((error) => {
      if (error.response.status === 403) {
        alert("Cannot perform this action in the Unverified Post.");
        throw new Error("Forbidden");
      } else if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
    });
  return res.data;
};

export const saveBlog = async ({blogId, savedPost}) => {
  const res = await axios
    .patch(
      `${BASE_URL}/blog/savedpost`,
      {
        blogId,
        savedPost,
      },
      {
        headers: {
          user_id: localStorage.getItem("user_id"),
        },
        withCredentials: true,
      }
    )
    .catch((error) => {
      if (error.response.status === 403) {
        alert("Cannot perform this action in the Unverified Post.");
        throw new Error("Forbidden");
      } else if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
    });
  return res.data;
};

export const getCompletedModule = async (module) => {
  const res = await axios.get(
    `${BASE_URL}/user/module?type=completed&module=${module}`,
    {
      headers: {
        user_id: localStorage.getItem("user_id"),
      },
      withCredentials: true,
    }
  );
  if (res.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return res.data;
};

export const addCompletedSubModule = async ({module, submodule, action}) => {
  const res = await axios
    .patch(
      `${BASE_URL}/user/module`,
      {
        module,
        submodule,
        action,
        type: "completed",
      },
      {
        headers: {
          user_id: localStorage.getItem("user_id"),
        },
        withCredentials: true,
      }
    )
    .catch((error) => {
      if (error.response.status === 403) {
        alert("Cannot perform this action in the Unverified Post.");
        throw new Error("Forbidden");
      } else if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
    });
  return res.data;
};

export const getSavedModule = async (module) => {
  const res = await axios.get(
    `${BASE_URL}/user/module?type=saved&module=${module}`,
    {
      headers: {
        user_id: localStorage.getItem("user_id"),
      },
      withCredentials: true,
    }
  );
  if (res.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return res.data;
};

export const addSavedSubModule = async ({module, submodule, action}) => {
  const res = await axios
    .patch(
      `${BASE_URL}/user/module`,
      {
        module,
        submodule,
        action,
        type: "saved",
      },
      {
        headers: {
          user_id: localStorage.getItem("user_id"),
        },
        withCredentials: true,
      }
    )
    .catch((error) => {
      if (error.response.status === 403) {
        alert("Cannot perform this action in the Unverified Post.");
        throw new Error("Forbidden");
      } else if (res.status !== 200) {
        throw new Error("Network response was not ok");
      }
    });
  return res.data;
};

export const sendTimeLog = async ({action}) => {
  if (!localStorage.getItem("is_tracked")) {
    return;
  }

  const body = {
    sessionID: localStorage.getItem("session_id"),
    action,
  };
  await axios
    .post(`${BASE_URL}/timelog`, body, {
      headers: {
        user_id: localStorage.getItem("user_id"),
        is_login: action === LOG.LOGOUT,
      },
      withCredentials: true,
    })
    .catch(async (error) => {
      if (
        !!error.response &&
        error.response.status === 401 &&
        !!error.response.data &&
        error.response.data.message === "shouldChangeSessionID"
      ) {
        localStorage.clear();
        (await cookieExists("username")) && (await deleteCookie("username"));
        (await cookieExists("userID")) && (await deleteCookie("userID"));
        await axios.post(
          `${Config.BACKEND_URL}/user/logout`,
          {
            cookie_name: "auth",
          },
          {
            withCredentials: true,
          }
        );
        window.location.assign("/signin");
      } else {
        console.error("Network response was not ok");
      }
    });
};

export const getAnalyticData = async () => {
  const res = await axios.get(`${BASE_URL}/analytic`, {
    headers: {
      user_id: localStorage.getItem("user_id"),
    },
    withCredentials: true,
  });

  if (res.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return res.data;
};

export const getAnalyticDataByTimeframe = async (day) => {
  const res = await axios.get(`${BASE_URL}/analytic/timeframe?daysAgo=${day}`, {
    headers: {
      user_id: localStorage.getItem("user_id"),
    },
    withCredentials: true,
  });

  if (res.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return res.data;
};
