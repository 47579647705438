import {useMutation, useQueryClient} from "react-query";
import * as api from "../api/api";

const SaveSubmodule = ({module, submodule}) => {
  const queryClient = useQueryClient();
  const moduleData = queryClient.getQueryData(["module-saved", module]);
  const value = moduleData?.[submodule];

  const toggleSavedSubmodule = useMutation(api.addSavedSubModule, {
    onMutate: async ({module, submodule, action}) => {
      await queryClient.cancelQueries(["module-saved", module]);
      const previousModule = queryClient.getQueryData(["module-saved", module]);
      queryClient.setQueryData(["module-saved", module], (oldModule) => ({
        ...oldModule,
        [submodule]: action,
      }));
      return {previousModule};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        ["module-saved", module],
        context.previousModule
      );
    },
  });

  const handleToggleSaveSubmodule = async () => {
    toggleSavedSubmodule.mutate({module, submodule, action: !value});
  };

  if (!moduleData || moduleData[submodule] === undefined) {
    return null;
  }

  return (
    <span className="save-submodule">
      <i
        id={submodule}
        className={value ? "fa-solid fa-bookmark icon" : "fa-regular fa-bookmark icon"}
        onClick={handleToggleSaveSubmodule}
        style={{
          fontSize: "24px",
          marginRight: "8px",
          padding: "8px",
          color: "#659AD2",
        }}
      />
      <div className="tooltip">{value ? "Unsave" : "Save"}</div>
    </span>
  );
};

export default SaveSubmodule;
