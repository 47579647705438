import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";

function useTypingEffect(textToType, interKeyStrokeDurationInMs) {
  const [currentPosition, setCurrentPosition] = useState(0);
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      let index = 0;
      const interval = setInterval(() => {
        setCurrentPosition((value) => value + 1);
        if (index + 1 >= textToType.length) {
          clearInterval(interval);
        }
        return index;
      }, interKeyStrokeDurationInMs);
      return () => clearInterval(interval);
    } else {
      setCurrentPosition(0);
    }
  }, [inView, textToType, interKeyStrokeDurationInMs]);

  const setCombinedRef = (node) => {
    if (typeof ref === "object") {
      ref.current = node;
    }
    if (typeof ref === "function") {
      ref(node);
    }
  };

  return [textToType.substring(0, currentPosition), setCombinedRef];
}

export const TypingComponent = ({text, speed}) => {
  const [typedText, setCombinedRef] = useTypingEffect(text, speed);

  return <p ref={setCombinedRef}>{typedText}</p>;
};
