import React, {useState} from "react";
import axios from "axios";
import Config from "../assets/configs/configs.json";
import {WholeScreenLoader} from "./LoadingComponent";

const AddPost = () => {
  const resetForm = {
    title: "",
    description: "",
    type: "",
  };
  const errorForm = {
    title: "",
    description: "",
    type: "",
  };
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(resetForm);
  const [error, setError] = useState(errorForm);
  const [resError, setResError] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType.startsWith("image/") || fileType.startsWith("video/")) {
        setFile(selectedFile);
      } else {
        alert("Please upload an image or video file.");
        setFile(null);
      }
    }
  };

  const handleChange = (event) => {
    const {name, value} = event.target;
    let added;
    added = value;
    setForm({
      ...form,
      [name]: added,
    });
    validateForm(event);
  };

  const validateForm = (event) => {
    let {name, value} = event.target;
    setError((prev) => {
      const stateObj = {...prev, [name]: ""};
      switch (name) {
        case "title":
          if (!value) {
            stateObj[name] = "Please enter the title";
          }
          break;
        case "type":
          if (!value) {
            stateObj[name] = "Please select type of this post.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let err = false;
    if (!form.title) {
      setError((prev) => ({
        ...prev,
        ["title"]: "Please enter the title.",
      }));
      err = true;
    }
    if (!form.type) {
      setError((prev) => ({
        ...prev,
        ["type"]: "Please select type.",
      }));
      err = true;
    }
    if (err) {
      handleShowResError("Please check error message above.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(form)) {
      formData.append(key, value);
    }

    if (file) {
      const fileType = file.type;
      if (fileType.startsWith("image/")) {
        formData.append("image", file);
      } else if (fileType.startsWith("video/")) {
        formData.append("video", file);
      }
    }

    try {
      await axios.post(`${Config.BACKEND_URL}/blog`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: localStorage.getItem("user_id"),
        },
      });
      setLoading(false);
      window.location.assign(`/${form.type}`);
    } catch (error) {
      setLoading(false);
      handleShowResError(error.response.data.error);
    }
    setLoading(false);
  };

  const handleShowResError = (text) => {
    setResError(text);
    setTimeout(() => {
      setResError("");
    }, 5000);
  };

  return (
    <div className="addcar-container">
      <div className="addcar-box">
        <h2>Create new post</h2>
        <form onSubmit={handleSubmit} className="addcar-form">
          <h4>Title / Question</h4>
          <input
            name="title"
            onChange={handleChange}
            onBlur={validateForm}
            value={form.title}
          ></input>
          {error.title && <span className="error">{error.title}</span>}
          <h4>Description</h4>
          <textarea
            name="description"
            onChange={handleChange}
            onBlur={validateForm}
            value={form.description}
          ></textarea>

          <h4>Upload Image/Video</h4>
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*,video/*"
          ></input>
          <label>
            Max. files: 1
            <br />
            Max. accepted file size is 32MB.
            <br />
            Supported file types: jpg , jpeg , png , mp4 , mov
          </label>

          <h4>Type</h4>
          <select
            name="type"
            id="type"
            onChange={handleChange}
            onBlur={validateForm}
            defaultValue=""
          >
            <option disabled hidden></option>
            {[
              {name: "Share you story", value: "blog"},
              {name: "Add to forum", value: "forum"},
            ].map((each) => {
              return (
                <option key={each.value} value={each.value}>
                  {each.name}
                </option>
              );
            })}
          </select>
          {error.type && <span className="error">{error.type}</span>}

          <button type="submit">Post</button>
          {resError && <span className="error">{resError}</span>}
        </form>
      </div>
      {loading && <WholeScreenLoader />}
    </div>
  );
};

export default AddPost;
