import React, {useState} from "react";

const AboutUs = () => {
  const [selectedId, setSelectedId] = useState(null);

  const onClick = (status) => {
    if (!!selectedId && selectedId === status) {
      setSelectedId(null);
    } else {
      setSelectedId(status);
    }
  };

  return (
    <>
      <div id="aboutus" className="aboutus-section reponsive-hide-small">
        <div className="topic-section">
          <h2>About Us</h2>
          <hr />
        </div>
        <div className="about-us-wrapper">
          <div className="aboutus-part">
            <div>
              <h3>Our mission</h3>
            </div>
            <p className="aboutus-text">
              Our mission is to provide information, share experiences, and
              connect with those who understand the challenges of supporting a
              loved one through their IBD journey.
            </p>
          </div>
          <div className="aboutus-part">
            <div>
              <h3>Collaboration for Impact</h3>
            </div>
            <p className="aboutus-text">
              Born from the shared understanding of researchers, patients,
              families, and dedicated nurses, our platform empowers families
              navigating IBD. From practical resources to emotional support, we
              address the diverse needs of every household member, making the
              journey with IBD a little less daunting.
            </p>
          </div>
          <div className="aboutus-part">
            <div>
              <h3>Join Our Community</h3>
            </div>
            <p className="aboutus-text">
              Join our thriving community at FamilyBridge for IBD! Whether
              you're looking for information, sharing your story, or connecting
              with those who understand, we're here for you. Let's build a
              supportive network together, empowering and uplifting families
              affected by IBD.
            </p>
          </div>
        </div>
      </div>
      <div
        id="aboutus"
        className="responsive-aboutus-section reponsive-show-small"
      >
        <div>
          <h2>About Us</h2>
          <hr />
        </div>
        <div className="aboutus-content">
          <div className="up">
            <div
              style={
                !!selectedId && selectedId === "our-mission"
                  ? {backgroundColor: "#8bced2"}
                  : {}
              }
              onClick={() => onClick("our-mission")}
            >
              <img
                style={{height: "42px", width: "49px"}}
                src={require("../assets/images/aboutus01.png")}
                alt=""
              />
              <p>Our mission</p>
            </div>
            <div
              style={
                !!selectedId && selectedId === "collaboration-for-impact"
                  ? {backgroundColor: "#8bced2"}
                  : {}
              }
              onClick={() => onClick("collaboration-for-impact")}
            >
              <img
                style={{height: "42px", width: "49px"}}
                src={require("../assets/images/aboutus02.png")}
                alt=""
              />
              <p>Collaboration for Impact</p>
            </div>
            <div
              style={
                !!selectedId && selectedId === "join-our-community"
                  ? {backgroundColor: "#8bced2"}
                  : {}
              }
              onClick={() => onClick("join-our-community")}
            >
              <img
                style={{height: "42px", width: "49px"}}
                src={require("../assets/images/aboutus03.png")}
                alt=""
              />
              <p>Join Our Community</p>
            </div>
          </div>

          {selectedId && selectedId === "our-mission" && (
            <div className="about-us-mini">
              <p>
                Our mission is to provide information, share experiences, and
                connect with those who understand the challenges of supporting a
                loved one through their IBD journey.
              </p>
            </div>
          )}

          {selectedId && selectedId === "collaboration-for-impact" && (
            <div className="about-us-mini">
              <p>
                Born from the shared understanding of researchers, patients,
                families, and dedicated nurses, our platform empowers families
                navigating IBD. From practical resources to emotional support,
                we address the diverse needs of every household member, making
                the journey with IBD a little less daunting.
              </p>
            </div>
          )}

          {selectedId && selectedId === "join-our-community" && (
            <div className="about-us-mini">
              <p>
                Join our thriving community at FamilyBridge for IBD! Whether
                you're looking for information, sharing your story, or
                connecting with those who understand, we're here for you. Let's
                build a supportive network together, empowering and uplifting
                families affected by IBD.
              </p>
            </div>
          )}

          <div className="down">
            <p>
              Welcome to FamilyBridge for IBD project, a compassionate online
              community dedicated to supporting the family members of those
              affected by Inflammatory Bowel Disease (IBD).
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
